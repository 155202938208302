import one from "./assets/hardware/features/GT06N.jpg"
import two from "./assets/hardware/features/We-Track-2.jpg"
import thre from "./assets/hardware/features/3.jpg"
import four from "./assets/hardware/features/4.jpg"
import five from "./assets/hardware/features/5.jpg"
import six from "./assets/hardware/features/6.jpg"
import seven from "./assets/hardware/features/7.jpg"
import eight from "./assets/hardware/features/8.jpg"
import nine from "./assets/hardware/features/9.jpg"
import ten from "./assets/hardware/features/10.png"
import eleven from "./assets/hardware/features/11.png"
import twel from "./assets/hardware/features/12.png"

export const gpsDevices = [
    {
      name: "GT06N",
      features: [
        "GSM Quad-band",
        "GPS + GSM + GPRS wireless network",
        "High sensitive GPS chipset",
        "Real-time tracking",
        "Built-in GSM & GPS antenna",
        "Built-in ON / OFF power, wide voltage input range",
        "Built-in acceleration sensor for vibration alarm",
        "Support ACC detection, real-time reporting car status",
        "Low battery alarm",
        "Tele-cut off (petrol / electricity) function",
        "SOS alarm",
        "Voice monitor",
        "Built-in CPU, eliminating breakdown automatically",
        "Three LED indicators: GPS - Blue; GSM - Green; Power - Red",
        "Check location via platform"
      ],
      image: one
    },
    {
      name: "WeTrack 2",
      features: [
        "Small in Size",
        "ACC Detection",
        "Real-time Positioning & Tracking",
        "Arm/Disarm",
        "Vibration Alarm",
        "Cross Geo-fence Alarm",
        "ACC anti-theft alarm",
        "Remote Power Cutting off",
        "Broad Voltage Range Input",
        "Three LED indicators: GPS- Blue; GSM- Green; Power- Red",
        "Power/ACC/Tele-cutoff",
        "Low battery alarm",
        "Check location via platform"
      ],
      image: two
    },
    {
      name: "WeTrack Lite (GV25)",
      features: [
        "GPS+LBS positioning",
        "IPX5 waterproof",
        "Multiple alarms",
        "ACC detection",
        "850/900/1800/1900 MHz",
        "Tracked by: SMS, APP, Web",
        "Multiple alarms",
        "2.4GHz RFID Attendance function",
        "Colorful customized name label"
      ],
      image:thre
    },
    {
      name: "GT06F",
      features: [
        "GPS + GSM + GPRS wireless network",
        "Built-in high sensitive GPS chipset",
        "Built-in GSM & GPS antenna",
        "Real-time tracking",
        "Start working automatically after electrifying",
        "Built-in monitor circuits to avoid breakdown",
        "Built-in ON / OFF power, wide voltage input range",
        "Built-in acceleration sensor for saving power intelligently and Resisting static drifting",
        "Installing method: covert / open",
        "Three LED indicators: GPS - Blue; GSM - Green; Power - Red",
        "Check location via platform service"
      ],
      image: four
    },
    {
      name: "GT300",
      features: [
        "GSM 850 / 900 / 1800 / 1900 MHz Quad-band",
        "GPS / GSM / GPRS wireless network",
        "Suitable for cargo, container and personal tracking",
        "3 optional mode: Arm, Disarm and Send location to platform by adjustable time",
        "1100mAh large capacity internal battery for long working time",
        "Voice Monitor function",
        "Phone call available - 2 family numbers supported",
        "SOS emergency alarm",
        "Geo-fence alarming function",
        "Web-based online tracking platform"
      ],
      image: five
    },
    {
      name: "GK309",
      features: [
        "GSM 850 / 1900 or 900 / 1800 MHz Dual-band",
        "GPS / AGPS / LBS multiple locating mode",
        "Built-in 600mAh battery",
        "SOS button for emergency calls and alarms",
        "Silent mode for class time",
        "Voice monitor function",
        "Low battery alarm",
        "2.4GHz RFID Attendance function",
        "Colorful customized name label"
      ],
      image: six
    },
    {
      name: "GT100",
      features: [
        "Specially made for motorcycle",
        "Water and dust proof IP56",
        "GSM 850/900/1800/1900 MHz Quad-band",
        "GPS+GSM+GPRS wireless network",
        "Geo-fence, automatically in / out fence alarm",
        "Built-in high sensitive GPS chipset",
        "Built-in GSM&GPS antenna",
        "Real-time tracking",
        "Theft alert if the motorcycle is moved without ignition",
        "Geo-fence alarm",
        "Cut-off petrol/electricity",
        "Low battery protection",
        "ACC stature detection and alarm",
        "Built-in battery standby when the engine is off",
        "Three LED indicators: GPS- Blue; GSM- Green; Power- Red",
        "Check location via platform"
      ],
      image: seven
    },
    {
      name: "TR06",
      features: [
        "GSM Quad-band",
        "GPS+GSM+GPRS wireless network",
        "High sensitive GPS chipset",
        "Real-time tracking",
        "Built-in GSM&GPS antenna",
        "Built-in ON/OFF power, wide voltage input range",
        "Built-in acceleration sensor for vibration alarm",
        "Support ACC detection, real-time reporting car status",
        "Built-in 450mAh battery for alarm when the power supply is disconnected unexpectedly",
        "Tele-cut off (petrol/electricity) function",
        "SOS button for SOS alarm",
        "Voice monitor function",
        "Built-in CPU, eliminating breakdown automatically",
        "Three LED indicators: GPS- Blue; GSM- Green; Power- Red",
        "Check location via platform"
      ],
      image: eight
    },
    {
      name: "TR02",
      features: [
        "GPS + GSM + GPRS wireless network",
        "Built-in high sensitive GPS chipset",
        "Built-in GSM & GPS antenna",
        "Real-time tracking",
        "Start working automatically after electrifying",
        "Built-in monitor circuits to avoid breakdown",
        "Built-in ON / OFF power, wide voltage input range",
        "Built-in acceleration sensor for saving power intelligently and Resisting static drifting",
        "Installing method: covert / open",
        "Three LED indicators: GPS - Blue; GSM - Green; Power - Red",
        "Check location via platform service"
      ],
      image:nine
    },
    {
      name: "GT350",
      features: [
        "GSM 850/900/1800/1900MHz",
        "GPS+LBS positioning",
        "Built-in 800mAh/3.7V battery",
        "SOS call & Speed-dial",
        "Vibration alert",
        "Voice monitor function",
        "90 day standby time",
        "Tracked by: SMS, APP, Web",
        "Intelligent power management"
      ],
      image: ten
    },
    {
      name: "Obit Tracker",
      features: [
        "GSM 900/1800MHz",
        "GPS+LBS positioning",
        "Geo-fence alarm",
        "SOS call & Speed-dial",
        "Two-way audio",
        "IPX5 waterproof",
        "Low battery alarm",
        "Voice monitoring",
        "Tracked by: SMS, APP, Web"
      ],
      image: eleven
    },
    {
      name: "MOPLUS",
      features: [
        "GPS+AGPS tracking",
        "850/900/1800/1900 MHz",
        "Remotely cut off fuel/power",
        "ACC detection for ignition status",
        "Intelligent power management",
        "Tracked by: SMS, APP, Web",
        "Multiple alarms",
        "2.4GHz RFID Attendance function",
        "Colorful customized name label"
      ],
      image: twel}
  ];
  