import React from "react";
import { Fade, Slide } from "react-awesome-reveal";
import Footer from "../../components/common/Footer";
import HardwareNavbar from "../../components/common/HardwareNavbar";
const HardwareAboutPage = () => {
  return (
    <div className="">
      <HardwareNavbar />
      <div className=" py-5  text-black mt-10">
        <div className="relative -z-10 max-w-7xl mx-auto px-4 lg:gap-32 py-1 bg-opacity-90 bg-transparent text-black">
          <div className="main  items-center grid  gap-5 lg:grid-cols-2">
            <Slide direction="left">
              <div className="second">
                <img
                  src="https://luatgiale.vn/wp-content/uploads/2019/09/sales-manager-dl.gif"
                  alt="Construction"
                />
              </div>
            </Slide>
            <Slide direction="right">
              <div className="first grid gap-4 p-4 md:gap-6 md:p-8">
                <p className="text-xl md:text-3xl font-semibold  border-l-4 border-yellow-400 pl-4">
                  About Company Name Technologies- A Well Known Digital
                  Marketing Agency in India
                </p>
                <p className="text-sm md:text-xl ">
                  At Rankon Technologies, we pride ourselves on being an
                  award-winning digital marketing company based in India. Our
                  mission is to empower small to medium-sized businesses
                  worldwide by delivering comprehensive digital marketing
                  solutions that drive growth, enhance visibility, and achieve
                  tangible results
                </p>

                <div>
                  <button className="bg-yellow-400  rounded-md text-black w-fit px-5 py-2 text-xl lg:text-xl">
                    About Us
                  </button>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HardwareAboutPage;
