import React from "react";
import SoftwareNavbar from "../components/common/SoftwareNavbar";
import Hero from "../components/software/Hero";
import About from "../components/software/About";
import Services from "../components/software/Services";
import WhyShouldHire from "../components/software/WhyShouldHire";
import WhyUs from "../components/software/WhyUs";
import Meet from "../components/software/Meet";
import Footer from "../components/common/Footer";
import Client from "../components/software/Client";
import Poster from "../components/software/Poster";
import Blog from "../components/software/Blog";
import Campaign from "../components/software/Campaign";
import HomeQuote from "../components/software/HomeQuote";

const SoftwareHome = () => {
  return (
    <div>
      <SoftwareNavbar />
      <Hero />
      <About />
      <Client />
      <WhyUs />
      <Services />
      <WhyShouldHire />
      <Poster />
      <Meet />
      <Campaign />
      <Blog />
      <HomeQuote />
      <Footer />
    </div>
  );
};

export default SoftwareHome;
