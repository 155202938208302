import React, { useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import hero from "../../assets/software/smo/hero.png";
import about from "../../assets/software/smo/about.png";
import { Link } from "react-router-dom";
import Footer from "../../components/common/Footer";
import SoftwareNavbar from "../../components/common/SoftwareNavbar";
import AsSeenOn from "../../components/software/AsSeeOn";
import Client from "../../components/software/Client";
import SeoPackages from "../../components/software/SeoPackages";
import Industry from "../../components/software/Industry";
const SmoPackage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SoftwareNavbar />
      <div className="bg-yellow-400 py-10">
        <div className="main max-w-7xl mx-auto justify-center  grid lg:grid-cols-2 px-2 text-center lg:text-start">
          <Slide direction="left">
            <div className="first">
              <p className="text-xl my-4">SMO Packages</p>
              <p className="lg:text-4xl mt-5 text-2xl font-semibold lg:w-[90%] px-1 lg:px-0 text-center lg:text-start">
                Monthly SMO Packages For Better Social Media Trust{" "}
              </p>
              <p className="text-sm leading-loose my-4">
                Are you searching for SMO packages in India? RankON Technologies
                offers affordable social media optimization packages in India.
                Social Media Optimization is a significant way for increasing
                awareness of new products and services across various social
                media platforms.
              </p>
              <p className="text-sm my-4">
                Basically, it is a fool-proof plan that helps businesses to get
                connected to their target audience.{" "}
              </p>

              <br />
              <Link
                to="/software/quote"
                className="text-xl lg:mr-10 mr-3 my-8 px-4 py-2 rounded-md bg-red-600 text-white hover:bg-white hover:text-black"
              >
                Request Proposal
              </Link>
              <Link
                to="/software/packages"
                className="text-xl my-8 px-4 py-2 rounded-md bg-white text-black hover:bg-red-600 hover:text-white"
              >
                SMO Packages
              </Link>
            </div>
          </Slide>

          <Slide direction="right">
            <div className="second w-[72%] m-auto">
              <img src={hero} alt="not found" />
            </div>
          </Slide>
        </div>
      </div>

      <AsSeenOn />

      <div className="px-5">
        <div className=" flex flex-col  w-full items-center">
          <h3 className=" text-2xl lg:text-4xl font-semibold  text-black">
            What Is Social Media & Why You Should Use It?
          </h3>
          <br />
          <div className="flex items-center w-[75px]">
            <div className="h-0.5 bg-[#cee21a]"></div>
            <div className="h-1 w-1 bg-[#cee21a] rounded-full mx-1"></div>
            <div className="h-1 w-1 bg-[#cee21a] rounded-full mx-1"></div>
            <div className="h-1 w-1 bg-[#cee21a] rounded-full mx-1"></div>
            <div
              className="h-[4px] rounded-full w-[10px] flex-grow"
              style={{ backgroundColor: "#cee21a" }}
            ></div>
          </div>
                    
        </div>

        <div className="grid lg:grid-cols-2 gap-5 max-w-7xl mx-auto px-5">
          <div className="text-xl leading-loose">
            <p>
              Social media is the most effective tool to build an online
              community and brand trust among your buyers. Billions of people
              use social media channels nowadays and this is why you should also
              use social media management services to help your brand build a
              better online reputation and social trust. Our SMO packages cover
              everything you need to manage and build better online brand
              reputation and social trust.
            </p>
            <br />
            <p>
              Here at RankON Technologies, we believe in offering affordable yet
              best in quality social media management packages to our clients.
              This is why we have three different monthly SMO packages to help
              our clients to increase their brand’s social trust.
            </p>
          </div>
          <div>
            <img src={about} alt="not found" />
          </div>
        </div>
      </div>
      <br />
      <br />

      <SeoPackages />
      <br />
      <br />
      <div className="max-w-7xl mx-auto px-5">
        <p className="text-center text-2xl lg:text-4xl font-bold leading-loose">
          Best SMO Packages
          <p>
            {" "}
            That Increase Website Organic SMO Ranking,{" "}
            <span className="text-yellow-400">Traffic & Sales</span>
          </p>
        </p>
        <p className="text-center text-xl my-10 leading-loose">
          SEO packages from Provision Plus don’t only increase your search
          engine rankings but also improve your conversion, domain authority and
          brand visibility. Provision Plus Technologies is a leading search
          engine optimization company in India whose SEO Packages are worth
          every dime. Our SEO packages help you to rank higher in Google search
          results.
        </p>
        <p className="text-center text-xl my-10 leading-loose">
          From website optimization to keyword rankings we take care of every
          single aspect of SEO to increase your organic traffic asap.
        </p>
      </div>

      <Client />
      <Industry />
      <Footer />
    </>
  );
};

export default SmoPackage;
