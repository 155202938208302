import { saveProducts } from "../../redux/productSlice";
import { apiConnector } from "../apiConnector";
import {  productEndpoints } from "../apis";
import Swal from "sweetalert2";
import { toast } from "react-toastify";


const {
     ADD_PRODUCT_API, 
  UPDATE_PRODUCT_API, 
  DELETE_PRODUCT_API, 
  DETAILS_PRODUCT_API ,
  GET_ALL_PRODUCT_API,
    STATUS_NEWS_API,
    IMAGE_UPLOAD,
  
    ADD_CATEGORY_API,
    UPDATE_CATEGORY_API,
    GET_ALL_CATEGORY_API,
    DETAILS_CATEGORY_API,
    DELETE_CATEGORY_API,
  
    ADD_SUBCATEGORY_API,
    GET_ALL_SUBCATEGORY_API,
    UPDATE_SUBCATEGORY_API,
    DELETE_SUBCATEGORY_API,
    DETAILS_SUBCATEGORY_API,
  

  
  
  } = productEndpoints



  // Create Product
export const createProduct = async (data, token) => {
    let swalLoadingInstance;
  
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        swalLoadingInstance = Swal.showLoading();
      },
    });
  
    try {
      const response = await apiConnector("POST", ADD_PRODUCT_API, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
  
      console.log("CREATE Product API RESPONSE............", response);
  
      if (!response?.data?.success) {
        throw new Error("Could Not Add Product Details");
      }
  
      Swal.fire({
        icon: "success",
        title: "Product Added Successfully",
      });
    } catch (error) {
      console.log("CREATE Product API ERROR............", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.message,
      });
    } finally {
      if (swalLoadingInstance) {
        Swal.close();
      }
    }
  };
  

  export const getAllProduct = () => async (dispatch) => {
    try {
      const response = await apiConnector("GET", GET_ALL_PRODUCT_API);
  
      if (!response?.data?.success) {
        throw new Error("Could Not Fetch News");
      }
  
      const result = response?.data?.products;
      // console.log(result)
      dispatch(saveProducts(result)); // Dispatching action to save products
      return result;
    } catch (error) {
      console.log("GET_ALL_NEWS_API API ERROR:", error);
  
      return [];
    }
  };
  // Edit Product
  export const editProduct = async (data, token) => {
    const toastId = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    try {
      const response = await apiConnector("PUT", UPDATE_PRODUCT_API, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
  
      console.log("EDIT Product API RESPONSE............", response);
  
      if (!response?.data?.success) {
        throw new Error("Could Not Update Product Details");
      }
  
      Swal.fire({
        icon: "success",
        title: "Product Updated Successfully",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.log("EDIT Product API ERROR............", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.message,
      });
    } finally {
      Swal.close(toastId);
    }
  };
  
  // Delete Product
  export const deleteProduct = async (id, token) => {
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_PRODUCT_API,
        { id },
        {
          Authorization: `Bearer ${token}`,
        }
      );
  
      console.log("DELETE Product API RESPONSE............", response.data);
  
      if (!response?.data?.success) {
        throw new Error("Could Not Delete Product");
      }
  
      Swal.fire({
        icon: "success",
        title: "Product Deleted Successfully",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.log("DELETE Product API ERROR............", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.message,
      });
    }
  };
  
  // Get Single Product
  export const getSingleProduct = async (productId) => {
    try {
      // Log the API endpoint and product ID for debugging purposes
      console.log(DETAILS_PRODUCT_API, productId);
  
      // Make a GET request to fetch the product details
      const response = await apiConnector("GET", `${DETAILS_PRODUCT_API}/${productId}`);
  
      // Check if the response indicates success
      if (!response?.data?.success) {
        throw new Error("Could Not Fetch Product");
      }
  
      // Extract the product details from the response
      const result = response?.data?.product;
      return result;
  
    } catch (error) {
      // Log any errors that occur during the API request
      console.log("GET_SINGLE_PRODUCT API ERROR:", error);
      return null;
    }
  };
  



//CateGory

export const createCategory = async (data, token) => {
    // console.log(data);
    const toastId = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    try {
      const response = await apiConnector("POST", ADD_CATEGORY_API, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
  
      console.log("CREATE Category API RESPONSE............", response);
  
      if (!response?.data?.success) {
        throw new Error("Could Not Add Category Details");
      }
  
      Swal.fire({
        icon: "success",
        title: "Category Details Added Successfully",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.log("CREATE Category API ERROR............", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    } finally {
      Swal.close(toastId);
    }
  };
  
  export const updateCategory = async (data, token) => {
    // console.log(data);
    const toastId = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    try {
      const response = await apiConnector("POST", UPDATE_CATEGORY_API, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
  
      console.log("UPDATE Category API RESPONSE............", response);
  
      if (!response?.data?.success) {
        throw new Error("Could Not Add Category  UPDATE Details");
      }
  
      Swal.fire({
        icon: "success",
        title: "Category UPDATE Details Added Successfully",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.log("CREATE Category API ERROR............", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    } finally {
      Swal.close(toastId);
    }
  };
  
  export const deleteCategory = async (id, token) => {
    const toastId = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    try {
      const response = await apiConnector(
        "DELETE",
        `${DELETE_CATEGORY_API}/${id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
  
      console.log("DELETE Category API RESPONSE............", response);
  
      if (!response?.data?.success) {
        throw new Error("Could Not Delete Category");
      }
  
      Swal.fire({
        icon: "success",
        title: "Category Deleted Successfully",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.log("DELETE Category API ERROR............", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    } finally {
      Swal.close(toastId);
    }
  };
  
  export const fetchSingleCategory = async (id) => {
    let result = [];
    try {
      const response = await apiConnector("GET", `${DETAILS_CATEGORY_API}/${id}`);
      console.log("News_CATEGORIES_API API RESPONSE............", response);
      if (!response?.data?.success) {
        throw new Error("Could Not Fetch  Categories");
      }
  
      result = response?.data;
    } catch (error) {
      console.log("CATEGORY_API API ERROR............", error);
    }
    return result;
  };
  
  export const fetchCategory = async () => {
    let result = [];
    try {
      const response = await apiConnector("GET", GET_ALL_CATEGORY_API);
      // console.log("News_CATEGORIES_API API RESPONSE............", response)
      if (!response?.data?.success) {
        throw new Error("Could Not Fetch News Categories");
      }
      // console.log(response?.data)
  
      result = response?.data;
    } catch (error) {
      console.log("News_CATEGORY_API API ERROR............", error);
    }
    return result;
  };
  
  //SubCateGory
  
  export const createSubCategory = async (data, token) => {
    console.log(data);
    const toastId = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    try {
      const response = await apiConnector("POST", ADD_SUBCATEGORY_API, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
  
      console.log("CREATE SubCategory API RESPONSE............", response);
  
      if (!response?.data?.success) {
        throw new Error("Could Not Add SubCategory Details");
      }
  
      Swal.fire({
        icon: "success",
        title: "SubCategory Details Added Successfully",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.log("CREATE SubCategory API ERROR............", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    } finally {
      Swal.close(toastId);
    }
  };
  
  export const updateSubCategory = async (data, token) => {
    const toastId = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    try {
      const response = await apiConnector("POST", UPDATE_SUBCATEGORY_API, data, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
  
      console.log("UPDATE SubCategory API RESPONSE............", response);
  
      if (!response?.data?.success) {
        throw new Error("Could Not Update SubCategory Details");
      }
  
      Swal.fire({
        icon: "success",
        title: "SubCategory Details Updated Successfully",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.log("UPDATE SubCategory API ERROR............", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    } finally {
      Swal.close(toastId);
    }
  };
  
  export const deleteSubCategory = async (id, token) => {
    const toastId = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    try {
      const response = await apiConnector(
        "DELETE",
        `${DELETE_SUBCATEGORY_API}/${id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
  
      console.log("DELETE SubCategory API RESPONSE............", response);
  
      if (!response?.data?.success) {
        throw new Error("Could Not Delete SubCategory");
      }
  
      Swal.fire({
        icon: "success",
        title: "SubCategory Deleted Successfully",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.log("DELETE SubCategory API ERROR............", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    } finally {
      Swal.close(toastId);
    }
  };
  
  export const fetchSingleSubCategory = async (id) => {
    let result = [];
    try {
      const response = await apiConnector(
        "GET",
        `${DETAILS_SUBCATEGORY_API}/${id}`
      );
      if (!response?.data?.success) {
        throw new Error("Could Not Fetch SubCategory Details");
      }
      result = response?.data;
    } catch (error) {
      console.log("FETCH SINGLE SubCategory API ERROR............", error);
    }
    return result;
  };
  export const fetchSubCategory = async () => {
    let result = [];
    try {
      const response = await apiConnector("GET", GET_ALL_SUBCATEGORY_API);
      // console.log("News_SUB CATEGORIES_API API RESPONSE............", response)
  
      if (!response?.data?.success) {
        throw new Error("Could Not Fetch News Categories");
      }
  
      result = response?.data?.subCategories;
    } catch (error) {
      // console.log("News_CATEGORY_API API ERROR............", error)
    }
    return result;
  };



  //Image

export const imageUpload = async (data, token) => {
    let result = [];
    const toastId = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    try {
      const formData = new FormData();
      for (let i = 0; i < data.length; i++) {
        formData.append("thumbnail", data[i]);
      }
  
      const response = await apiConnector("POST", IMAGE_UPLOAD, formData, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      });
  
      if (!response?.data?.success) {
        throw new Error("Could Not Add Image Details");
      }
  
      Swal.fire({
        icon: "success",
        title: "Image Details Added Successfully",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
  
      result = response?.data?.images;
    } catch (error) {
      console.log("CREATE IMAGE API ERROR............", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    } finally {
      Swal.close(toastId);
    }
  
    return result;
  };
  

