// src/components/Footer.js
import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaPinterest,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaWhatsapp,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const Footer = () => {
  const { token } = useSelector((state) => state.auth);
  return (
    <footer className="bg-black text-white py-10">
      <div className="max-w-7xl mx-auto p-5">
        <div className="grid lg:grid-cols-3 gap-5">
          <div className=" ">
            <h2 className="text-xl font-semibold mb-4">About Us</h2>
            <hr className="my-4" />
            <p>
              Founded in 2017, Provision Plus is a leading international
              software & Hardware Manufacturing ( Security Surveillance ‘ CCTV ;
              LEDs ; GPS )provider for the eCommerce , Service Industry ,
              Banking and Investment Management industries. The company has a
              presence in Delhi , Mumbai , Ranchi and Gaya delivering innovative
              solutions to both start-ups and established institutions, through
              direct communication or a reliable partners network.
            </p>
            <div className="mt-4">
              <img
                src="https://jharkhanditservices.com/images/icons/Review1.png"
                alt="Ratings"
                className="h-16"
              />
            </div>
          </div>
          <div className="grid  grid-cols-2 gap-8 lg:gap-10 px-5">
            <div className="">
              <h2 className="text-xl font-semibold mb-4">Our Services</h2>
              <hr className="my-4" />

              <ul className="grid gap-2">
                <Link to="/software/seo">SEO Service</Link>
                <Link to="/software/smo">SMO Service</Link>
                <Link to="/software/webdev">Website Development</Link>
                <Link to="/software/webdesign">Website Desinging</Link>
                <Link to="/hardware/tv/home">LED TV</Link>
                <Link to="/hardware/cctv/home">CCTV</Link>
                <Link to="/hardware/gps/home">GPS</Link>
                <Link to="/refundpolicy">Refund Policy</Link>
                <Link to="/privacypolicy">Privacy Policy</Link>
                <Link to="/replacementpolicy">Replacement Policy</Link>
                <Link to="/tc">Terms of use</Link>
              </ul>
            </div>
            <div className="">
              <h2 className="text-xl font-semibold mb-4">Quick Links</h2>
              <hr className="my-4" />

              <ul className="grid gap-2">
                <Link to="/career">Career</Link>
                <Link to="/software/services">Service</Link>
                <Link to="/software/packages">Packages</Link>
                <Link to="/hardware/home">Product</Link>
                <Link to="/software/about">About</Link>
                <Link to="/contact">Contact</Link>
                {token ? (
                  <Link to="/admin/dashboard">Admin Login</Link>
                ) : (
                  <Link to="/login">Admin Login</Link>
                )}
              </ul>
            </div>
          </div>
          <div className="px-5">
            <h2 className="text-xl font-semibold mb-4">Contact Us</h2>
            <hr className="my-4" />

            <ul className="grid gap-3 list-disc ">
              <li>
                Rimjhim Kothi Indrapuri Road 1 Ratu road Ranchi 834005 Jharkhand
                <li>
                  New Area Gaus Nagar Manitola Doranda 834002 Ranchi Jharkhand
                </li>
              </li>
            </ul>

            <ul className="grid my-3">
              <li> +91 9212315612 ,+91 62008 19868</li>
              <li> provisionplusindia@gmail.com</li>
            </ul>
            <div className="mt-5 flex space-x-4">
              <Link to="#">
                <FaFacebook className="h-6 w-6" />
              </Link>
              <Link to="#">
                <FaTwitter className="h-6 w-6" />
              </Link>
              <Link to="#">
                <FaPinterest className="h-6 w-6" />
              </Link>
              <Link to="#">
                <FaInstagram className="h-6 w-6" />
              </Link>
              <Link to="#">
                <FaLinkedin className="h-6 w-6" />
              </Link>
              <Link to="#">
                <FaYoutube className="h-6 w-6" />
              </Link>
            </div>
            <div>
              <div className="flex items-center bg-green-500 rounded-full p-2 space-x-2 text-white hover:bg-green-600 transition w-fit px-5 mt-5 duration-300">
                <FaWhatsapp size={34} />
                <a
                  href="https://wa.me/+919212315612"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-bold text-sm"
                >
                  WhatsApp
                  <span className="block text-sm font-normal">
                    Click to Chat
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center text-xs mt-10 border-t border-blue-800 pt-4">
          <p>Made By I NEXT ETS ❤️</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
