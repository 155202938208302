// WhyChoose.js
import React, { useEffect, useState } from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { Link } from "react-router-dom";

const truncateTitle = (title, wordLimit) => {
  const words = title.split(" ");
  return words.length > wordLimit
    ? words.slice(0, wordLimit).join(" ") + "..."
    : title;
};

const SmartTv = ({ AllProducts }) => {
  const [top5Products, setTop5Products] = useState([]);
  useEffect(() => {
    if (AllProducts && AllProducts.length > 0) {
      // Sort the products by createdAt
      const sortedProducts = AllProducts.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );

      // Get the top 5 products from the sorted list
      const top5Prod = sortedProducts.slice(0, 5);

      // Set the top 5 products
      setTop5Products(top5Prod);

      // Optionally log the top 5 products for debugging
      console.log(top5Prod);
    }
  }, [AllProducts]);

  return (
    <div className="my-10">
      <p className="px-5 text-3xl text-center font-bold ">Smart TVs</p>
      <p className="px-5 text-xl text-center  mt-1">
        A wide range of smart and durable television sets.
      </p>
      <p className="px-5 text-2xl text-center font-bold mt-1">
        View All Products
      </p>
      <br />

      <div className="px-5 mt-2 ">
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          autoplay={{ delay: 2000 }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          {top5Products?.map((item) => (
            <SwiperSlide key={item._id} className="">
              <Link to={`/hardware/product/${item._id}`}  className="border  border-gray-400 hover:border-red-600 rounded-md shadow-lg py-5">
                <img
                  src={item.images[0]?.url}
                  alt={item.title}
                  className="w-full h-80 object-cover rounded-md "
                />
                <div className="mt-8 mb-3">
                  <p className="text-center">{truncateTitle(item.title, 8)}</p>
                  <div className="flex gap-3 justify-center mt-3">
                    <p className="text-[#c6a85b] text-xl">₹{item?.price}</p>
                    <p className="line-through text-red-600 text-xl">
                      ₹{item?.highPrice}
                    </p>
                    <p className="text-green-600">{item.discount}</p>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SmartTv;
