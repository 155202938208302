import React, { useState } from "react";
import { FaBars, FaTimes, FaChevronDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import FreeSeoModel from "../software/FreeSeoModel";
import logo from "../../assets/software/logo.jpg";
const navLinks = [
  { name: "Home", path: "/" },
  {
    name: "Services",
    path: "/software/services",
    dropdown: [
      {
        name: "SEO Services",
        path: "/software/seo",
        // dropdown: [
        //   {
        //     name: "Local Business SEO",
        //     path: "/seo/local-business",
        //     dropdown: [
        //       {
        //         name: "Pest Control SEO",
        //         path: "/seo/local-business/pest-control",
        //       },
        //       { name: "Roofer SEO", path: "/seo/local-business/roofer" },
        //     ],
        //   },
        //   { name: "Outsource SEO", path: "/seo/outsource" },
        //   { name: "Small Business SEO", path: "/seo/small-business" },
        //   { name: "OnPage SEO", path: "/seo/onpage" },
        //   { name: "Off-Page SEO", path: "/seo/offpage" },
        //   { name: "Competitor Analysis SEO", path: "/seo/competitor-analysis" },
        //   { name: "Technical SEO", path: "/seo/technical" },
        //   { name: "CMS SEO", path: "/seo/cms" },
        // ],
      },
      { name: "SMO Services", path: "/software/smo" },
      { name: "Web Design Services", path: "/software/webdesign" },
      { name: "Web Development", path: "/software/webdev" },
    ],
  },
  {
    name: "Packages",
    path: "/software/packages",
    dropdown: [
      {
        name: "SEO Packages",
        path: "/software/seoPackage",
      },
      { name: "SMO Packages", path: "/software/smoPackage" },
      {
        name: "Web Maintenance",
        path: "/software/website-maintenance-packages",
      },
      {
        name: "Web Development Packages",
        path: "/software/website-development-packages",
      },
    ],
  },
  {
    name: "Product",
    path: "/hardware/home",
    dropdown: [
      {
        name: "TV",
        path: "/hardware/tv/home",
      },
      {
        name: "CCTV",
        path: "/hardware/cctv/home",
      },
      {
        name: "GPS",
        path: "/hardware/gps/home",
      },
    ],
  },
  { name: "About Us", path: "/software/about" },
  { name: "Contact Us", path: "/contact" },
  { name: "Get Quote", path: "/software/quote" },
  { name: "Career", path: "/career" },
];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [openSubsubmenu, setOpenSubsubmenu] = useState(null);
  const [openThirdSubmenu, setOpenThirdSubmenu] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleMouseEnter = (index) => setOpenSubmenu(index);
  const handleMouseLeave = () => {
    setOpenSubmenu(null);
    setOpenSubsubmenu(null);
    setOpenThirdSubmenu(null);
  };

  const handleSubMouseEnter = (index) => setOpenSubsubmenu(index);
  const handleSubMouseLeave = () => {
    setOpenSubsubmenu(null);
    setOpenThirdSubmenu(null);
  };

  const handleThirdMouseEnter = (index) => setOpenThirdSubmenu(index);
  const handleThirdMouseLeave = () => setOpenThirdSubmenu(null);

  const handleLinkClick = () => {
    setIsOpen(false);
    setOpenSubmenu(null);
    setOpenSubsubmenu(null);
    setOpenThirdSubmenu(null);
  };

  const handleArrowClick = (e, index, level) => {
    e.preventDefault();
    if (level === 1) {
      setOpenSubmenu(openSubmenu === index ? null : index);
    } else if (level === 2) {
      setOpenSubsubmenu(openSubsubmenu === index ? null : index);
    } else if (level === 3) {
      setOpenThirdSubmenu(openThirdSubmenu === index ? null : index);
    }
  };

  return (
    <div className="bg-black">
      <nav className="max-w-7xl mx-auto text-white p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center  ">
            <Link to="/" className=" bg-white absolute">
              <img src={logo} alt="Logo" className="  h-10 " />
            </Link>
          </div>
          <div className="hidden md:flex space-x-6">
            {navLinks.map((link, index) => (
              <div
                key={index}
                className="relative group"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                {link.dropdown ? (
                  <>
                    <div className="flex justify-between items-center">
                      <Link
                        to={link.path}
                        className="hover:text-yellow-400"
                        onClick={handleLinkClick}
                      >
                        {link.name}
                      </Link>
                      <button
                        onClick={(e) => handleArrowClick(e, index, 1)}
                        className="ml-1"
                      >
                        <FaChevronDown />
                      </button>
                    </div>
                    {openSubmenu === index && (
                      <div className="absolute left-0 -mt-0 py-1 w-48 bg-black text-white  shadow-lg z-10 opacity-100 transition-opacity duration-300">
                        {link.dropdown.map((sublink, subIndex) => (
                          <div
                            key={subIndex}
                            className="relative group"
                            onMouseEnter={() => handleSubMouseEnter(subIndex)}
                            onMouseLeave={handleSubMouseLeave}
                          >
                            {sublink.dropdown ? (
                              <>
                                <div className="flex justify-between items-center">
                                  <Link
                                    to={sublink.path}
                                    className="hover:text-yellow-400 w-full text-left px-4 py-2 flex items-center"
                                    onClick={handleLinkClick}
                                  >
                                    {sublink.name}
                                  </Link>
                                  <button
                                    onClick={(e) =>
                                      handleArrowClick(e, subIndex, 2)
                                    }
                                    className="ml-1"
                                  >
                                    <FaChevronDown />
                                  </button>
                                </div>
                                {openSubsubmenu === subIndex && (
                                  <div className="absolute left-full top-2  w-48 bg-black text-white rounded-lg shadow-lg z-10 opacity-100 transition-opacity duration-300">
                                    {sublink.dropdown.map(
                                      (subSubLink, subSubIndex) => (
                                        <div
                                          key={subSubIndex}
                                          className="relative group"
                                          onMouseEnter={() =>
                                            handleThirdMouseEnter(subSubIndex)
                                          }
                                          onMouseLeave={handleThirdMouseLeave}
                                        >
                                          {subSubLink.dropdown ? (
                                            <>
                                              <div className="flex justify-between items-center">
                                                <Link
                                                  to={subSubLink.path}
                                                  className="hover:text-yellow-400 w-full text-left px-4 py-2 flex items-center"
                                                  onClick={handleLinkClick}
                                                >
                                                  {subSubLink.name}
                                                </Link>
                                                <button
                                                  onClick={(e) =>
                                                    handleArrowClick(
                                                      e,
                                                      subSubIndex,
                                                      3
                                                    )
                                                  }
                                                  className="ml-1"
                                                >
                                                  <FaChevronDown />
                                                </button>
                                              </div>
                                              {openThirdSubmenu ===
                                                subSubIndex && (
                                                <div className="absolute left-full top-2 mt-1 w-48 bg-black text-white rounded-lg shadow-lg z-10 opacity-100 transition-opacity duration-300">
                                                  {subSubLink.dropdown.map(
                                                    (thirdLink, thirdIndex) => (
                                                      <Link
                                                        key={thirdIndex}
                                                        to={thirdLink.path}
                                                        className="block px-4 py-2 hover:bg-yellow-400"
                                                        onClick={
                                                          handleLinkClick
                                                        }
                                                      >
                                                        {thirdLink.name}
                                                      </Link>
                                                    )
                                                  )}
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <Link
                                              to={subSubLink.path}
                                              className="block px-4 py-2 hover:bg-yellow-400"
                                              onClick={handleLinkClick}
                                            >
                                              {subSubLink.name}
                                            </Link>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </>
                            ) : (
                              <Link
                                to={sublink.path}
                                className="block px-4 py-2 hover:bg-yellow-400"
                                onClick={handleLinkClick}
                              >
                                {sublink.name}
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <Link
                    to={link.path}
                    className="hover:text-yellow-400"
                    onClick={handleLinkClick}
                  >
                    {link.name}
                  </Link>
                )}
              </div>
            ))}
          </div>
          <div className="hidden md:flex items-center space-x-4">
            <button
              onClick={openModal}
              className="bg-green-500 text-black py-2 px-4 rounded hover:bg-green-600"
            >
              FREE SEO Audit
            </button>
          </div>
          <div className="md:hidden">
            <button onClick={toggleMenu}>
              {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>
        {isOpen && (
          <div className="md:hidden mt-2">
            {navLinks.map((link, index) => (
              <div key={index} className="relative group">
                {link.dropdown ? (
                  <>
                    <div className="flex justify-between items-center">
                      <Link
                        to={link.path}
                        className="block py-2 px-4 hover:bg-gray-200"
                        onClick={handleLinkClick}
                      >
                        {link.name}
                      </Link>
                      <button
                        onClick={(e) => handleArrowClick(e, index, 1)}
                        className="ml-1"
                      >
                        <FaChevronDown />
                      </button>
                    </div>
                    {openSubmenu === index && (
                      <div className="ml-4 space-y-1">
                        {link.dropdown.map((sublink, subIndex) => (
                          <div key={subIndex} className="relative group">
                            {sublink.dropdown ? (
                              <>
                                <div className="flex justify-between items-center">
                                  <Link
                                    to={sublink.path}
                                    className="block py-2 px-4 hover:bg-gray-200 w-full"
                                    onClick={handleLinkClick}
                                  >
                                    {sublink.name}
                                  </Link>
                                  <button
                                    onClick={(e) =>
                                      handleArrowClick(e, subIndex, 2)
                                    }
                                    className="ml-1"
                                  >
                                    <FaChevronDown />
                                  </button>
                                </div>
                                {openSubsubmenu === subIndex && (
                                  <div className="ml-4 space-y-1">
                                    {sublink.dropdown.map(
                                      (subSubLink, subSubIndex) => (
                                        <div
                                          key={subSubIndex}
                                          className="relative group"
                                        >
                                          {subSubLink.dropdown ? (
                                            <>
                                              <div className="flex justify-between items-center">
                                                <Link
                                                  to={subSubLink.path}
                                                  className="block py-2 px-4 w-full"
                                                  onClick={handleLinkClick}
                                                >
                                                  {subSubLink.name}
                                                </Link>
                                                <button
                                                  onClick={(e) =>
                                                    handleArrowClick(
                                                      e,
                                                      subSubIndex,
                                                      3
                                                    )
                                                  }
                                                  className="ml-1"
                                                >
                                                  <FaChevronDown />
                                                </button>
                                              </div>
                                              {openThirdSubmenu ===
                                                subSubIndex && (
                                                <div className="ml-4 space-y-1">
                                                  {subSubLink.dropdown.map(
                                                    (thirdLink, thirdIndex) => (
                                                      <Link
                                                        key={thirdIndex}
                                                        to={thirdLink.path}
                                                        className="block py-2 px-4"
                                                        onClick={
                                                          handleLinkClick
                                                        }
                                                      >
                                                        {thirdLink.name}
                                                      </Link>
                                                    )
                                                  )}
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <Link
                                              to={subSubLink.path}
                                              className="block py-2 px-4"
                                              onClick={handleLinkClick}
                                            >
                                              {subSubLink.name}
                                            </Link>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </>
                            ) : (
                              <Link
                                to={sublink.path}
                                className="block py-2 px-4 hover:bg-gray-200"
                                onClick={handleLinkClick}
                              >
                                {sublink.name}
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <Link
                    to={link.path}
                    className="block py-2 px-4 hover:bg-gray-700"
                    onClick={handleLinkClick}
                  >
                    {link.name}
                  </Link>
                )}
              </div>
            ))}
            <button
              onClick={openModal}
              className="w-full bg-green-500 text-black py-2 px-4 rounded hover:bg-green-600 mt-2"
            >
              FREE SEO Audit
            </button>
          </div>
        )}
      </nav>
      <FreeSeoModel isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default Navbar;
