import React, { useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import hero from "../../assets/software/smo/dev.png";
import about3 from "../../assets/software/smo/about3.png";
import { Link } from "react-router-dom";
import Footer from "../../components/common/Footer";
import SoftwareNavbar from "../../components/common/SoftwareNavbar";
import AsSeenOn from "../../components/software/AsSeeOn";
import Client from "../../components/software/Client";
import WhyUs from "../../components/software/WhyUs";
import SeoPackages from "../../components/software/SeoPackages";

const WebSiteDevelopment = () => {
  useEffect(() => {
    window.scrollY(0, 0);
  }, []);
  return (
    <>
      <SoftwareNavbar />
      <div className="bg-yellow-400 py-16">
        <div className="main max-w-7xl mx-auto justify-center  grid lg:grid-cols-2 px-2 text-center lg:text-start">
          <Slide direction="left">
            <div className="first">
              <p className="text-xl my-4">Website Development Packages</p>
              <p className="lg:text-4xl mt-5 text-2xl font-semibold lg:w-[90%] px-1 lg:px-0 text-center lg:text-start">
                Best Web Design Packages at Lowest Prices
              </p>
              <p className="text-sm leading-loose my-4">
                Are you looking for affordable website development packages in
                India? RankON Technologies offers affordable website development
                packages for small businesses at very affordable prices.
              </p>
              <p className="text-sm my-4">
                Get A FREE Consulting now and choose from our best web
                development packages now!
              </p>

              <br />
              <Link
                to="/software/quote"
                className="text-xl lg:mr-10 mr-2 my-8 px-4 py-2 rounded-md bg-red-600 text-white hover:bg-white hover:text-black"
              >
                Request Proposal
              </Link>
              <Link
                to="/software/packages"
                className="text-xl my-8 px-4 py-2 rounded-md bg-white text-black hover:bg-red-600 hover:text-white"
              >
                Our Packages
              </Link>
            </div>
          </Slide>

          <Slide direction="right">
            <div className="second w-[72%] m-auto">
              <img src={hero} alt="not found" />
            </div>
          </Slide>
        </div>
      </div>

      <AsSeenOn />

      <div className="max-w-7xl mx-auto p-3">
        <p className="text-center text-2xl lg:text-4xl font-bold leading-loose">
          <p>
            {" "}
            Save Big On Small Business Website Development Packages In India
          </p>
        </p>
        <p className="text-center text-xl my-10 leading-loose">
          Give your business a kick start with developing a website. RankON
          Technologies offers affordable website development packages for small
          businesses, and provide great features to make your website unique and
          attractive to your industry competitors.
        </p>
        <p className="text-center text-xl my-10 leading-loose">
          We understand the benefits of a website for any business thus we offer
          affordable website development pricing packages to serve every single
          business no matter small, big or startup.
        </p>
      </div>
      <img src={about3} className="flex m-auto" alt="not found" />

      <WhyUs />
      <SeoPackages />

      <Client />
      <Footer />
    </>
  );
};

export default WebSiteDevelopment;
