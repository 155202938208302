import React, { useEffect, useState } from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";


const SliderCCTV = ({ name }) => {
  const work =[
    { id: 1, image: "https://www.indinatus.com/assets/frontend/productImage/EarlyWarning-N.png" },
    { id: 2, image: "https://www.indinatus.com/assets/frontend/productImage/VehicleClassification-n.png" },
    { id: 3, image: "https://www.indinatus.com/assets/frontend/productImage/PeopleCounting-n.png" },
    { id: 4, image: "https://www.indinatus.com/assets/frontend/productImage/Smart-n.png" },
    // { id: 5, image: e5 },
    // { id: 6, image: e6 },
  ];

 

  return (
    <div className="relative mt-2 my-10">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
        
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        spaceBetween={0}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        {work.map((item) => (
          <SwiperSlide key={item.id}>
            <img
              src={item.image}
              alt="Service"
              className="w-full lg:h-[80vh]"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SliderCCTV;
