import React from "react";
import {
  FaUserCheck,
  FaCalendarAlt,
  FaHandshake,
  FaUsers,
} from "react-icons/fa";
const Campaign = () => {
  const stats = [
    {
      id: 1,
      icon: <FaUserCheck size={50} className="text-yellow-500" />,
      number: "500+",
      description: "Verified Reviews",
    },
    {
      id: 2,
      icon: <FaCalendarAlt size={50} className="text-yellow-500" />,
      number: "10",
      description: "Long Years of Experience",
    },
    {
      id: 3,
      icon: <FaHandshake size={50} className="text-yellow-500" />,
      number: "500+",
      description: "Happy Clients",
    },
    {
      id: 4,
      icon: <FaUsers size={50} className="text-yellow-500" />,
      number: "20+",
      description: "Team Members & Growing",
    },
  ];
  return (
    <div className="my-10">
      <div className="max-w-7xl mx-auto px-5">
        <p className=" text-xl lg:text-2xl font-bold text-yellow-400 mt-3">
          Digital Marketing Services in India like None Other
        </p>
        <p className=" text-2xl lg:text-3xl font-bold text-black mt-3">
          Adding a Success Quotient to Your Digital Marketing Campaign
        </p>
        <p className=" text-xl leading-loose mt-3">
          Recover lost sales, retain customers, increase revenue and get optimum
          results for your Digital Marketing campaign with us!
        </p>
        <p className=" text-xl leading-loose mt-3">
          We may not be the only Digital Marketing Agency in India, but we can
          bet our Digital Marketing solutions will drive your outcomes like none
          other.
        </p>
        <p className=" text-xl leading-loose mt-3">
          Our Digital Marketing Professionals are not just a team of experts who
          are well-versed with the intricacies of Digital Marketing but is a
          group of “restless doers” who are ready to take on any challenge and
          are on the forefront to go above and beyond to keep the best on the
          table.
        </p>
      </div>
      <br />
      <div className="flex flex-wrap justify-center gap-6 p-4">
        {stats.map((stat) => (
          <div
            key={stat.id}
            className="flex flex-col items-center bg-white shadow-lg rounded-lg p-6 text-center w-60"
          >
            <div className="mb-4">{stat.icon}</div>
            <h2 className="text-2xl font-bold">{stat.number}</h2>
            <p className="text-gray-700">{stat.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Campaign;
