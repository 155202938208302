import React from "react";
import { gpsData } from "./gps";
import ProductSlider from "./ProductSlider";

function RenderData() {
  return (
    <div className=" ">
      <div className=" text-center my-10 max-w-7xl mx-auto p-4 ">
        <h4 className="lg:text-4xl text-xl">Welcome to GPS Systems India</h4>

        <p className="lg:text-lg mt-6">
          When tracking comes to mind, GPS Systems India is the best thing which
          can come to your mind. While many other sites are saying the exact
          same thing, we are different. We are in the industry since Indian GPS
          industry was in its nascent stage. When we were introducing GPS
          devices to India, we had to explain to many what GPS devices actually
          do
          <br /> <br />
          We are a New Delhi based GPS systems provider but we have our service
          centre all around the country so you are never far from our reach.
        </p>
      </div>

      <div className=" min-w-screen bg-green-950 text-white">
        <div className="max-w-7xl mx-auto p-4 text-center py-10">
          <h4 className=" lg:text-4xl">GPS Systems India Products</h4>

          <p className=" text-xl my-4 lg:px-5">
            GPS Systems India is the best thing that can happen to you when you
            are looking for tracking solutions. That is a claim which dozens of
            other sites are making, but we are different{" "}
          </p>
        </div>

        <div className=" flex justify-center pb-10 gap-1">
          <button className=" p-1 px-3 border-white border">ALL</button>

          <button className=" p-1 px-3 border-white border">
            Vehicle Tracking
          </button>

          <button className=" p-1 px-3 border-white border">
            Personal Tracking
          </button>

          <button className=" p-1 px-3 border-white border">
            Bike Tracking
          </button>

          <button className=" p-1 px-3 border-white border">
            Car Tracking
          </button>
        </div>


        <div>
            <ProductSlider />
        </div>
      </div>

      <div className=" text-center max-w-7xl mx-auto p-4 ">
        {gpsData.map((data) => (
          <div key={data.id} className=" my-10">
            <h4 className=" lg:text-3xl text-xl font-semibold mb-5">
              {data.heading}
            </h4>

            <p className=" lg:text-lg">{data.p}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RenderData;
