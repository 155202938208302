import React from "react";
import { Link } from "react-router-dom";
import { seo } from "../../data";

const SeoPackages = () => {
  const seoPackages = seo.seoPackage;

  return (
    <div className="my-16">
      <div>
        <p className="lg:text-3xl text-xl font-bold text-center">
          Our <span className="text-yellow-400"> Packages</span> Pricing Plans
        </p>

        <div className="main grid lg:grid-cols-3 gap-5 max-w-7xl mx-auto px-3 mt-8">
          {seoPackages.map((pkg, index) => (
            <div className="card bg-white shadow-md p-5" key={index}>
              <div className="w-16 h-16 rounded-full m-auto flex justify-center items-center bg-yellow-400">
                {pkg.icon}
              </div>
              <p className="text-xl text-center font-bold p-2 mt-3">
                {pkg.title}
              </p>
              <p className="text-sm text-center h-[20%] p-2">{pkg.desc}</p>
              <br />
              <Link
                to="/software/packages"
                className="px-5 py-2 bg-yellow-400 w-fit lg:text-xl text-black font-bold rounded-md mb-10 mx-auto flex"
              >
                {pkg.btn}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SeoPackages;
