import React from "react";
import top from "../../assets/hardware/custmore/top.webp";
import center from "../../assets/hardware/custmore/center.webp";
import HardwareNavbar from "../../components/common/HardwareNavbar";

import { FiPhone, FiMail } from 'react-icons/fi';
import Footer from "../../components/common/Footer";
function CusmoreSUpport() {
  return (

    <>
    <HardwareNavbar />
   <div className=" ">
      <div>
        <img src={top} alt="" />
      </div>

      <div className="max-w-7xl mx-auto p-5">
        <h4 className=" w-full text-center text-[28px]  "> GET IN TOUCH </h4>
        <div className=" grid lg:grid-cols-2 grid-cols-1 ">
          <img src={center} alt=""  className="h-[400px]"/>

          <div >
      <form className="flex flex-col gap-4">
        <input
          type="text"
          name="name"
          placeholder="Name"
          className="mt-1 block w-full p-2 border-b border-b-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-b-indigo-500 sm:text-sm"
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          className="mt-1 block w-full p-2 border-b border-b-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-b-indigo-500 sm:text-sm"
        />
        <input
          type="tel"
          name="phone"
          placeholder="Phone Number"
          className="mt-1 block w-full p-2 border-b border-b-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-b-indigo-500 sm:text-sm"
        />
        <textarea
          name="message"
          placeholder="Message"
          rows="4"
          className="mt-1 block w-full p-2 border-b border-b-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-b-indigo-500 sm:text-sm"
        />
        <button
          type="submit"
          className="w-[100px] lg:mt-[50px] py-2 px-4 inline bg-black text-white font-medium rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Send
        </button>
      </form>
    </div>





    <div className="grid grid-cols-3 gap-8 w-screen mt-[100px] mb-[10px]">
        <div className="flex flex-col items-center">
          <FiPhone className="text-gray-600 text-[120px] p-6 rounded-full border border-black" />
          <span className="mt-4 font-medium text-gray-700">Customer Care Number: 9532730000</span>
        </div>
        <div className="flex flex-col items-center">
          <FiPhone className="text-gray-600 text-[120px] p-6 rounded-full border border-black" />
          <span className="mt-4 font-medium text-gray-700">Customer Care Number: 9532730000</span>
        </div>
        <div className="flex flex-col items-center">
          <FiMail className="text-gray-600 text-[120px] p-6 rounded-full border border-black" />
          <span className="mt-4 font-medium text-gray-700 text-center">
            Support: info@kevinasia.com, info@bestonskyvision.com
          </span>
        </div>
      </div>
    {/* <div className="max-w-md mx-auto p-6 border border-gray-300 rounded-lg shadow-lg">
      <div className="flex items-center space-x-4">
        <FiPhone className="text-indigo-600" />
        <span className="font-medium text-gray-700">Customer Care Number: 9532730000</span>
      </div>
      <div className="flex items-center space-x-4 mt-4">
        <FiPhone className="text-indigo-600" />
        <span className="font-medium text-gray-700">Customer Care Number: 9532730000</span>
      </div>
      <div className="flex items-center space-x-4 mt-4">
        <FiMail className="text-indigo-600" />
        <span className="font-medium text-gray-700">Support: info@kevinasia.com, info@bestonskyvision.com</span>
      </div>
    </div> */}
        </div>
      </div>
    </div>
    <Footer />

    </>
 
  );
}

export default CusmoreSUpport;
