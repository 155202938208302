import React, { useEffect } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const seoPackages = [
  {
    type: "Small Business",
    desc: "SEO Packages",
    priceInr: "20,000 INR",
    priceUsd: "250 USD",
    features: {
      "WEBSITE REVIEW & ANALYSIS": [
        { name: "Max 10 Keywords", available: true },
        { name: "Website & Competitor Analysis", available: true },
        { name: "Content Duplicity Check", available: true },
        { name: "Initial Ranking Report", available: true },
        { name: "Keywords Research", available: true },
      ],
      "ON PAGE SEO ANALYSIS": [
        { name: "Meta Tags Creation", available: true },
        { name: "Canonicalization", available: true },
        { name: "URL Structure", available: true },
        { name: "Content Optimization", available: true },
        { name: "Image Optimization", available: true },
        { name: "Heading Tag Optimization", available: true },
        { name: "Website Speed Optimization", available: true },
        { name: "Robots.txt", available: true },
        { name: "Sitemap Creation", available: true },
        { name: "Google Analytics & Search Console Setup", available: true },
        { name: "Blog Optimization", available: false },
      ],
      "LOCAL SEO SETUP": [
        { name: "Google Map Integration on website", available: true },
        {
          name: "Google My Business Page Setup and Optimization",
          available: true,
        },
        { name: "Local Citations – 5", available: true },
        { name: "Local Classifieds – 2", available: true },
      ],
      "CONTENT MARKETING": [
        { name: "Blog Posting (500 – 700 words) – 1", available: true },
        { name: "Article Writing(500 – 700 words) – 1", available: true },
        { name: "Onsite Blog (1000 – 1500 words) – 1", available: true },
      ],
      "EMAIL OUTREACH": [
        { name: "Guest Blogging", available: false },
        { name: "Broken Backlink Building", available: false },
        { name: "Resource Link Building", available: false },
        { name: "Alerts and Mention", available: false },
        { name: "Link Roundups", available: false },
        { name: "Competitor Backlink Research", available: true },
      ],
      "OFF PAGE SEO": [
        { name: "Social Sharing – 30(Total)", available: true },
        { name: "Blog Social Sharing", available: true },
        { name: "Slide Submissions – 1", available: true },
        { name: "Text Infographic Creation – 1", available: true },
        { name: "Video Creation", available: false },
        { name: "Q & A – 1", available: true },
      ],
      "MONTHLY REPORTING": [
        { name: "Keyword Ranking Report", available: false },
        { name: "Google Analytics Report", available: true },
        { name: "Acquired Links Report", available: true },
      ],
      "CLIENT SUPPORT": [
        { name: "Email", available: true },
        { name: "Chat", available: true },
        { name: "Call", available: false },
      ],
    },
  },
  {
    type: "Mid Size Business",
    desc: "SEO Packages",
    priceInr: "30,000 INR",
    priceUsd: "400 USD",
    features: {
      "WEBSITE REVIEW & ANALYSIS": [
        { name: "Max 20 Keywords", available: true },
        { name: "Website & Competitor Analysis", available: true },
        { name: "Content Duplicity Check", available: true },
        { name: "Initial Ranking Report", available: true },
        { name: "Keywords Research", available: true },
      ],
      "ON PAGE SEO ANALYSIS": [
        { name: "Meta Tags Creation", available: true },
        { name: "Canonicalization", available: true },
        { name: "URL Structure", available: true },
        { name: "Content Optimization", available: true },
        { name: "Image Optimization", available: true },
        { name: "Heading Tag Optimization", available: true },
        { name: "Website Speed Optimization", available: true },
        { name: "Robots.txt", available: true },
        { name: "Sitemap Creation", available: true },
        { name: "Google Analytics & Search Console Setup", available: true },
        { name: "Blog Optimization – 5 Posts", available: true },
      ],
      "LOCAL SEO SETUP": [
        { name: "Google Map Integration on website", available: true },
        {
          name: "Google My Business Page Setup and Optimization",
          available: true,
        },
        { name: "Local Citations – 10", available: true },
        { name: "Local Classifieds – 5", available: true },
      ],
      "CONTENT MARKETING": [
        { name: "Blog Posting (500 – 700 words) – 3", available: true },
        { name: "Article Writing(500 – 700 words) – 3", available: true },
        { name: "Onsite Blog (1000 – 1500 words) – 3", available: true },
      ],
      "EMAIL OUTREACH": [
        { name: "Guest Blogging", available: true },
        { name: "Broken Backlink Building", available: true },
        { name: "Resource Link Building", available: false },
        { name: "Alerts and Mention", available: true },
        { name: "Link Roundups", available: false },
        { name: "Competitor Backlink Research", available: true },
      ],
      "OFF PAGE SEO": [
        { name: "Social Sharing – 40(Total)", available: true },
        { name: "Blog Social Sharing", available: true },
        { name: "Slide Submissions – 2", available: true },
        { name: "Text Infographic Creation – 2", available: true },
        { name: "Video Creation", available: true },
        { name: "Q & A – 3", available: true },
      ],
      "MONTHLY REPORTING": [
        { name: "Keyword Ranking Report", available: true },
        { name: "Google Analytics Report", available: true },
        { name: "Acquired Links Report", available: true },
      ],
      "CLIENT SUPPORT": [
        { name: "Email", available: true },
        { name: "Chat", available: true },
        { name: "Call", available: true },
      ],
    },
  },
  {
    type: "Large or Ecommerce",
    desc: "SEO Packages",
    priceInr: "45,000 INR",
    priceUsd: "600 USD",
    features: {
      "WEBSITE REVIEW & ANALYSIS": [
        { name: "Max 40 Keywords", available: true },
        { name: "Website & Competitor Analysis", available: true },
        { name: "Content Duplicity Check", available: true },
        { name: "Initial Ranking Report", available: true },
        { name: "Keywords Research", available: true },
      ],
      "ON PAGE SEO ANALYSIS": [
        { name: "Meta Tags Creation", available: true },
        { name: "Canonicalization", available: true },
        { name: "URL Structure", available: true },
        { name: "Content Optimization", available: true },
        { name: "Image Optimization", available: true },
        { name: "Heading Tag Optimization", available: true },
        { name: "Website Speed Optimization", available: true },
        { name: "Robots.txt", available: true },
        { name: "Sitemap Creation", available: true },
        { name: "Google Analytics & Search Console Setup", available: true },
        { name: "Blog Optimization – 5 Posts", available: true },
      ],
      "LOCAL SEO SETUP": [
        { name: "Google Map Integration on website", available: true },
        {
          name: "Google My Business Page Setup and Optimization",
          available: true,
        },
        { name: "Local Citations – 15", available: true },
        { name: "Local Classifieds – 10", available: true },
      ],
      "CONTENT MARKETING": [
        { name: "Blog Posting (500 – 700 words) – 4", available: true },
        { name: "Article Writing(500 – 700 words) – 4", available: true },
        { name: "Onsite Blog (1000 – 1500 words) – 4", available: true },
      ],
      "EMAIL OUTREACH": [
        { name: "Guest Blogging", available: true },
        { name: "Broken Backlink Building", available: true },
        { name: "Resource Link Building", available: true },
        { name: "Alerts and Mention", available: true },
        { name: "Link Roundups", available: true },
        { name: "Competitor Backlink Research", available: true },
      ],
      "OFF PAGE SEO": [
        { name: "Social Sharing – 50(Total)", available: true },
        { name: "Blog Social Sharing", available: true },
        { name: "Slide Submissions – 2", available: true },
        { name: "Text Infographic Creation – 3", available: true },
        { name: "Video-1 Minute (Product Based)", available: true },
        { name: "Q & A – 5", available: true },
      ],
      "MONTHLY REPORTING": [
        { name: "Keyword Ranking Report", available: true },
        { name: "Google Analytics Report", available: true },
        { name: "Acquired Links Report", available: true },
      ],
      "CLIENT SUPPORT": [
        { name: "Email", available: true },
        { name: "Chat", available: true },
        { name: "Call", available: true },
      ],
    },
  },
];
const maintenancePackages = [
  {
    type: "Bronze",
    desc: "Website Maintenance Packages",
    priceUsd: "250 USD",
    priceInr: "15000 INR",
    features: [
      { name: "Yearly", available: true },
      { name: "2 Hours Monthly", available: true },
      { name: "Monthly backup Offline", available: true },
      { name: "Monthly Scanning", available: true },
      { name: "Disaster Recovery", available: true },
      { name: "Domain & Hosting Support", available: true },
      { name: "SSL Certificate Monitoring", available: true },
      { name: "Domain Expiration Alert", available: true },
      { name: "Google Analytics Setup", available: true },
      { name: "Search Console Setup", available: true },
      { name: "Webmail Supports", available: true },
      { name: "WP Core & plugins update", available: true },
      { name: "New Page Creation", available: true },
      { name: "Get Started Now!", available: true },
      { name: "T&C Apply", available: true },
    ],
  },
  {
    type: "Silver",
    desc: "Website Maintenance Packages",
    priceUsd: "350 USD",
    priceInr: "22000 INR",
    features: [
      { name: "Yearly", available: true },
      { name: "5 Hours Monthly", available: true },
      { name: "Two off-site backup Monthly", available: true },
      { name: "Monthly Scanning", available: true },
      { name: "Broken link check & fix", available: true },
      { name: "Disaster Recovery", available: true },
      { name: "Domain & Hosting Support", available: true },
      { name: "SSL Certificate Monitoring", available: true },
      { name: "Domain Expiration Alert", available: true },
      { name: "Basic website updates", available: true },
      { name: "Google Analytics Setup", available: true },
      { name: "Search Console Setup", available: true },
      { name: "Webmail Supports", available: true },
      { name: "WP Core & plugins update", available: true },
      { name: "New Page Creation", available: true },
      { name: "Get Started Now!", available: true },
      { name: "T&C Apply", available: true },
    ],
  },
  {
    type: "Gold",
    desc: "Website Maintenance Packages",
    priceUsd: "500 USD",
    priceInr: "40000 INR",
    features: [
      { name: "Yearly", available: true },
      { name: "7 Hours Monthly", available: true },
      { name: "Monthly Offline Backup", available: true },
      { name: "Monthly Security Scanning", available: true },
      { name: "Broken link check & fix", available: true },
      { name: "Disaster Recovery", available: true },
      { name: "Domain & Hosting Support", available: true },
      { name: "SSL Certificate Monitoring", available: true },
      { name: "Domain Expiration Alert", available: true },
      { name: "Basic website updates", available: true },
      { name: "Google Analytics Setup", available: true },
      { name: "Search Console Setup", available: true },
      { name: "Webmail Supports", available: true },
      { name: "WP Core & plugins update", available: true },
      { name: "New Page Creation", available: true },
      { name: "Get Started Now!", available: true },
      { name: "T&C Apply", available: true },
    ],
  },
  {
    type: "Platinum",
    desc: "Website Maintenance Packages",
    priceUsd: "700 USD",
    priceInr: "55000 INR",
    features: [
      { name: "Yearly", available: true },
      { name: "15 Hours Monthly", available: true },
      { name: "Monthly Backup Offline", available: true },
      { name: "Website Performance Monitoring", available: true },
      { name: "Disaster Recovery", available: true },
      { name: "Domain & Hosting Support", available: true },
      { name: "Broken link check & fix", available: true },
      { name: "SSL Certificate Monitoring", available: true },
      { name: "Domain Expiration Alert", available: true },
      { name: "Basic website updates", available: true },
      { name: "Webmail Supports", available: true },
      { name: "Google Analytics Setup", available: true },
      { name: "Search Console Setup", available: true },
      { name: "WP Core & plugins update", available: true },
      { name: "Malware scanning", available: true },
      { name: "New Page Creation", available: true },
      { name: "Get Started Now!", available: true },
      { name: "T&C Apply", available: true },
    ],
  },
];
const smoPackages = [
  {
    type: "Basic",
    desc: "SMO Packages",
    priceUsd: "250 USD",
    priceInr: "20,000 INR",
    features: {
      "Facebook Management": [
        { name: "Page Creation", available: true },
        { name: "Facebook Cover And Profile Pic Creation", available: true },
        { name: "Page Optimization", available: true },
        { name: "Posting Per Week – 3", available: true },
        { name: "Facebook Story Creation", available: false },
        { name: "Post Sharing In Groups", available: false },
        { name: "Video Posting (Provided By Client)", available: false },
        { name: "Page Monitoring", available: false },
        { name: "Facebook Tabs Creation", available: true },
        { name: "Call To Action Button Creation", available: false },
        { name: "Influencer Research", available: false },
        { name: "Creation Of Facebook Polls/ Quizzes", available: false },
        { name: "Responding To Comments", available: true },
      ],
      "Instagram Management": [
        { name: "Page Creation", available: true },
        { name: "Page Optimization", available: true },
        { name: "Posting Per Week – 3", available: true },
        { name: "Engagement Strategy", available: true },
        { name: "Instagram Stories Creation", available: false },
        { name: "Competitors Analysis", available: false },
        { name: "Responding to Comments", available: false },
        { name: "Link With Facebook Page", available: false },
        { name: "IGTV Upload (Video provided by Client)", available: true },
        { name: "Outreach With Influencers", available: false },
        { name: "Image Tagging", available: false },
        { name: "Instagram Analytics Monitoring", available: true },
      ],
      "LinkedIn Management": [
        { name: "Profile Creation", available: true },
        { name: "Profile Pic & Cover Pic Creation", available: true },
        { name: "Profile Optimization", available: true },
        { name: "Company Page Creation", available: true },
        { name: "Competitors Analysis", available: false },
        { name: "Posting Per Week – 2", available: true },
        { name: "LinkedIn Story Creation", available: false },
        { name: "Post Sharing in Groups", available: false },
        { name: "Page Monitoring", available: false },
        { name: "LinkedIn Tabs Creation", available: true },
        { name: "Responding to Comments", available: true },
        { name: "LinkedIn Polls/Quizzes", available: false },
      ],
      "YouTube Management": [
        { name: "Channel Creation", available: false },
        { name: "Channel Pic & Cover Pic Creation", available: false },
        {
          name: "Video Optimization(Video Provided By Client) – 1",
          available: true,
        },
        { name: "Thumbnail Creation", available: true },
        { name: "Keyword Research", available: true },
        { name: "Youtube Status Posting*", available: false },
        { name: "Comment Moderation", available: false },
        { name: "End Screen Cards Creation", available: true },
        { name: "Information Cards Creation", available: true },
        { name: "Video Tags Creation", available: true },
        { name: "Playlist Creation", available: false },
        { name: "Video Creation", available: false },
        { name: "Youtube Analytics Monitoring", available: false },
        { name: "Competitors Analysis", available: false },
        { name: "Video Backlinks", available: false },
      ],
      "Pinterest Management": [
        { name: "Account Setup", available: true },
        { name: "Profile Optimization", available: true },
        { name: "Pin Creation", available: true },
        { name: "Posting Per Week – 5", available: true },
        { name: "Pinterest Board Creation", available: true },
        { name: "Pin Design", available: true },
        { name: "Competitor Analysis", available: true },
        { name: "Pinterest Analytics Monitoring", available: true },
        { name: "Responding to Comments", available: true },
      ],
      "Twitter Management": [
        { name: "Profile Creation", available: true },
        { name: "Profile & Background Picture Creation", available: true },
        { name: "Tweet Per Week – 3", available: true },
        { name: "Retweets", available: false },
        { name: "List Creation", available: false },
        { name: "Active In Trending Hashtag", available: false },
        { name: "Creation Of Poll", available: false },
        { name: "Following Industry Related People", available: false },
        { name: "Creation Of Moments", available: false },
        { name: "Twitter Analytics Monitoring", available: false },
      ],
    },
  },
  {
    type: "Standard",
    desc: "SMO Packages",
    priceUsd: "500 USD",
    priceInr: "40,000 INR",
    features: {
      "Facebook Management": [
        { name: "Page Creation", available: true },
        { name: "Facebook Cover And Profile Pic Creation", available: true },
        { name: "Page Optimization", available: true },
        { name: "Posting Per Week – 5", available: true },
        { name: "Facebook Story Creation", available: true },
        { name: "Post Sharing In Groups", available: true },
        { name: "Video Posting (Provided By Client)", available: true },
        { name: "Page Monitoring", available: true },
        { name: "Facebook Tabs Creation", available: true },
        { name: "Call To Action Button Creation", available: true },
        { name: "Influencer Research", available: true },
        { name: "Creation Of Facebook Polls/ Quizzes", available: true },
        { name: "Responding To Comments", available: true },
      ],
      "Instagram Management": [
        { name: "Page Creation", available: true },
        { name: "Page Optimization", available: true },
        { name: "Posting Per Week – 5", available: true },
        { name: "Engagement Strategy", available: true },
        { name: "Instagram Stories Creation", available: true },
        { name: "Competitors Analysis", available: true },
        { name: "Responding to Comments", available: true },
        { name: "Link With Facebook Page", available: true },
        { name: "IGTV Upload (Video provided by Client)", available: true },
        { name: "Outreach With Influencers", available: true },
        { name: "Image Tagging", available: true },
        { name: "Instagram Analytics Monitoring", available: true },
      ],
      "LinkedIn Management": [
        { name: "Profile Creation", available: true },
        { name: "Profile Pic & Cover Pic Creation", available: true },
        { name: "Profile Optimization", available: true },
        { name: "Company Page Creation", available: true },
        { name: "Competitors Analysis", available: true },
        { name: "Posting Per Week – 4", available: true },
        { name: "LinkedIn Story Creation", available: true },
        { name: "Post Sharing in Groups", available: true },
        { name: "Page Monitoring", available: true },
        { name: "LinkedIn Tabs Creation", available: true },
        { name: "Responding to Comments", available: true },
        { name: "LinkedIn Polls/Quizzes", available: true },
      ],
      "YouTube Management": [
        { name: "Channel Creation", available: true },
        { name: "Channel Art & Logo Creation", available: true },
        { name: "Channel Optimization", available: true },
        { name: "Video Posting Per Month – 4", available: true },
        { name: "Video Optimization", available: true },
        { name: "YouTube Thumbnail Creation", available: true },
        { name: "Engagement Strategy", available: true },
        { name: "Responding to Comments", available: true },
        { name: "Competitor Analysis", available: true },
        { name: "YouTube Analytics Monitoring", available: true },
      ],
      "Pinterest Management": [
        { name: "Account Setup", available: true },
        { name: "Profile Optimization", available: true },
        { name: "Pin Creation", available: true },
        { name: "Posting Per Week – 10", available: true },
        { name: "Pinterest Board Creation", available: true },
        { name: "Pin Design", available: true },
        { name: "Competitor Analysis", available: true },
        { name: "Pinterest Analytics Monitoring", available: true },
        { name: "Responding to Comments", available: true },
      ],
      "Twitter Management": [
        { name: "Account Setup", available: true },
        { name: "Profile Optimization", available: true },
        { name: "Tweet Posting Per Week – 10", available: true },
        { name: "Twitter Banner Creation", available: true },
        { name: "Engagement Strategy", available: true },
        { name: "Competitor Analysis", available: true },
        { name: "Twitter Analytics Monitoring", available: true },
        { name: "Responding to Mentions", available: true },
        { name: "Hashtag Strategy", available: true },
      ],
    },
  },
  {
    type: "Premium",
    desc: "SMO Packages",
    priceUsd: "800 USD",
    priceInr: "60,000 INR",
    features: {
      "Facebook Management": [
        { name: "Page Creation", available: true },
        { name: "Facebook Cover And Profile Pic Creation", available: true },
        { name: "Page Optimization", available: true },
        { name: "Posting Per Week – 7", available: true },
        { name: "Facebook Story Creation", available: true },
        { name: "Post Sharing In Groups", available: true },
        { name: "Video Posting (Provided By Client)", available: true },
        { name: "Page Monitoring", available: true },
        { name: "Facebook Tabs Creation", available: true },
        { name: "Call To Action Button Creation", available: true },
        { name: "Influencer Research", available: true },
        { name: "Creation Of Facebook Polls/ Quizzes", available: true },
        { name: "Responding To Comments", available: true },
      ],
      "Instagram Management": [
        { name: "Page Creation", available: true },
        { name: "Page Optimization", available: true },
        { name: "Posting Per Week – 7", available: true },
        { name: "Engagement Strategy", available: true },
        { name: "Instagram Stories Creation", available: true },
        { name: "Competitors Analysis", available: true },
        { name: "Responding to Comments", available: true },
        { name: "Link With Facebook Page", available: true },
        { name: "IGTV Upload (Video provided by Client)", available: true },
        { name: "Outreach With Influencers", available: true },
        { name: "Image Tagging", available: true },
        { name: "Instagram Analytics Monitoring", available: true },
      ],
      "LinkedIn Management": [
        { name: "Profile Creation", available: true },
        { name: "Profile Pic & Cover Pic Creation", available: true },
        { name: "Profile Optimization", available: true },
        { name: "Company Page Creation", available: true },
        { name: "Competitors Analysis", available: true },
        { name: "Posting Per Week – 5", available: true },
        { name: "LinkedIn Story Creation", available: true },
        { name: "Post Sharing in Groups", available: true },
        { name: "Page Monitoring", available: true },
        { name: "LinkedIn Tabs Creation", available: true },
        { name: "Responding to Comments", available: true },
        { name: "LinkedIn Polls/Quizzes", available: true },
      ],
      "YouTube Management": [
        { name: "Channel Creation", available: true },
        { name: "Channel Art & Logo Creation", available: true },
        { name: "Channel Optimization", available: true },
        { name: "Video Posting Per Month – 8", available: true },
        { name: "Video Optimization", available: true },
        { name: "YouTube Thumbnail Creation", available: true },
        { name: "Engagement Strategy", available: true },
        { name: "Responding to Comments", available: true },
        { name: "Competitor Analysis", available: true },
        { name: "YouTube Analytics Monitoring", available: true },
      ],
      "Pinterest Management": [
        { name: "Account Setup", available: true },
        { name: "Profile Optimization", available: true },
        { name: "Pin Creation", available: true },
        { name: "Posting Per Week – 15", available: true },
        { name: "Pinterest Board Creation", available: true },
        { name: "Pin Design", available: true },
        { name: "Competitor Analysis", available: true },
        { name: "Pinterest Analytics Monitoring", available: true },
        { name: "Responding to Comments", available: true },
      ],
      "Twitter Management": [
        { name: "Account Setup", available: true },
        { name: "Profile Optimization", available: true },
        { name: "Tweet Posting Per Week – 15", available: true },
        { name: "Twitter Banner Creation", available: true },
        { name: "Engagement Strategy", available: true },
        { name: "Competitor Analysis", available: true },
        { name: "Twitter Analytics Monitoring", available: true },
        { name: "Responding to Mentions", available: true },
        { name: "Hashtag Strategy", available: true },
      ],
    },
  },
];

const websitePackages = [
  {
    type: "Startup",
    desc: "Website Development Package",
    priceUsd: "150 USD",
    priceInr: "10,000 INR",
    features: [
      { name: "Upto 5 Pages", available: true },
      { name: "Logo", available: true },
      { name: "Slider", available: true },
      { name: "Dynamic Website", available: true },
      { name: "Domain-1st Year Free (.com or .in)", available: true },
      { name: "Hosting Free For 1st Year", available: true },
      { name: "1 Email – 500 MB Quota", available: true },
      { name: "1 Contact Form", available: true },
      { name: "Free SSL", available: true },
      { name: "Testimonials", available: true },
      { name: "Gallery Section", available: true },
      { name: "Chat Feature", available: true },
      { name: "Content - 1 Rs per word", available: true },
      { name: "Social Profile Link", available: true },
      { name: "Click to Call/Email", available: true },
      { name: "Mobile Responsive", available: true },
      { name: "Login Details Will Be Given", available: true },
      { name: "Delivery in 3 Days", available: true },
      { name: "Renewal – 2000 Rs + Domain Price Plus GST", available: true },
      { name: "Support – 500 Rs/Hr", available: true },
      { name: "Get Started Now!", available: true },
      { name: "T&C Apply", available: true },
    ],
  },
  {
    type: "Small Business",
    desc: "Website Development Package",
    priceUsd: "250 USD",
    priceInr: "20,000 INR",
    features: [
      { name: "10 Pages", available: true },
      { name: "Logo", available: true },
      { name: "Slider", available: true },
      { name: "Dynamic Website", available: true },
      { name: "Domain-1st Year Free (.com or .in)", available: true },
      { name: "Hosting Free For 1st Year", available: true },
      { name: "2 Email – 500 MB Quota Each", available: true },
      { name: "1 Contact Form", available: true },
      { name: "Free SSL", available: true },
      { name: "Testimonials", available: true },
      { name: "Gallery Section", available: true },
      { name: "Chat Feature", available: true },
      { name: "300 Rs/Extra Page", available: true },
      { name: "Content - 1 Rs per word", available: true },
      { name: "Social Profile Link", available: true },
      { name: "Click to Call/Email", available: true },
      { name: "Mobile Responsive", available: true },
      { name: "Login Details Will Be Given", available: true },
      { name: "Delivery in 7 Days", available: true },
      { name: "Renewal – 3000 Rs + Domain Price Plus GST", available: true },
      { name: "Free 4 Hours Support", available: true },
      { name: "Support – 500 Rs/Hr", available: true },
      { name: "Get Started Now!", available: true },
      { name: "T&C Apply", available: true },
    ],
  },
  {
    type: "eCommerce",
    desc: "Website Development Package",
    priceUsd: "420 USD",
    priceInr: "35,000 INR",
    features: [
      { name: "Up to 50 Products", available: true },
      { name: "Logo", available: true },
      { name: "Contact Form – 3", available: true },
      { name: "Content - 1 Rs per word", available: true },
      { name: "300 Rs/Extra Page", available: true },
      { name: "WhatsApp Chat Integration", available: true },
      { name: "Social Media Profiles Integration", available: true },
      { name: "Social Profile Link", available: true },
      { name: "WordPress Login Details Will be Given", available: true },
      { name: "Payment Gateway", available: true },
      { name: "Extra Support – 500 Rs/Hour", available: true },
      { name: "Revisions – 2", available: true },
      { name: "Call/Email/Chat Support", available: true },
      { name: "Get Started Now!", available: true },
      { name: "T&C Apply", available: true },
    ],
  },
];

const SEOPackages = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="flex flex-wrap justify-center mt-8 ">
        {seoPackages.map((pkg, index) => (
          <div
            key={index}
            className="max-w-sm rounded overflow-hidden shadow-lg m-4 "
          >
            <div className="bg-yellow-500 py-5">
              <div className="text-center text-2xl lg:text-3xl font-semibold">
                {pkg.type}
              </div>
              <div className="text-center py-2 px-4">{pkg.desc}</div>
            </div>

            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">
                {pkg.priceInr}/{pkg.priceUsd}
              </div>
              <p className="text-gray-700 text-base">
                Monthly <br />
                EXCLUSIVE OF ALL TAXES
              </p>
            </div>
            <div className="px-6 pt-4 pb-2  ">
              {Object.keys(pkg.features).map((category, idx) => (
                <div key={idx} className="mb-4">
                  <h2 className="font-bold">{category}</h2>
                  {pkg.features[category].map((feature, fIdx) => (
                    <p key={fIdx} className="flex items-center">
                      {feature.available ? (
                        <FaCheckCircle className="text-green-500 mr-2" />
                      ) : (
                        <FaTimesCircle className="text-red-500 mr-2" />
                      )}
                      {feature.name}
                    </p>
                  ))}
                </div>
              ))}
            </div>
            <Link
              to="/software/quote"
              className="flex justify-center w-fit m-auto px-5 py-3 rounded-lg bg-yellow-400 text-white"
            >
              Get Started Now!
            </Link>
            <p className="text-center my-4">T&C Apply</p>
          </div>
        ))}
      </div>

      <div className="grid lg:grid-cols-4 max-w-7xl mx-auto">
        {maintenancePackages.map((pkg, index) => (
          <div
            key={index}
            className="max-w-sm rounded overflow-hidden shadow-lg m-4"
          >
            <div className="bg-yellow-500 py-5">
              <div className="text-center text-2xl lg:text-3xl font-semibold">
                {pkg.type}
              </div>
              <div className="text-center py-2 px-4">{pkg.desc}</div>
            </div>

            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">
                {pkg.priceInr}/{pkg.priceUsd}
              </div>
              <p className="text-gray-700 text-base">
                Monthly <br />
                EXCLUSIVE OF ALL TAXES
              </p>
            </div>
            <div className="px-6 pt-4 pb-2 min-h-[485px]">
              {pkg.features.map((feature, fIdx) => (
                <p key={fIdx} className="flex items-center">
                  {feature.available ? (
                    <FaCheckCircle className="text-green-500 mr-2" />
                  ) : (
                    <FaTimesCircle className="text-red-500 mr-2" />
                  )}
                  {feature.name}
                </p>
              ))}
            </div>
            <br />
            <Link
              to="/software/quote"
              className="flex justify-center w-fit m-auto px-5 py-3 rounded-lg bg-yellow-400 text-white"
            >
              Get Started Now!
            </Link>
            <p className="text-center my-4">T&C Apply</p>
          </div>
        ))}
      </div>
      <div className="grid lg:grid-cols-3 max-w-7xl mx-auto">
        {smoPackages.map((pkg, index) => (
          <div key={index} className="rounded overflow-hidden shadow-lg m-4">
            <div className="bg-yellow-500 py-5">
              <div className="text-center text-2xl lg:text-3xl font-semibold">
                {pkg.type}
              </div>
              <div className="text-center py-2 px-4">{pkg.desc}</div>
            </div>

            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">
                {pkg.priceInr}/{pkg.priceUsd}
              </div>
              <p className="text-gray-700 text-base">
                Monthly <br />
                EXCLUSIVE OF ALL TAXES
              </p>
            </div>

            <div className="px-6 pt-4 pb-2 min-h-[2100px] ">
              {Object.keys(pkg.features).map((category, cIdx) => (
                <div key={cIdx} className="mb-4">
                  <h3 className="font-semibold text-lg mb-2">{category}</h3>
                  {pkg.features[category].map((feature, fIdx) => (
                    <p key={fIdx} className="flex items-center">
                      {feature.available ? (
                        <FaCheckCircle className="text-green-500 mr-2" />
                      ) : (
                        <FaTimesCircle className="text-red-500 mr-2" />
                      )}
                      {feature.name}
                    </p>
                  ))}
                </div>
              ))}
            </div>
            <br />
            <Link
              to="/software/quote"
              className="flex justify-center w-fit m-auto px-5 py-3 rounded-lg bg-yellow-400 text-white"
            >
              Get Started Now!
            </Link>
            <p className="text-center my-4">T&C Apply</p>
          </div>
        ))}
      </div>

      <div className="grid lg:grid-cols-3 max-w-7xl mx-auto">
        {websitePackages.map((pkg, index) => (
          <div
            key={index}
            className="max-w-sm rounded overflow-hidden shadow-lg m-4"
          >
            <div className="bg-yellow-500 py-5">
              <div className="text-center text-2xl lg:text-3xl font-semibold">
                {pkg.type}
              </div>
              <div className="text-center py-2 px-4">{pkg.desc}</div>
            </div>

            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">
                {pkg.priceInr}/{pkg.priceUsd}
              </div>
              <p className="text-gray-700 text-base">
                Monthly <br />
                EXCLUSIVE OF ALL TAXES
              </p>
            </div>
            <div className="px-6 pt-4 pb-2 min-h-[595px]">
              {pkg.features.map((feature, fIdx) => (
                <p key={fIdx} className="flex items-center">
                  {feature.available ? (
                    <FaCheckCircle className="text-green-500 mr-2" />
                  ) : (
                    <FaTimesCircle className="text-red-500 mr-2" />
                  )}
                  {feature.name}
                </p>
              ))}
            </div>
            <br />
            <Link
              to="/software/quote"
              className="flex justify-center w-fit m-auto px-5 py-3 rounded-lg bg-yellow-400 text-white"
            >
              Get Started Now!
            </Link>
            <p className="text-center my-4">T&C Apply</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SEOPackages;
