import React, { useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import Footer from "../common/Footer";
import SoftwareNavbar from "../common/SoftwareNavbar";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <SoftwareNavbar />
      <div className=" py-5  text-black mt-10">
        <div className="relative -z-10 max-w-7xl mx-auto px-4 lg:gap-32 py-1 bg-opacity-90 bg-transparent text-black">
          <div className="main  items-center grid  gap-5 lg:grid-cols-2">
            <Slide direction="left">
              <div className="second">
                <img
                  src="https://cdn-ilajhfl.nitrocdn.com/GWBVsNjvMBCRClaArkbYFtYdcOXeOKtB/assets/images/optimized/rev-1eca500/www.rankontechnologies.com/wp-content/uploads/2022/06/about-us-img.png"
                  alt="Construction"
                />
              </div>
            </Slide>
            <Slide direction="right">
              <div className="first grid gap-4 p-4 md:gap-6 md:p-8">
                <p className="text-xl md:text-3xl font-semibold  border-l-4 border-yellow-400 pl-4">
                  About Provision Plus Technologies- A Well Known Digital
                  Marketing Agency in India
                </p>
                <p className="text-sm md:text-xl ">
                  Founded in 2017, Provision Plus is a leading international
                  software & Hardware Manufacturing ( Security Surveillance ‘
                  CCTV ; LEDs ; GPS )provider for the eCommerce , Service
                  Industry , Banking and Investment Management industries. The
                  company has a presence in Delhi , Mumbai , Ranchi and Gaya
                  delivering innovative solutions to both start-ups and
                  established institutions, through direct communication or a
                  reliable partners network.
                </p>
              </div>
            </Slide>
          </div>
        </div>
      </div>

      <Slide direction="right">
        <div className="max-w-7xl mx-auto p-5">
          <div className=" flex flex-col  w-full items-center">
            <h3 className="  text-4xl font-fjalla text-[#33536B]">Our Focus</h3>
            <div className="flex items-center w-[75px]">
              <div className="h-0.5 bg-[#e2571a]"></div>
              <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
              <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
              <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
              <div
                className="h-[4px] rounded-full w-[10px] flex-grow"
                style={{ backgroundColor: "#e2571a" }}
              ></div>
            </div>
                      
          </div>
          <p className="text-xl text-center mt-5">
            Provision Plus offers innovative, award-winning and flexible
            software & Security solutions developed with the highest standards.
            Continuous R&D investments and close contact with clients and
            associates around the world allow us to anticipate future trends and
            meet the growing market needs.
          </p>
        </div>
      </Slide>

      <Slide direction="right">
        <div className="max-w-7xl mx-auto p-5">
          <div className=" flex flex-col  w-full items-center">
            <h3 className="  text-4xl font-fjalla text-[#33536B]">
              Our Culture
            </h3>
            <div className="flex items-center w-[75px]">
              <div className="h-0.5 bg-[#e2571a]"></div>
              <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
              <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
              <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
              <div
                className="h-[4px] rounded-full w-[10px] flex-grow"
                style={{ backgroundColor: "#e2571a" }}
              ></div>
            </div>
                      
          </div>
          <p className="text-xl text-center mt-5">
            The company provides a challenging environment that encourages
            initiative and promotes commitment to its clients’ business
            objectives.
          </p>
        </div>
      </Slide>
      <Slide direction="right">
        <div className="max-w-7xl mx-auto p-5">
          <div className=" flex flex-col  w-full items-center">
            <h3 className="  text-4xl font-fjalla text-[#33536B]">Vision</h3>
            <div className="flex items-center w-[75px]">
              <div className="h-0.5 bg-[#e2571a]"></div>
              <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
              <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
              <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
              <div
                className="h-[4px] rounded-full w-[10px] flex-grow"
                style={{ backgroundColor: "#e2571a" }}
              ></div>
            </div>
                      
          </div>
          <p className="text-xl text-center mt-5">
            To provide innovative software & Security solutions that enable
            Retail and Financial Institutions and corporates across the world to
            deliver their full potential to their clients.
          </p>
        </div>
      </Slide>
      <Slide direction="right">
        <div className="max-w-7xl mx-auto p-5">
          <div className=" flex flex-col  w-full items-center">
            <h3 className="  text-4xl font-fjalla text-[#33536B]">Mission</h3>
            <div className="flex items-center w-[75px]">
              <div className="h-0.5 bg-[#e2571a]"></div>
              <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
              <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
              <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
              <div
                className="h-[4px] rounded-full w-[10px] flex-grow"
                style={{ backgroundColor: "#e2571a" }}
              ></div>
            </div>
                      
          </div>
          <p className="text-xl text-center mt-5">
            To develop and deliver reliable, client-centric software & Security
            platforms, accompanied by exceptional service, that add value and
            support Institutions.
          </p>
        </div>
      </Slide>
      <Footer />
    </div>
  );
};

export default AboutPage;
