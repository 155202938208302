import React from 'react'
import CCTVNavbar from './CCTVNavbar'
import Slider from '../../../components/old/Slider'
import HomeProduct from './HomeProduct'
import SliderCCTV from './SliderCCTV'
import Achivement from './Achivement'
import Footer from '../../../components/common/Footer'

function HomeCCTV() {
  return (
    <div>
    <CCTVNavbar></CCTVNavbar>
    

    <Slider name="camera" />
    <HomeProduct />
    <SliderCCTV />
    <Achivement />
    <Footer />

    </div>
  )
}

export default HomeCCTV