import React from "react";
import s1 from "../../assets/software/service/s1.jpg";
// import s2 from "../../assets/software/service/s2.png";
// import s3 from "../../assets/software/service/s3.jpg";
// import s4 from "../../assets/software/service/s4.jpg";
// import s5 from "../../assets/software/service/s5.png";
import s6 from "../../assets/software/service/s6.jpg";
// import s7 from "../../assets/software/service/s7.png";
import s8 from "../../assets/software/service/s8.png";
import s9 from "../../assets/software/service/s9.jpg";
import { Link } from "react-router-dom";

const Services = () => {
  const service = [
    {
      img: s1,
      title: "SEO Services",
      desc: "By utilizing the white hat practices, using the best SEO tools, and performing in-depth keyword research, we can help steal initial positions for your website in SERPs.",
      btn: "SEO Services",
      path:"/software/seo"
    },
    // {
    //   img: s2,
    //   title: "PPC Services",
    //   desc: "When you need to increase your sales, leads, and conversions instantly, our PPC services can be the answer. We shall run a result-oriented PPC campaign under the direct supervision of our experts.",
    //   btn: "PPC Services",
    // },
    // {
    //   img: s3,
    //   title: "SMM Services",
    //   desc: "In today’s digital realm, businesses that overlook the power of social media may not perform well. Thus, we help you stay connected to your target audience on social media & keep them engaged.",
    //   btn: "SMM Services",
    // },
    // {
    //   img: s4,
    //   title: "ORM Services",
    //   desc: "Reputation in the online world holds the utmost importance. We help you show a positive facade to the world with the best-in-class online reputation management solutions.",
    //   btn: "ORM Services",
    // },
    // {
    //   img: s5,
    //   title: "Content Writing Services",
    //   desc: "Our content writers know the intricacies of Content Writing as well as SEO. We create informative, compelling and interactive content copies that clearly indicate business objectives.",
    //   btn: "Content Writing Services",
    // },
    {
      img: s6,
      title: "Website Development",
      desc: "Operational as a Web Design & Development Company in India, we create websites that are easier to navigate and give better user experience to the viewers.",
      btn: "Website Development",
      path:"/software/webdev"

    },
    // {
    //   img: s7,
    //   title: "Video Creation Service",
    //   desc: "We are known for creating amazingly attractive explainer videos and animated videos that help promote your business, products, and services in an interesting way.",
    //   btn: "Video Creation Service",
    // },
    {
      img: s8,
      title: "SMO Services",
      desc: "SMO is the process of improving the visibility and reach of a website on social media platforms. We engage people on social media to get new followers and engage with existing ones.",
      btn: "SMO Services",
      path:"/software/smo"

    },
    {
      img: s9,
      title: "Website Designing",
      desc: "Web design services help you to make a perfectly designed website for your business to increase UI/UX. Hire us for Web Design Services at very affordable prices. Request a FREE Quote now!",
      btn: "Website Designing",
      path:"/software/webdesign"

    },
  ];
  return (
    <div className="my-16 bg-gray-200">
      <br />
      <br />
      <div>
        <p className="lg:text-3xl  text-xl font-bold text-center">
          Our <span className="text-yellow-400"> Digital Marketing</span>{" "}
          Services in India
        </p>

        <div className="main grid lg:grid-cols-4 gap-5 max-w-7xl mx-auto px-3 mt-8">
          {service.map((currElem, index) => (
            <Link to={currElem?.path} className="card bg-white shadow-md py-3" key={index}>
              <div className="h-[40%] w-full flex justify-center">
                <img src={currElem.img} alt="not found" />
              </div>

              <p className="text-xl text-center font-bold p-2 mt-16">
                {currElem.title}
              </p>
              <p className="text-sm text-center h-[25%] p-2">{currElem.desc}</p>
              <button className="px-5 py-2 bg-yellow-400 lg:text-xl text-black font-bold rounded-md mb-10 mx-auto flex">
                {currElem.btn}
              </button>
              <br />
            </Link>
          ))}
        </div>
        {/* <button className="px-5 py-2 bg-red-600 lg:text-xl text-white rounded-full mt-16 mx-auto flex">
          Our Service
        </button> */}
        <br />
        <br />
      </div>
    </div>
  );
};

export default Services;
