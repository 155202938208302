import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Slider = ({categories}) => {

  useEffect(()=>{
    console.log(categories)
  },[])
  return (
    <div className="px-5">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        navigation
        autoplay={{ delay: 2000 }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        {categories.map((item) => (
          <SwiperSlide key={item._id}>
            <Link to={`/hardware/subCategory/${item._id}`} className="relative mt-10 h-64 overflow-hidden ]">
          
          <div className="min-h-[300px] max-h-[300px]">

              <img
                src={item?.image}
                alt={item?.name}
                className="w-full h-full object-cover transition-transform duration-500 ease-in-out transform hover:scale-105"
              />
          </div>
              <h3 className="absolute bottom-0 left-0 right-0 bg-red-700 bg-opacity-80 text-white pl-2 text-xl py-2">
                {item.name}
              </h3>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
