import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login, signUp } from "../../../services/operation/user";

const LoginModal = ({ show, onClose }) => {
  const [formData, setFormData] = useState({ email: "", password: "", name: "" });
  const [isSignup, setIsSignup] = useState(false); // Toggle between login and signup modes
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!show) {
    return null;
  }

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    console.log(formData.name, formData.email, formData.password,)
    if (isSignup) {
      await signUp(formData, navigate, dispatch); // Updated to include name
      setIsSignup(false)

    } else {
      await login(formData.email, formData.password, navigate, dispatch);
      onClose();
    }
  };

  const toggleMode = () => {
    setIsSignup(!isSignup);
    setFormData({ email: "", password: "", name: "" }); // Clear form data when toggling
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative">
        <h2 className="text-2xl font-bold text-center mb-6">{isSignup ? "Sign Up" : "Login"}</h2>
        <Link to={"/"} onClick={onClose} className="absolute top-2 right-2 text-gray-900 hover:text-gray-700 text-2xl">
          &times;
        </Link>
        <form className="space-y-4" onSubmit={handleOnSubmit}>
          {isSignup && (
            <div className="space-y-2">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleOnChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
          )}
          <div className="space-y-2">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleOnChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleOnChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <button type="submit" className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
            {isSignup ? "Sign Up" : "Login"}
          </button>
        </form>
        <div className="text-center mt-4">
          <button
            onClick={toggleMode}
            className="text-blue-500 hover:text-blue-700"
          >
            {isSignup ? "Already have an account? Login" : "Don't have an account? Sign Up"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
