export const jobPostings = [
    {
      title: "SPECIAL EDUCATION TEACHER",
      details: "The candidates should provide special education services to meet the requirements of moderate to severe needs students who have a range of learning, emotional, physical and mental disabilities. Candidates with atleast 3 years in the same line will be preferred.\n\nTo apply, mail at: provisionplusindia@gmail.com"
    },
    {
      title: "SR. SECONDARY - PGT ACCOUNTANCY",
      details: "The candidates should be qualified and experienced in Accountancy and Business Studies. He/she will be responsible to plan and prepare well-structured, clearly presented lessons appropriate to the abilities of all students and the syllabus being taught.\n\nTo apply, mail at: provisionplusindia@gmail.com"
    },
    {
      title: "SR. SECONDARY - PGT MATHEMATICS",
      details: "The candidate must have good requisite experience in teaching Maths for higher classes and should have excellent communication skills. Should be passionate about using the latest online learning tools as well and promoting the use of the same in the classrooms.\n\nTo apply, mail at: provisionplusindia@gmail.com"
    }
  ];
  