import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { banking,education ,retail,smart} from '../../solutionData';
import CCTVNavbar from './CCTV/CCTVNavbar';

function Solutions() {
  const { name } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    if (name === "banking") {
      setData(banking);
    }
    if (name === "education") {
      setData(education);
    }
    if (name === "retail") {
      setData(retail);
    }
    if (name === "smart-city") {
      setData(smart);
    }
  }, [name]);

  return (
    <>
      <CCTVNavbar />
    <div className="max-w-7xl mx-auto p">
      <div className="my-8">
        <img src={data?.heroImg} alt="Hero" className="w-full h-auto rounded-lg" />
      </div>
      
      <div className="space-y-8">
        <div>
          <h2 className="text-lg ">{data?.headingOne}</h2>
        </div>

        {data?.headings?.map((head, index) => (
          <div key={index} className={`flex flex-col ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'} items-center space-y-4 md:space-y-0 md:space-x-8`}>
            <div className="flex-1">
              <h3 className="text-2xl font-semibold mb-2">{head?.heading}</h3>
              <p className="text-lg">{head?.para}</p>
            </div>
            {head?.image && (
              <div className="flex-1">
                <img src={head.image} alt={head.heading} className="w-full h-auto rounded-lg shadow-lg" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
    </>
  );
}

export default Solutions;
