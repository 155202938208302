
// const BASE_URL = process.env.REACT_APP_BASE_URL
const BASE_URL = "https://hardware-shop-backend.onrender.com/api/v1"
// const BASE_URL = "https://hardware-shop-backend.onrender.com/api/v1"

export const userEndpoints = {
  LOGIN_API: BASE_URL + "/auth/login",
  SIGNUP_API: BASE_URL + "/auth/register",
  CONTACT_US_API: BASE_URL + "/auth/contact",
  SEO_US_API: BASE_URL + "/auth/seoAudit",
  GET_QOUTES_API: BASE_URL + "/auth/getQoutes",

  //Order
  GET_ALL_ORDER: BASE_URL + "/product/adminGetOrder",
  UPDATE_ORDER: BASE_URL + "/product/updateOrder"
}


export const productEndpoints = {
  ADD_PRODUCT_API: BASE_URL + "/product/add",
  UPDATE_PRODUCT_API: BASE_URL + "/product/edit",
  GET_ALL_PRODUCT_API: BASE_URL + "/product/get-all",
  DETAILS_PRODUCT_API: BASE_URL + "/product",
  DELETE_PRODUCT_API: BASE_URL + "/product/delete",


  // Image
  IMAGE_UPLOAD: BASE_URL + "/image/multi",




  // CateGOry
  ADD_CATEGORY_API: BASE_URL + "/category/create",
  DELETE_CATEGORY_API: BASE_URL + "/category/delete",
  UPDATE_CATEGORY_API: BASE_URL + "/category/update",
  GET_ALL_CATEGORY_API: BASE_URL + "/category/all",
  DETAILS_CATEGORY_API: BASE_URL + "/category",





  // suBCateGOry
  ADD_SUBCATEGORY_API: BASE_URL + "/subcategory/create",
  DELETE_SUBCATEGORY_API: BASE_URL + "/subcategory/delete",
  UPDATE_SUBCATEGORY_API: BASE_URL + "/subcategory/update",
  GET_ALL_SUBCATEGORY_API: BASE_URL + "/subcategory/all",
  DETAILS_SUBCATEGORY_API: BASE_URL + "/subcategory",




}


export const paymentEndpoints = {
  PRODUCT_PAYMENT_API: BASE_URL + "/order/capturePayment",
  PRODUCT_VERIFY_API: BASE_URL + "/order/verifyPayment",
  SEND_PAYMENT_SUCCESS_EMAIL_API: BASE_URL + "/order/sendPaymentSuccessEmail",


  // Get all orders
  GET_ALL_ORDER: BASE_URL + "/order/get",


}