import React from "react";

function Achivement() {
  return (
    <div className="my-10">
      <div className=" text-center font-semibold text-3xl ">Our Achievments</div>

      <div className=" grid lg:grid-cols-5 gap-3 max-w-7xl mx-auto p-4">
        <div>
            <img src="https://www.indinatus.com/assets/frontend/productImage/IndiNatus-ShreeGanesha.jpg" alt="" className="  " />
        </div>

        <div className=" lg:col-span-4">
          <h4 className=" text-2xl my-3 font-serif font-semibold">Inauguration of Delhi Corporate Office</h4>
          <p className=" text-lg">
            The Big Day has Finally Come, On the Day of Ganesh Chaturthi "The
            Day of Ganapati Bappa" We are Pleased to Announce that Today we have
            Inaugurated our First Office / Corporate Office at IndiNatus® India
            Private Limited, 2nd floor, D 92/6, Okhla I, New Delhi - 110020 in
            presence of our entire hardcore team members and family.
          </p>
          <br />
          <p className=" text-lg">
            We are an ISO 14001:2015, Recognized By Startup India and MSME
            (Udyam Accredited) Company, demonstrating our commitment to
            delivering high-quality Video Surveillance Product and services to
            Video Surveillance Industry which serves across a range of private
            and public sector businesses. We have recently announced our Make in
            India Brand "IndiNatus® India Private Limited". Our Product range
            includes range of IP Network Cameras, PTZ Camera and Network Video
            Recorder and we are also available through the Governments Digital
            Marketplace (GeM).
          </p>
        </div>
      </div>
    </div>
  );
}

export default Achivement;
