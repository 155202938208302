import React, { useEffect } from "react";
import Footer from "../../components/common/Footer";
import SoftwareNavbar from "../../components/common/SoftwareNavbar";
import Packages from "../../components/software/Packages";
const SeoPackage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <SoftwareNavbar />
      <Packages />
      <Footer />
    </div>
  );
};

export default SeoPackage;
