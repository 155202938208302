import React from "react";
import { industrySeo } from "../../data";
import { Link } from "react-router-dom";

const Industry = () => {
  return (
    <div>
      <div>
        <br />
        <br />
        <div className=" flex flex-col  w-full items-center">
          <h3 className=" text-2xl lg:text-4xl font-semibold text-center text-black">
            Industry Specialized SEO/SMO Services
          </h3>
          <br />
          <div className="flex items-center w-[75px]">
            <div className="h-0.5 bg-[#cee21a]"></div>
            <div className="h-1 w-1 bg-[#cee21a] rounded-full mx-1"></div>
            <div className="h-1 w-1 bg-[#cee21a] rounded-full mx-1"></div>
            <div className="h-1 w-1 bg-[#cee21a] rounded-full mx-1"></div>
            <div
              className="h-[4px] rounded-full w-[10px] flex-grow"
              style={{ backgroundColor: "#cee21a" }}
            ></div>
          </div>
                    
        </div>
        <div className="flex flex-col lg:flex-row max-w-7xl mx-auto p-5 gap-5 ">
          {industrySeo.map((currElem, index) => (
            <div
              key={index}
              className="shadow-lg flex flex-col  items-center p-2"
            >
              <img src={currElem.img} alt="not found" />
              <Link
                className="px-6 py-3 rounded-full bg-red-600 text-white "
                to={currElem.link}
              >
                {currElem.btn}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Industry;
