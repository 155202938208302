import React, { useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import hero from "../../assets/software/smo/web.png";
import about2 from "../../assets/software/smo/about2.png";
import { Link } from "react-router-dom";
import Footer from "../../components/common/Footer";
import SoftwareNavbar from "../../components/common/SoftwareNavbar";
import AsSeenOn from "../../components/software/AsSeeOn";
import Client from "../../components/software/Client";
import SeoPackages from "../../components/software/SeoPackages";

const WebMaintainPackage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SoftwareNavbar />
      <div className="bg-yellow-400 py-10">
        <div className="main max-w-7xl mx-auto justify-center  grid lg:grid-cols-2 px-2 text-center lg:text-start">
          <Slide direction="left">
            <div className="first">
              <p className="text-xl my-4">Website Maintenance Packages</p>
              <p className="lg:text-4xl mt-5 text-2xl font-semibold lg:w-[90%] px-1 lg:px-0 text-center lg:text-start">
                How Much Do Website Maintenance Packages Cost?
              </p>
              <p className="text-sm leading-loose my-4">
                Searching for website maintenance packages? RankON offers
                affordable website AMC packages for website owners. Website
                support and maintenance is an integral process for online
                businesses.
              </p>
              <p className="text-sm my-4">
                The average cost of website maintenance services in India is
                about INR 20,000 ($270.00 ) per annum.
              </p>

              <br />
              <Link
                to="/software/quote"
                className="text-xl lg:mr-10 mr-2 my-8 px-4 py-2 rounded-md bg-red-600 text-white hover:bg-white hover:text-black"
              >
                Request Proposal
              </Link>
              <Link
                to="/software/packages"
                className="text-xl my-8 px-4 py-2 rounded-md bg-white text-black hover:bg-red-600 hover:text-white"
              >
                Our Packages
              </Link>
            </div>
          </Slide>

          <Slide direction="right">
            <div className="second w-[72%] m-auto">
              <img src={hero} alt="not found" />
            </div>
          </Slide>
        </div>
      </div>

      <AsSeenOn />

      <div className="px-5">
        <div className=" flex flex-col  w-full items-center ">
          <h3 className=" text-2xl lg:text-4xl font-semibold max-w-7xl mx-auto text-black">
            Why Is Support And Website Maintenance Package Essential For Your
            Business Website?{" "}
          </h3>
          <br />
          <div className="flex items-center w-[75px]">
            <div className="h-0.5 bg-[#cee21a]"></div>
            <div className="h-1 w-1 bg-[#cee21a] rounded-full mx-1"></div>
            <div className="h-1 w-1 bg-[#cee21a] rounded-full mx-1"></div>
            <div className="h-1 w-1 bg-[#cee21a] rounded-full mx-1"></div>
            <div
              className="h-[4px] rounded-full w-[10px] flex-grow"
              style={{ backgroundColor: "#cee21a" }}
            ></div>
          </div>
                    
        </div>

        <div className="grid lg:grid-cols-2 gap-5 max-w-7xl mx-auto px-5">
          <div className="text-xl leading-loose">
            <p>
              You have a business fully-functional, attractive, and
              well-optimized business website. You have invested dimes to get
              your business website created. But, for some reason, you neglected
              to take website support and maintenance services. Either you
              didn’t find it worth it, or you were in the over-confidence that
              there wouldn’t be any problem with your website in future, or you
              thought that it’s simply a waste of money to invest in website
              maintenance services. You are wrong, You should get website
              maintenance packages to keep your website up and running when you
              want any update or face any website issue.
            </p>
            <br />
            <p>
              Unfortunately, it was just the second month since your website
              went live; your website acquired some technical glitches. Now you
              approached the website development agency where you gave a
              contract for your website, but again, you met a jerk there. The
              company denied your plea by saying that their website maintenance
              and support expired the previous month itself.
            </p>
          </div>
          <div>
            <img src={about2} alt="not found" />
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="max-w-7xl mx-auto px-5 ">
        <p className="text-center text-2xl lg:text-4xl font-bold leading-loose">
          Best SEO Packages
          <p>
            {" "}
            Website Maintenance Service Packages To Run Your
            <span className="text-yellow-400"> Website Flawlessly</span>
          </p>
        </p>
        <p className="text-center text-xl my-10 leading-loose">
          Though you may find several Website Maintenance Agencies to quickly
          fix your website’s glitches. But, RankON Technologies can be a
          steadfast partner owning tons of capabilities to solve your problems
          without hitting your pocket hard. Our website maintenance packages are
          reasonable and customizable and can be the best solution for your
          website’s issues.
        </p>
      </div>

      <SeoPackages />

      <Client />
      <Footer />
    </>
  );
};

export default WebMaintainPackage;
