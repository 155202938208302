import React from "react";
import hero from "../../assets/software/hero.png";
import { Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="bg-yellow-400 py-10">
      <div className="main max-w-7xl mx-auto justify-center  grid lg:grid-cols-2 px-2 text-center lg:text-start">
        <Slide direction="left">
          <div className="first">
            <p className="lg:text-4xl mt-5 text-2xl font-semibold lg:w-[50%] px-1 lg:px-0 text-center lg:text-start">
              Award Winning Digital Marketing Company in India
            </p>
            <p className="text-2xl my-4">
              Best Digital Marketing Services in India from Top Rated Digital
              Marketing Experts
            </p>
            <p className="text-xl my-4">
              India with professionals with more than 10 years of experience in
              digital marketing services.
            </p>
            <p className="text-xl my-4">
              Are you searching for the best digital marketing services in
              India?{" "}
            </p>
            <p className="text-xl my-4">Contact us to get a FREE Quote!</p>
            <Link
              to="/contact"
              className="lg:text-xl text:sm my-8 mr-5 px-4 py-2 rounded-md bg-black text-white hover:bg-red-600 hover:text-black"
            >
              Request Proposal
            </Link>
            {/* <Link
              to="/software/contact"
              className="lg:text-xl text-sm my-8 px-4 py-2 rounded-md bg-red-500 text-white hover:bg-red-600 hover:text-black"
            >
              Contact US
            </Link> */}
          </div>
        </Slide>

        <Slide direction="right">
          <div className="second w-[72%] m-auto mt-10">
            <img src={hero} alt="not found" />
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default Hero;
