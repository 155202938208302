import React from "react";
import h1 from "../../assets/software/whyhire/h1.png";
import h2 from "../../assets/software/whyhire/h2.png";
const WhyShouldHire = () => {
  const hire = [
    {
      img: h1,
      title: "Dedicated Team",
      desc: "We assign dedicated team members for you so you feel more homely And friendly. Weekly calls and meetings make your way to success more smooth.",
    },
    {
      img: h2,
      title: "Project Management Tool",
      desc: "Everything will be managed in a fast and versatile project management tool so you will feel your team working for your business.",
    },
  ];
  return (
    <div>
      <div className="max-w-7xl mx-auto px-5">
        <p className="lg:text-3xl w-[90%]  text-xl font-bold text-center">
          Why Should You Hire Provision Plus As Your Digital Marketing Company
          in India?
        </p>
        <br />
        <div className="grid lg:grid-cols-2">
          {hire.map((currElem, index) => (
            <div className="card" key={index}>
              <img src={currElem.img} alt="not found" />
              <p className="text-center lg:text-3xl font-bold mt-5 ">
                {currElem.title}
              </p>
              <p className="text-center text-xl  leading-snug mt-4">
                {currElem.desc}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyShouldHire;
