import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { calculateDiscount } from '../../../helper/utills';

function HomeProduct() {
    const { allProducts } = useSelector((state) => state.news);

    // Normalize category name to lowercase for case-insensitive comparison
    const filteredProducts = allProducts?.filter(product => product?.category?.name === "CCTV ");

useEffect(()=>{
    console.log(filteredProducts[0])
},[])

  return (

 <div>
       <div className=' flex justify-center font-semibold text-3xl'>
        <div className=' my-10'>Star Products</div>
    </div>
    <div className='grid lg:grid-cols-4 gap-4 max-w-7xl mx-auto'> 

    
          {filteredProducts?.slice(
                      0,
                     4
                    ).map((product) => (
                      <Link
                        to={`/hardware/product/${product?._id}`}
                        key={product._id}
                        className={`border p-4 rounded-lg ${"flex flex-col"}`}
                      >
                        <div className="min-h-[130px] max-h-[130px] flex items-center justify-center">
                          <img
                            src={product?.images[0]?.url}
                            alt={product.title}
                            className={`mb-4  h-32`}
                          />
                        </div>
                        <div className={`flex flex-col`}>
                          <h3 className="text-lg font-semibold mb-2">
                            {product?.title?.split(" ").slice(0, 8).join(" ")}
                            ...
                          </h3>
                          <div className="text-gray-600 line-through">
                            ₹{product.highPrice}
                          </div>
                          <div className="text-red-500 font-semibold">
                            ₹{product.price}
                          </div>
                          <div className="text-green-500">
                            {calculateDiscount(
                              product?.highPrice - product?.price,
                              product.price
                            )}
                            % Off
                          </div>
                        </div>
                      </Link>
                    ))}
    </div>
 </div>
  )
}

export default HomeProduct