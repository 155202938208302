import React, { useState } from 'react';
import { gpsDevices } from '../../gpsData';
import HardwareNavbar from '../../components/common/HardwareNavbar';
import { FaSearch, FaTimes } from 'react-icons/fa';
import hero from "../../assets/hardware/features/hero.jpg";
import GPSNavbar from './GPS/GPSNavbar';

function Features() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openFullScreen = (image) => {
    setSelectedImage(image);
    setIsFullScreen(true);
  };

  const closeFullScreen = () => {
    setSelectedImage(null);
    setIsFullScreen(false);
  };

  return (
    <div>
      <GPSNavbar />
      <div>
        <img src={hero} alt="" className="w-full h-auto" />
        <div className='my-10 text-center max-w-7xl p-4 mx-auto'>
          <h4 className='mb-8 lg:text-4xl font-semibold text-2xl'>GPS Systems India Products Features</h4>
          <p className='lg:text-xl'>
            A lot of different GPS products are up for grab from GPS Systems India. These products will cover almost every tracking purpose a simple man might have. From personal vehicle tracking, managing the fleet professionally or keeping an eye on kids, elders and pets; every purpose is served with our products.
          </p>
        </div>
      </div>
      <div className="container mx-auto py-8 grid lg:grid-cols-4 gap-4">
        {gpsDevices.map((device, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg p-6 mb-8 relative group">
            <div className="relative">
              <img
                src={device.image}
                alt={device.name}
                className="w-full h-auto mb-4 rounded-lg cursor-pointer"
                onClick={() => openFullScreen(device.image)}
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex justify-center items-center transition-opacity cursor-pointer" onClick={() => openFullScreen(device.image)}>
                <FaSearch className="text-white text-3xl" />
              </div>
            </div>
            <h2 className="text-2xl font-semibold mb-4">{device.name}</h2>
            <ul className="list-disc list-inside text-gray-700">
              {device.features.map((feature, i) => (
                <li key={i} className="mb-2">{feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {isFullScreen && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-50">
          <img src={selectedImage} alt="Full screen" className="w-auto h-auto max-w-full max-h-full"/>
          <button
            className="absolute top-4 right-4 text-white text-4xl"
            onClick={closeFullScreen}
          >
            <FaTimes />
          </button>
        </div>
      )}
    </div>
  );
}

export default Features;
