import React, { useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import hero from "../../assets/software/hero.png";
import { Link } from "react-router-dom";
import Footer from "../../components/common/Footer";
import SoftwareNavbar from "../../components/common/SoftwareNavbar";
import AsSeenOn from "../../components/software/AsSeeOn";
import Client from "../../components/software/Client";
import SeoPackages from "../../components/software/SeoPackages";
import Industry from "../../components/software/Industry";
const SeoPackage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SoftwareNavbar />
      <div className="bg-yellow-400 py-10 px-3">
        <div className="main max-w-7xl mx-auto justify-center  grid lg:grid-cols-2 px-2 text-center lg:text-start">
          <Slide direction="left">
            <div className="first">
              <p className="text-xl my-4">SEO Packages</p>
              <p className="lg:text-4xl mt-5 text-2xl font-semibold lg:w-[90%] px-1 lg:px-0 text-center lg:text-start">
                Monthly SEO Plans: Trusted By 500+ Clients & 97% Success Rate!
              </p>
              <p className="text-sm leading-loose my-4">
                Are you looking for affordable SEO Packages in India? RankON
                Technologies offers affordable SEO packages for small to large
                size businesses. Instead of providing fixed-price monthly SEO
                packages, we give custom and affordable SEO packages that help
                the business requirements of every client. Our average monthly
                SEO cost for a project is 250$ (20,000 INR)/Month to 600$
                (45,000 INR)/Month.
              </p>
              <p className="text-sm my-4">
                Keep reading to know how much does SEO Cost in India.
              </p>

              <br />
              <Link
                to="/software/quote"
                className="text-xl lg:mr-10 mr-3 my-8 px-4 py-2 rounded-md bg-red-600 text-white hover:bg-white hover:text-black"
              >
                Request Proposal
              </Link>
              <Link
                to="/software/packages"
                className="text-xl my-8 px-4 py-2 rounded-md bg-white text-black hover:bg-red-600 hover:text-white"
              >
                SEO Packages
              </Link>
            </div>
          </Slide>

          <Slide direction="right">
            <div className="second w-[72%] m-auto mt-5">
              <img src={hero} alt="not found" />
            </div>
          </Slide>
        </div>
      </div>
      <AsSeenOn />

      <div className="max-w-7xl mx-auto px-5">
        <p className="text-center text-2xl lg:text-4xl font-bold leading-loose">
          Best SEO Packages
          <p>
            {" "}
            That Increase Website Organic SEO Ranking,{" "}
            <span className="text-yellow-400">Traffic & Sales</span>
          </p>
        </p>
        <p className="text-center text-xl my-10 leading-loose">
          SEO packages from Provision Plus don’t only increase your search
          engine rankings but also improve your conversion, domain authority and
          brand visibility. Provision Plus Technologies is a leading search
          engine optimization company in India whose SEO Packages are worth
          every dime. Our SEO packages help you to rank higher in Google search
          results.
        </p>
        <p className="text-center text-xl my-10 leading-loose">
          From website optimization to keyword rankings we take care of every
          single aspect of SEO to increase your organic traffic asap.
        </p>
      </div>

      <SeoPackages />
      <Client />
      <Industry />
      <Footer />
    </>
  );
};

export default SeoPackage;
