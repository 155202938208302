import React from "react";

const Meet = () => {
  return (
    <div className="bg-gray-200">
      <div className="grid lg:grid-cols-2 max-w-7xl mx-auto items-center text-center">
        <div>
          <p className="font-bold text-sm lg:text-xl my-6">
            Customized Packages & Pilot Projects
          </p>
          <p className="font-bold lg:text-3xl m-auto text-2xl my-5 w-[90%]">
            We Believe in "First Deserve Then Desire".
          </p>
          <p className="font-bold text-sm lg:text-xl my-6">
            This Is Why We Are Open for Pilot Projects!
          </p>
          <p className="w-[80%] m-auto font-bold text-sm lg:text-xl my-2 leading-loose">
            We know it may take a few months to establish a trusted relationship
            so we are open to customization.
          </p>
          <button className="px-5 py-2 bg-red-600 lg:text-xl text-white rounded-full mt-8 mx-auto flex">
           
            <a    href="https://wa.me/919212315612" target="_blank"> Let's Meet{" "}</a>
          </button>
        </div>
        <div className="w-[88%] m-auto">
          <img
            src="https://www.rankontechnologies.com/wp-content/uploads/2022/05/imgpsh_fullsize_anim.png"
            alt="not found"
          />
        </div>
      </div>
    </div>
  );
};

export default Meet;
