import React, { useEffect } from "react";
import Footer from "./Footer";
import SoftwareNavbar from "./SoftwareNavbar";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <SoftwareNavbar />
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-10">
        <h1 className="text-3xl font-bold mb-4 text-center text-green-600">
          Privacy Policy
        </h1>

        <section className="mb-8">
          <p className="mb-4 font-semibold text-red-500">
            Disclaimer: In the event of any discrepancy or conflict, the English
            version will prevail over the translation.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Introduction</h2>
          <p className="mb-2">
            We value the trust you place in us and recognize the importance of
            secure transactions and information privacy. This Privacy Policy
            describes how Sonora Engineering OPC Private Limited and its
            affiliates (collectively "Sonora, we, our, us") collect, use, share,
            protect or otherwise process your personal data through Sonora
            website{" "}
            <a
              href="https://www.provisionplus.in"
              className="text-blue-600 underline"
            >
              https://www.provisionplus.in
            </a>
            . While you may be able to browse certain sections of the Platform
            without registering with us, however, please note we do not offer
            any product/service under this Platform outside India and your
            personal data will primarily be stored and processed in India. By
            visiting this Platform, providing your information or availing any
            product/service offered on the Platform, you expressly agree to be
            bound by the terms and conditions of this Privacy Policy, the{" "}
            <a href="#" className="text-blue-600 underline">
              Terms of Use
            </a>{" "}
            and the applicable service/product terms and conditions, and agree
            to be governed by the laws of India including but not limited to the
            laws applicable to data protection and privacy. If you do not agree
            please do not use or access our Platform.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Collection</h2>
          <p className="mb-2">
            We collect your personal data relating to your identity,
            demographics when you use our Platform, services or otherwise
            interact with us during the course of our relationship and related
            information provided from time to time. Some of the information that
            we may collect includes but is not limited to Information provided
            to us during sign-up/registering or using our Platform such as name,
            date of birth, address, telephone/mobile number, email ID and any
            such information shared as proof of identity or address. Some of the
            sensitive personal data may be collected with your consent, such as
            your bank account or credit or debit card or other payment
            instrument information or biometric information such as your facial
            features or physiological information (in order to enable use of
            certain features when opted for, available on the Platform to assist
            you with your shopping experience) etc. all of the above being in
            accordance with applicable law.
          </p>
          <p className="mb-2">
            Some of the information such as your shopping behaviour,
            preferences, call data records, device location, voice, your
            browsing history, and other information that you may provide to us
            from time to time. Our primary goal in doing so is to provide you a
            safe, efficient, smooth, and customised experience. This allows us
            to provide services and features that most likely meet your needs,
            and to customise our Platform to make your experience safer and
            easier. In general, you can browse the Platform without telling us
            who you are or revealing any personal data about yourself. Once you
            give us your personal data, you are not anonymous to us. Where
            possible, we indicate which fields are required and which fields are
            optional. You always have the option to not provide information, by
            choosing not to use a particular service or feature on the Platform.
          </p>
          <p className="mb-2">
            We may track your buying behaviour, preferences, and other
            information that you choose to provide on our Platform. We use this
            information to do research on our users' demographics, interests,
            and behaviour to better understand and serve our users. This
            information is compiled and analysed on an aggregated basis. This
            information may include the URL that you just came from (whether
            this URL is on our Platform or not), which URL you next go to
            (whether this URL is on our Platform or not), your computer browser
            information, and your IP address. If you enrol into our loyalty
            program or participate in third party loyalty program offered by us,
            we will collect and store your personal data such as name, contact
            number, email address, communication address, date of birth, gender,
            zip code, lifestyle information, demographic and work details which
            is provided by you to us or a third-party business partner that
            operates platforms where you can earn loyalty points for purchase of
            goods and services, and/or also redeem them. We will also collect
            your information related to your transactions on Platform and such
            third-party business partner platforms. When such a third-party
            business partner collects your personal data directly from you, you
            will be governed by their privacy policies. We shall not be
            responsible for the third-party business partner’s privacy practices
            or the content of their privacy policies, and we request you to read
            their privacy policies prior to disclosing any information.
          </p>
          <p className="mb-2">
            If you set up an account or transact with us, we may seek some
            additional information, such as billing address, and/ or other
            payment instrument details and tracking information from cheques or
            money orders to provide services, enable transactions or to refund
            for cancelled transactions. If you choose to post messages on our
            message boards, personalised messages, images, photos, gift card
            message box, chat rooms or other message areas or leave
            feedback/product review or if you use voice commands to shop on the
            Platform, we will collect that information you provide to us.
            Furthermore we may use the images shared by you. Please note such
            messages posted by you will be in public domain and can be read by
            others as well, please exercise caution while posting such messages,
            personal details, photos and reviews. We retain this information as
            necessary to resolve disputes, provide customer support, internal
            research and troubleshoot problems as permitted by law. If you send
            us personal correspondence, such as emails or letters, or if other
            users or third parties send us correspondence about your activities
            or postings on the Platform, we may collect such information into a
            file specific to you. While you can browse some sections of our
            Platform without being a registered member, certain activities (such
            as placing an order or consuming our online content or services or
            participating in any event) requires registration. We may use your
            contact information to send you offers based on your previous orders
            or preferences and your interests.
          </p>
          <p className="mb-2">
            If you receive an email, a call from a person/association claiming
            to be from Sonora seeking any personal data like debit/credit card
            PIN, net-banking or mobile banking password, we request you to never
            provide such information. We at Sonora or our affiliate logistics
            partner do not at any time connect with you requesting for such
            information. If you have already revealed such information, report
            it immediately to an appropriate law enforcement agency.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Use</h2>
          <p className="mb-2">
            We use personal data to provide the services you request. To the
            extent we use your personal data to market to you, we will provide
            you the ability to opt-out of such uses. We use your personal data
            to assist sellers and business partners in handling and fulfilling
            orders; enhancing customer experience; to resolve disputes;
            troubleshoot problems; help promote a safe service; collect money;
            measure consumer interest in our products and services, inform you
            about online and offline offers, products, services, and updates;
            customise your experience; detect and protect us against error,
            fraud and other criminal activity; enforce our terms and conditions;
            conduct marketing research, analysis and surveys; and as otherwise
            described to you at the time of collection of information.
          </p>
          <p className="mb-2">
            We will ask for your permission to allow us access to your text
            messages (SMS), instant messages, contacts in your directory,
            camera, photo gallery, location and device information: (i) to send
            commercial communication regarding your orders or other products and
            services (ii) enhance your experience on the platform and provide
            you access to the products and services offered on the Platform by
            sellers, affiliates, partners or lending partners. You understand
            that your access to these products/services may be affected in the
            event permission is not provided to us. In our efforts to
            continually improve our product and service offerings, we collect
            and analyse demographic and profile data about users' activity on
            our Platform. We identify and use your IP address to help diagnose
            problems with our server, and to administer our Platform. Your IP
            address is also used to help identify you and to gather broad
            demographic information.
          </p>
          <p className="mb-2">
            We will occasionally ask you to complete surveys conducted either by
            us or through a third- party market research agency. These surveys
            may ask you for personal data, contact information, gender, date of
            birth, demographic information (like pin code, age or income level)
            attributes such as your interests, household or lifestyle
            information, your purchasing behaviour or history preference and
            other such information that you may choose to provide. The survey
            may involve collection of voice data or video recordings.
            Participation in these surveys are purely voluntary in nature. We
            use this data to tailor your experience at our Platform, providing
            you with content that we think you might be interested in and to
            display content according to your preferences.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Cookies</h2>
          <p className="mb-2">
            We use data collection devices such as "cookies" on certain pages of
            the Platform to help analyse our web page flow, measure promotional
            effectiveness, and promote trust and safety. "Cookies" are small
            files placed on your hard drive that assist us in providing our
            services. Cookies do not contain any of your personal data. We offer
            certain features that are only available through the use of a
            "cookie". We also use cookies to allow you to enter your password
            less frequently during a session. Cookies can also help us provide
            information that is targeted to your interests. Most cookies are
            "session cookies," meaning that they are automatically deleted from
            your hard drive at the end of a session. You are always free to
            decline/delete our cookies if your browser permits, although in that
            case you may not be able to use certain features on the Platform and
            you may be required to re-enter your password more frequently during
            a session.
          </p>
          <p className="mb-2">
            Additionally, you may encounter "cookies" or other similar devices
            on certain pages of the Platform that are placed by third parties.
            We do not control the use of cookies by third parties.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Sharing</h2>
          <p className="mb-2">
            We may share personal data with our other corporate entities and
            affiliates for purposes mentioned under this Policy. These entities
            and affiliates may market to you as a result of such sharing unless
            you explicitly opt-out. We may disclose personal data to third
            parties. This disclosure may be required for us to provide you
            access to our products and services; for fulfilment of your orders;
            for enhancing your experience; for providing feedback on products;
            to collect payments from you; to comply with our legal obligations;
            to conduct market research or surveys; to enforce our Terms of Use;
            to facilitate our marketing and advertising activities; to analyse
            data; to undertake product recommendations to you; to help with
            detection and prevention of identity theft, fraud and other
            potentially illegal acts; and to prevent abuse of our services.
          </p>
          <p className="mb-2">
            We do not disclose your personal data to third parties for their
            marketing and advertising purposes without your explicit consent. We
            may disclose personal data, if required to do so by law or in the
            good faith belief that such disclosure is reasonably necessary to
            respond to subpoenas, court orders, or other legal process. We may
            disclose personal data to law enforcement offices, third party
            rights owners, or others in the good faith belief that such
            disclosure is reasonably necessary to: enforce our Terms of Use or
            Privacy Policy; respond to claims that an advertisement, posting or
            other content violates the rights of a third party; or protect the
            rights, property or personal safety of our users or the general
            public. We and our affiliates will share / sell some or all of your
            personal data with another business entity should we (or our assets)
            plan to merge with, or be acquired by that business entity, or
            re-organization, amalgamate, or restructure business. Should such a
            transaction occur that other business entity (or the new combined
            entity) will be required to follow this Privacy Policy with respect
            to your personal data.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Links to Other Sites</h2>
          <p className="mb-2">
            Our Platform may provide links to other websites or applications
            that may collect personal data about you. We are not responsible for
            the privacy practices or the content of those linked websites.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Security Precautions</h2>
          <p className="mb-2">
            We maintain reasonable physical, electronic, and procedural
            safeguards to protect your personal data. Whenever you access your
            account information, we offer the use of a secure server. Once your
            information is in our possession we adhere to our security
            guidelines to protect it against unauthorised access. However, by
            using the Platform, the users accept the inherent security
            implications of data transmission over the internet and the World
            Wide Web which cannot always be guaranteed as completely secure, and
            therefore, there would always remain certain inherent risks
            regarding use of the Platform. Users are responsible for ensuring
            the protection of login and password records for their account.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Choice/Opt-Out</h2>
          <p className="mb-2">
            We provide all users with the opportunity to opt-out of receiving
            non-essential (promotional, marketing-related) communications from
            us on behalf of our partners, and from us in general, after setting
            up an account. If you want to remove your contact information from
            all Sonora lists and newsletters, please visit{" "}
            <a href="#" className="text-blue-600 underline">
              unsubscribe
            </a>{" "}
            from Sonora.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            Advertisements on Platform
          </h2>
          <p className="mb-2">
            We use third-party advertising companies to serve ads when you visit
            our Platform. These companies may use information (not including
            your name, address, email address, or telephone number) about your
            visits to this and other websites in order to provide advertisements
            about goods and services of interest to you.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Children Information</h2>
          <p className="mb-2">
            We do not knowingly solicit or collect personal data from children
            under the age of 18. If we become aware that a child under the age
            of 18 has provided us with personal data, we will delete such data
            in accordance with applicable law.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Data Retention</h2>
          <p className="mb-2">
            We retain your personal data in accordance with applicable laws, for
            a period no longer than is required for the purposes for which it
            was collected, or as required under any applicable law. However, we
            may retain data related to you if we believe it may be necessary to
            prevent fraud or future abuse, to enable Sonora to exercise its
            legal rights and/or defend against legal claims or if required by
            law or for other legitimate purposes. We may continue to retain your
            data in anonymised form for analytical and research purposes.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Your Consent</h2>
          <p className="mb-2">
            By visiting our Platform or by providing your information, you
            consent to the collection, use, storage, disclosure and otherwise
            processing of your information (including sensitive personal data)
            on the Platform in accordance with this Privacy Policy. If you
            disclose to us any personal data relating to other people, you
            represent that you have the authority to do so and to permit us to
            use the information in accordance with this Privacy Policy.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            Changes to this Privacy Policy
          </h2>
          <p className="mb-2">
            Please check our Privacy Policy periodically for changes. We may
            update this Privacy Policy to reflect changes to our information
            practices. We will alert you to significant changes by posting the
            date our policy got last updated, placing a notice on our Platform,
            or by sending you an email when we are required to do so by
            applicable law. Your continued use of the Platform will signify your
            acceptance of the amended Policy.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Grievance Officer</h2>
          <p className="mb-2">
            In accordance with Information Technology Act 2000 and rules made
            thereunder, the name and contact details of the Grievance Officer
            are provided below:
          </p>
          <p className="mb-2">Name: Nirbhay Gupta</p>
          <p className="mb-2">
            Email:{" "}
            <a
              href="mailto:feedback@provisionplus.in"
              className="text-blue-600 underline"
            >
              feedback@provisionplus.in
            </a>
          </p>
          <p className="mb-2">Time: Mon - Fri (9:00 - 18:00)</p>
        </section>
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
