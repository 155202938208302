import React from "react";
import a1 from "../../assets/software/about/a1.png";
import a2 from "../../assets/software/about/a2.png";
import a3 from "../../assets/software/about/a3.png";
import a4 from "../../assets/software/about/a4.png";
import a5 from "../../assets/software/about/a5.png";
import a6 from "../../assets/software/about/a6.png";
import a7 from "../../assets/software/about/a7.png";
import a8 from "../../assets/software/about/a8.png";

const AsSeenOn = () => {
  const logos = [
    { src: a1, alt: "Hindustan Times" },
    { src: a2, alt: "Mid Day" },
    { src: a3, alt: "ANI" },
    { src: a4, alt: "The Print" },
    { src: a5, alt: "Business Standard" },
    { src: a6, alt: "Lokmat Times" },
    { src: a7, alt: "Lokmat Times" },
    { src: a8, alt: "Lokmat Times" },
  ];

  return (
    <section className="py-10 bg-white my-20">
      <div className="flex justify-center"></div>
      <div className="flex flex-col items-center relative mt-[-1.5rem]">
        <div className="bg-black w-[80%] sm:w-[60%] md:w-[40%] lg:w-[30%] h-[70%] absolute top-5 transform rounded-lg -translate-y-1/2 z-0">
          <div className="relative bg-black text-white py-2 px-6 text-lg sm:text-xl md:text-2xl rounded-lg font-bold z-10 text-center">
            AS SEEN ON
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-4 sm:gap-6 lg:gap-8 z-10 mt-8">
          {logos.map((logo, index) => (
            <div
              key={index}
              className="w-20 h-20 sm:w-24 sm:h-24 lg:w-32 lg:h-32 bg-white rounded-full flex justify-center items-center shadow-md"
            >
              <img
                src={logo.src}
                alt={logo.alt}
                className="max-w-3/4 max-h-3/4 rounded-full"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AsSeenOn;
