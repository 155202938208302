import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
// import HomePage from "./pages/HomePage";
import SoftwareHome from "./pages/SoftwareHome";
import Layout from "./components/Admin/pages/Layout";
import PrivateRoute from "./components/Admin/auth/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import Login from "./components/Admin/pages/Login";
import OpenRoute from "./components/Admin/auth/OpenRoute";
import AddProduct from "./components/Admin/pages/AddProduct";
import AllProduct from "./components/Admin/pages/AllProduct";
import Contact from "./components/software/Contact";
import Quote from "./components/software/Quote";
import AboutPage from "./components/software/AboutPage";
import ServicePage from "./pages/software/ServicePage";
import { getAllProduct } from "./services/operation/product";
import HardwareHome from "./pages/Hardware/HardwareHome";
import SingleCategory from "./pages/Hardware/SingleCategory";
// import ProductCategoryManager from "./pages/Test";
import ContactHardware from "./pages/Hardware/ContactUs";
import HardwareAboutPage from "./pages/Hardware/AboutUsHArdware";
import HardwareGetQuote from "./pages/Hardware/AuotoHardware";
import ProductDetails from "./pages/Hardware/ProductDetails";
import SubCategory from "./pages/Hardware/SubCategory";
import CartMain from "./pages/Hardware/Cart";
import Modal from "./components/core/Cart/Modal";
import { setCheckout } from "./redux/paymentSlice";
import CheckoutForm from "./components/core/Cart/CheckoutForm";
import Whatsapp from "./components/common/Whatsapp";
import Package from "./pages/software/Package";
import SeoPackage from "./pages/software/SeoPackage";
import SmoPackage from "./pages/software/SmoPackage";
import WebMaintainPackage from "./pages/software/WebMaintainPackage";
import WebSiteDevelopment from "./pages/software/WebSiteDevelopment";
import CusmoreSUpport from "./pages/Hardware/CusmoreSUpport";
import Category from "./components/Admin/pages/Category";
import AdminSubCategory from "./components/Admin/pages/SubCategory";
import Fetures from "./pages/Hardware/Fetures";
import Soltions from "./pages/Hardware/Soltions";
import CCTVProduct from "./pages/Hardware/CCTVProduct";
import GPSProduct from "./pages/Hardware/GPSProduct";
import HomeCCTV from "./pages/Hardware/CCTV/HomeCCTV";
import AboutPageCCTV from "./pages/Hardware/CCTV/CCTVAbout";
import BecomePartner from "./pages/Hardware/CCTV/BecomeParter";
import SingleSubCategory from "./pages/Hardware/CCTV/SingleCategory";
import TVHome from "./pages/Hardware/TV/TVHome";
import TvAbout from "./pages/Hardware/TV/TvAbout";
import CustomerSupport from "./pages/Hardware/TV/CustomerSupport";
import LedTv from "./pages/Hardware/TV/LedTv";
import AboutPageGPS from "./pages/Hardware/GPS/GPSAbout";
import Features from "./pages/Hardware/Fetures";
import HomeGPS from "./pages/Hardware/GPS/HomeGPS";
import RefundPolicy from "./components/common/RefundPolicy";
import PrivacyPolicy from "./components/common/PrivacyPolicy";
import ReplacementPolicy from "./components/common/ReplacementPolicy";
import Career from "./pages/Career";
import Terms from "./components/common/Terms";
import Orders from "./components/Admin/pages/Order";
const App = () => {
  const { user } = useSelector((state) => state.auth);
  const { checkout } = useSelector((state) => state.payment);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProduct());
  }, []);
  return (
    <div>
      <Routes>
        {/* <Route path="/" element={<HomePage />} /> */}
        <Route
          path="/login"
          element={
            <OpenRoute>
              <Login />
            </OpenRoute>
          }
        />
        <Route path="/" element={<SoftwareHome />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/software/quote" element={<Quote />} />
        <Route path="/software/about" element={<AboutPage />} />
        <Route path="/software/packages" element={<Package />} />
        <Route path="/software/seoPackage" element={<SeoPackage />} />
        <Route path="/software/smoPackage" element={<SmoPackage />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/replacementpolicy" element={<ReplacementPolicy />} />
        <Route path="/career" element={<Career />} />
        <Route path="/tc" element={<Terms />} />
        <Route
          path="/software/website-maintenance-packages"
          element={<WebMaintainPackage />}
        />
        <Route
          path="/software/website-development-packages"
          element={<WebSiteDevelopment />}
        />
        <Route path="/software/:name" element={<ServicePage />} />

        {/* <Route path="/test" element={<ProductCategoryManager />} /> */}

        {/* //Hardware */}

        <Route path="/hardware/home" element={<HardwareHome />} />
        <Route path="/hardware/category/tv/:id" element={<SingleCategory />} />
        <Route path="/hardware/category/cctv/:id" element={<CCTVProduct />} />

        <Route path="/hardware/subCategory/:id" element={<SubCategory />} />
        <Route path="/hardware/contact" element={<ContactHardware />} />
        <Route path="/hardware/quote" element={<HardwareGetQuote />} />
        <Route path="/hardware/about" element={<HardwareAboutPage />} />
        <Route path="/hardware/cart" element={<CartMain />} />
        <Route path="/hardware/fetaures" element={<Fetures />} />
        <Route path="/hardware/custmoreS" element={<CusmoreSUpport />} />
        <Route
          path="/hardware/product/:productID"
          element={<ProductDetails />}
        />

        {/* //CCTV */}
        <Route path="/hardware/cctv/home" element={<HomeCCTV />} />
        <Route path="/hardware/cctv/about" element={<AboutPageCCTV />} />
        <Route path="/hardware/cctv/become" element={<BecomePartner />} />
        <Route path="/hardware/solution/:name" element={<Soltions />} />
        <Route
          path="/hardware/cctv/subcategory/:subcat"
          element={<SingleSubCategory />}
        />

        {/* TV */}
        <Route path="/hardware/tv/home" element={<TVHome />} />
        <Route path="/hardware/tv/about" element={<TvAbout />} />
        <Route
          path="/hardware/tv/customer-support"
          element={<CustomerSupport />}
        />
        <Route path="/hardware/tv/LED-TVS" element={<LedTv />} />

        {/* GPS */}
        <Route path="/hardware/category/gps/:id" element={<GPSProduct />} />
        <Route path="/hardware/gps/home" element={<HomeGPS />} />
        <Route path="/hardware/gps/about" element={<AboutPageGPS />} />
        <Route path="/hardware/gps/features" element={<Features />} />

        {/* Admin Pages */}
        {user && user?.acccountType === "admin" && (
          <Route
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }
          >
            <Route
              path="/admin/dashboard"
              element={<div> Ha me hu dashboard</div>}
            />
            <Route path="/admin/createproduct" element={<AddProduct />} />
            <Route path="/admin/createproduct/:id" element={<AddProduct />} />
            <Route path="/admin/getallproducts" element={<AllProduct />} />
            <Route path="/admin/category" element={<Category />} />
            <Route path="/admin/subcategory" element={<AdminSubCategory />} />
            <Route path="/admin/orders" element={<Orders />} />
          </Route>
        )}
      </Routes>

      {checkout && (
        <div className="min-w-screen min-h-screen flex flex-col">
          <PrivateRoute>
            <Modal
              show={checkout}
              handleClose={() => dispatch(setCheckout(false))}
            >
              <CheckoutForm handleClose={() => dispatch(setCheckout(false))} />
            </Modal>
          </PrivateRoute>
        </div>
      )}

      <div className="fixed bottom-8 md:left-10 left-4 z-50">
        <Whatsapp />
      </div>
    </div>
  );
};

export default App;
