import React from 'react'
import Slider from '../../../components/core/home/Slider'
import { useSelector } from 'react-redux';
import {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
  } from "swiper/modules";
  import { Swiper, SwiperSlide } from "swiper/react";
  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
  import "swiper/css/scrollbar";
  import "swiper/css/autoplay";
  import { Link } from "react-router-dom";
  import { useEffect } from "react";
  

function ProductSlider() {
    const { allProducts } = useSelector((state) => state.news);

    // Normalize category name to lowercase for case-insensitive comparison
    const filteredProducts = allProducts?.filter(product => product?.category?.name === "GPS Devices");

    useEffect(()=>{
        console.log(filteredProducts )
    },[])
  return (
    <div>
        <div className="px-5">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        navigation
        autoplay={{ delay: 2000 }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        {filteredProducts?.map((item) => (
          <SwiperSlide key={item._id}>
            <Link to={`/hardware/product/${item._id}`} className="relative mt-10 h-64 overflow-hidden ]">
          
          <div className="min-h-[300px] max-h-[300px] bg-white">

              <img
                src={item?.images[0]?.url}
                alt={item?.title}
                className="w-full h-full object-cover transition-transform duration-500 ease-in-out transform hover:scale-105"
              />
          </div>
              <h3 className="absolute bottom-0 left-0 right-0 bg-red-700 bg-opacity-80 text-white pl-2 text-xl py-2">
              {item?.title.slice(0, 10)}
              </h3>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
    </div>
  )
}

export default ProductSlider