import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allProducts: localStorage.getItem("allProducts")
    ? JSON.parse(localStorage.getItem("allProducts"))
    : [],
  category: localStorage.getItem("category")
    ? JSON.parse(localStorage.getItem("category"))
    : [],
  isMenuOpen: false,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    saveProducts: (state, action) => {
      state.allProducts = action.payload;
      localStorage.setItem("allProducts", JSON.stringify(state.allProducts));
    },
    saveCategory: (state, action) => {
      state.category = action.payload;
      localStorage.setItem("category", JSON.stringify(state.category));
    },
    handleIsMenuOpen: (state, action) => {
      state.isMenuOpen = action.payload !== undefined ? action.payload : !state.isMenuOpen;
    },
  },
});

export const { saveProducts, saveCategory, handleIsMenuOpen } = productSlice.actions;

export default productSlice.reducer;
