import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { displayMoney } from "../../../helper/utills";
import { FaTag } from "react-icons/fa";
import { Link } from "react-router-dom";

const TopProducts = () => {
  const { allProducts } = useSelector((state) => state.news);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Sort by createdAt in descending order
  const sortedProducts = [...allProducts].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  const topFiveProducts = sortedProducts?.slice(0, 8); // Get the first 8 products

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col w-full items-center mb-8">
        <h3 className="text-4xl font-fjalla text-[#33536B]">Latest Drop</h3>
        <div className="flex items-center w-[75px] mt-2">
          <div className="h-0.5 bg-[#e2571a]"></div>
          <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
          <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
          <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
          <div
            className="h-[4px] rounded-full w-[10px] flex-grow"
            style={{ backgroundColor: "#e2571a" }}
          ></div>
        </div>
      </div>
      <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {topFiveProducts?.map((product, index) => (
          <Link
            to={`/hardware/product/${product?._id}`}
            key={index}
            className="bg-white rounded-lg shadow-md p-4 overflow-hidden transition transform hover:shadow-lg hover:scale-105"
          >
            <div className="overflow-hidden rounded-lg mb-4 relative">
              <img
                src={product.images[0].url} // Assuming the image URL is stored in images[0].url
                alt={product.title}
                className="w-full h-48 object-cover transform transition-transform duration-500 ease-in-out hover:scale-110"
              />
              {product?.highPrice >= 0 &&
                product?.price < product?.highPrice && (
                  <div className="absolute top-2 left-2 text-[20px] bg-gradient-to-r from-black to-red-500 text-white text-xl font-semibold py-1 px-4 rounded-full shadow-lg flex items-center">
                    <FaTag className="mr-1 text-sm" />
                    {Math.round(
                      ((product.highPrice - product.price) /
                        product.highPrice) *
                        100
                    )}
                    %
                  </div>
                )}
            </div>
            <h3 className="text-lg font-semibold mb-2">{product.title}</h3>
            <div className="flex justify-between items-center">
              <span className="text-gray-800 font-bold">
                Price: {displayMoney(product.price)}
              </span>
            </div>
            <div className="text-gray-600 mt-2">
              {product.category.name} / {product.subcategory.name}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default TopProducts;
