import React, { useState, useEffect } from "react";
import { FaThLarge, FaThList } from "react-icons/fa";
import { Link } from "react-router-dom";
import { calculateDiscount } from "../../../helper/utills";

const CombinedComponent = ({ products }) => {
  const [viewMode, setViewMode] = useState("grid");
  const [sortOption, setSortOption] = useState("");
  const [priceRange, setPriceRange] = useState([0, 100000]);

  // States for filters
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedResolution, setSelectedResolution] = useState("");
  const [selectedType, setSelectedType] = useState("");

  // Extract unique filter options from product titles
  const extractKeywords = (products, keywordType) => {
    const keywordSet = new Set();
    products.forEach(product => {
      const keywords = product.title.split(" ");
      keywords.forEach(keyword => keywordSet.add(keyword));
    });
    return Array.from(keywordSet).filter(keyword => keywordType.includes(keyword));
  };

  // Ensure these arrays have valid data
  const brands = extractKeywords(products, ["Aiwa", "Akai", "Amstrad", "BPL", "Haier", "HANBOA", "Hisense", "IFFALCON", "Intec", "Intel", "LG", "Lloyd", "Lumiford", "MITASHI", "NVY", "Panasonic", "Realme", "Samsung", "Sansui", "Sharp", "Sony", "TCL", "TOSHIBA"]);
  const screenSizes = extractKeywords(products, ["24", "32", "40", "43", "49", "50", "55", "58", "65", "70", "75"]);
  const resolutions = extractKeywords(products, ["HD","FHD", "HD Ready", "HD Ultra","Ultra"]);
  const screenTypes = extractKeywords(products, ["LED", "OLED", "QLED"]);

  // Log filter options for debugging
  console.log("Brands:", brands);
  console.log("Screen Sizes:", screenSizes);
  console.log("Resolutions:", resolutions);
  console.log("Screen Types:", screenTypes);

  // Function to handle sorting
  const handleSort = (e) => {
    const value = e.target.value;
    setSortOption(value);
  };

  // Function to handle price range filter
  const handlePriceRange = (e) => {
    const value = parseInt(e.target.value, 10);
    setPriceRange([0, value]);
  };

  // Apply sorting
  const sortedProducts = [...products].sort((a, b) => {
    if (sortOption === "Price: Low to High") {
      return a.price - b.price;
    }
    if (sortOption === "Price: High to Low") {
      return b.price - a.price;
    }
    return 0;
  });

  // Apply price range and other filters
  const filteredProducts = sortedProducts.filter(
    (product) => product.price >= priceRange[0] && product.price <= priceRange[1]
  ).filter(product => {
    return (
      (!selectedBrand || product.title.includes(selectedBrand)) &&
      (!selectedSize || product.title.includes(selectedSize)) &&
      (!selectedResolution || product.title.includes(selectedResolution)) &&
      (!selectedType || product.title.includes(selectedType))
    );
  });

  return (
    <div className="container mx-auto p-6 relative">
      <div className="flex flex-col md:flex-row">
        {/* Filter Sidebar */}
        <div className="w-full md:w-1/4 bg-white p-4 border-r sticky top-10">
          <h2 className="text-xl font-semibold mb-4">Filters</h2>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Sort By</h3>
            <select className="w-full p-2 border rounded" value={sortOption} onChange={handleSort}>
              <option value="">Sort By</option>
              <option value="Price: Low to High">Price: Low to High</option>
              <option value="Price: High to Low">Price: High to Low</option>
            </select>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Price</h3>
            <div className="flex justify-between text-gray-600">
              <span>₹{priceRange[0]}</span>
              <span>₹{priceRange[1]}</span>
            </div>
            <input
              type="range"
              min="0"
              max="100000"
              step="1000"
              value={priceRange[1]}
              onChange={handlePriceRange}
              className="w-full h-2 bg-red-500 rounded-lg appearance-none cursor-pointer"
            />
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Brand</h3>
            <select className="w-full p-2 border rounded" value={selectedBrand} onChange={(e) => setSelectedBrand(e.target.value)}>
              <option value="">All Brands</option>
              {brands.map(brand => <option key={brand} value={brand}>{brand}</option>)}
            </select>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Screen Size</h3>
            <select className="w-full p-2 border rounded" value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)}>
              <option value="">All Sizes</option>
              {screenSizes.map(size => <option key={size} value={size}>{size}"</option>)}
            </select>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Resolution</h3>
            <select className="w-full p-2 border rounded" value={selectedResolution} onChange={(e) => setSelectedResolution(e.target.value)}>
              <option value="">All Resolutions</option>
              {resolutions.map(resolution => <option key={resolution} value={resolution}>{resolution}</option>)}
            </select>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Screen Type</h3>
            <select className="w-full p-2 border rounded" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
              <option value="">All Types</option>
              {screenTypes.map(type => <option key={type} value={type}>{type}</option>)}
            </select>
          </div>
        </div>
        {/* Product List */}
        <div className="w-full md:w-3/4 p-4">
          {/* Icons for toggling view modes */}
          <div className="flex justify-end mb-4">
            <FaThLarge
              className={`cursor-pointer mx-2 ${
                viewMode === "grid" ? "text-blue-500" : "text-gray-500"
              }`}
              size={24}
              onClick={() => setViewMode("grid")}
            />
            <FaThList
              className={`cursor-pointer mx-2 ${
                viewMode === "list" ? "text-blue-500" : "text-gray-500"
              }`}
              size={24}
              onClick={() => setViewMode("list")}
            />
          </div>
          <div
            className={`${
              viewMode === "grid"
                ? "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
                : "flex flex-col"
            }`}
          >
            {filteredProducts.map((product) => (
              <Link
                to={`/hardware/product/${product?._id}`}
                key={product.id}
                className={`border p-4 rounded-lg ${
                  viewMode === "grid" ? "flex flex-col" : "flex flex-row"
                }`}
              >
                <img
                  src={product?.images[0]?.url}
                  alt={product.title}
                  className={`${
                    viewMode === "grid"
                      ? "mb-4 w-full h-48 object-cover"
                      : "w-32 h-32 object-cover"
                  }`}
                />
                <div
                  className={`flex flex-col ${
                    viewMode === "grid" ? "" : "ml-4"
                  }`}
                >
                  <h3 className="text-lg font-semibold mb-2">
                    {product.title.split(" ").slice(0, 8).join(" ")}...
                  </h3>
                  <div className="text-gray-600 line-through">
                    ₹{product.highPrice}
                  </div>
                  <div className="text-red-500 font-semibold">
                    ₹{product.price}
                  </div>
                  <div className="text-green-500">
                    {calculateDiscount(
                      product?.highPrice - product?.price,
                      product.price
                    )}
                    % Off
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CombinedComponent;
