import React, { useEffect } from "react";
import Footer from "./Footer";
import SoftwareNavbar from "../common/SoftwareNavbar";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="my-10">
      <SoftwareNavbar />
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-10">
        <h1 className="text-3xl font-bold mb-4 text-center text-green-600">
          Refund Policy
        </h1>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            How Can I Cancel my Order?
          </h2>
          <p className="mb-2">
            Track your order and check the current order status. At
            Provisionplus.in, you can cancel your order BEFORE it has been
            processed by the brand/seller. Here's how to cancel your order:
          </p>
          <ul className="list-disc list-inside ml-4 mb-2">
            <li>Log in to your account and go to My Account.</li>
            <li>Select the appropriate order from Recent Orders.</li>
            <li>
              Click on ‘Cancel Order’ for the items you want to cancel,
              individually.
            </li>
            <li>Select the reason and confirm the cancellation.</li>
          </ul>
          <p>
            Once you're done, we'll send you an acknowledgement of the
            cancellation. Remember, you won’t be able to cancel an order after
            it has been processed by the brand/seller.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            When Can I Cancel my order?
          </h2>
          <p>
            Make sure you cancel your order before it has been processed by the
            brand/seller. That's the only way we can refund the full amount
            you've spent.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            Can I Cancel only part of my order?
          </h2>
          <p>
            Yes, you can. You can individually cancel products in your bag. Just
            make sure your order is NOT processed by the brand/seller yet. Go to
            the "Recent Orders" tab in the "My Account" section to cancel
            products.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            Why Do I see a disabled cancel link?
          </h2>
          <p>
            That means the products from your order have already been shipped
            and you can't make any cancellations now.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            How will I get my refund when I cancel an order?
          </h2>
          <p>
            Once your order has been cancelled, we initiate the refund
            immediately back to the source mode of payment. It typically takes
            3-4 business days, or up to 10-15 days for some public sector banks,
            for your refund to be processed and the amount to be transferred
            back to the source account.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            Will I get the complete refund for the order I’ve cancelled?
          </h2>
          <p>
            No. We'll refund the partial amount for a cancelled order if
            cancelled after 3rd day of purchase.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            This is not what I Ordered. How do I replace it?
          </h2>
          <p>
            If your order or a part of it does not match the product
            description, we'll look into it right away. You can initiate a
            return request by updating the correct details about the received
            product through 'Recent Order' tab in the My Account section.
          </p>
        </section>
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default RefundPolicy;
