import TVNavbar from "./TVNavbar";
import HeroSlider from "../../../components/old/Slider";
import SmartTv from "./SmartTv";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchSingleCategory } from "../../../services/operation/product";
import Choose from "./Choose";
import Footer from "../../../components/common/Footer";

const TVHome = () => {
  const [categories, setCategories] = useState([]);
  const [product, setProducts] = useState([]);
  const { id } = useParams();
  const Id = "66aa21ae162fae46903e3d13";
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetchSingleCategory(id || Id);
        setCategories(response?.category?.subCategories);
        setProducts(response?.category?.Product);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [id]);
  return (
    <div>
      <TVNavbar />
      <HeroSlider name="tv" />
      <SmartTv AllProducts={product} />
      <Choose />
      <Footer />
    </div>
  );
};

export default TVHome;
