import React, { useEffect } from "react";
import Footer from "./Footer";
import SoftwareNavbar from "./SoftwareNavbar";

const ReplacementPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <SoftwareNavbar />
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-10">
        <h1 className="text-3xl font-bold mb-4 text-center text-green-600">
          Replacement Policy
        </h1>

        <section className="mb-8">
          <p className="mb-4">
            At Provision Plus, we value your choices and strive to provide the
            best possible shopping experience. We understand that sometimes a
            product may not meet your expectations or require a replacement for
            various reasons.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Eligibility</h2>
          <p className="mb-2">
            Replacements are currently available only for select
            brands/products. For the most updated information about Replacement
            eligibility, kindly refer to the product display page. Products will
            be eligible for replacement only under following scenarios:
          </p>
          <ul className="list-disc list-inside mb-2">
            <li>Delivered product is defective or damaged.</li>
            <li>Wrong product delivered.</li>
            <li>
              If you have received a different size/colour than what was
              originally ordered.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Condition</h2>
          <ul className="list-disc list-inside mb-2">
            <li>
              Products eligible for replacement must meet the following
              criteria: they should be unused, in their original packaging, and
              accompanied by all original price tags, labels, barcodes, user
              manuals, warranty cards, invoices, accessories, freebies, and
              original boxes.
            </li>
            <li>
              Replacement is subject to be accepted or rejected basis quality
              checks either by our logistic partner at the time of pickup or by
              the Seller.
            </li>
            <li>
              If an eligible item is out of stock, it cannot be replaced. You
              can only return the product.
            </li>
            <li>
              In the unlikely event that we are unable to fulfil the
              replacement, we will promptly process the return/refund for your
              order.
            </li>
            <li>
              Product can be replaced only once and are limited to serviceable
              pin codes only.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Process</h2>
          <p className="mb-2">
            If your product qualifies and meets the requirements, you can easily
            request a replacement from the "My Orders" section. The replacement
            is subject to quality checks, which will be conducted either when
            the old product is picked up or by the seller. Once the quality
            check is completed, the new product will be sent to you.
          </p>
        </section>
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default ReplacementPolicy;
