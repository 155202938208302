import React, { useEffect } from "react";
import Footer from "./Footer";
import SoftwareNavbar from "./SoftwareNavbar";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <SoftwareNavbar />
      <div className="max-w-7xl mx-auto   p-5">
        <h1 className="text-2xl font-bold mb-4">Terms of Use</h1>
        <p>
          Welcome to the www.provisionplus.com website (the "Website"). This
          Website is provided solely to assist customers in gathering software &
          hardware information, determining the availability of related goods
          and services, making legitimate reservations or otherwise transacting
          business with software & hardware suppliers, and for no other
          purposes. The terms "we", "us", "our" and "Sonora Engineering" refer
          to Sonora Engineering OPC Private Limited, a company incorporated in
          Delhi, India. The term "you" refers to the customer visiting the
          Website and/or booking a reservation through us on this Website, or
          through our customer service agents.
        </p>
        <p>
          This Website is offered to you conditioned upon your acceptance
          without modification of all the terms, conditions, and notices set
          forth below (collectively, the "Agreement"). By accessing or using
          this Website in any manner, you agree to be bound by the Agreement.
          Please read the Agreement carefully. If you do not accept all of these
          terms and conditions, please do not use this Website.
        </p>
        <h2 className="text-xl font-semibold mt-4 mb-2">Use of the website</h2>
        <ul className="list-disc ml-6">
          <li>You are at least 18 years of age</li>
          <li>
            You possess the legal authority to create a binding legal obligation
          </li>
          <li>You will use this Website in accordance with this Agreement</li>
          <li>
            You will only use this Website to make legitimate purchases for you
            or for another person who is at least 18 years of age and for whom
            you are legally authorized to act
          </li>
          <li>
            All information supplied by you on this Website is true, accurate,
            current and complete
          </li>
          <li>You are a resident of India</li>
        </ul>
        <p>
          We retain the right at our sole discretion to deny access to anyone to
          this Website and the services we offer, at any time and for any
          reason, including, but not limited to, for violation of this
          Agreement.
        </p>
        <h2 className="text-xl font-semibold mt-4 mb-2">
          Prohibited activities
        </h2>
        <p>
          The content and information on this Website (including, but not
          limited to, price and availability of software & hardware services),
          as well as the infrastructure used to provide such content and
          information, is proprietary to us or our suppliers and providers.
          While you may make limited copies of your software & hardware
          itinerary (and related documents) for software & hardware or services
          booked through this Website, you agree not to otherwise modify, copy,
          distribute, transmit, display, perform, reproduce, publish, license,
          create derivative works from, transfer, or sell or re-sell any
          information, software & hardware, products, or services obtained from
          or through this Website. Additionally, you agree not to:
        </p>
        <ul className="list-disc ml-6">
          <li>Use this Website or its contents for any commercial purpose</li>
          <li>
            Make any speculative, false, or fraudulent reservation or any
            reservation in anticipation of demand
          </li>
          <li>
            Access, monitor or copy any content or information of this Website
            using any robot, spider, scraper or other automated means or any
            manual process for any purpose without our express written
            permission
          </li>
          <li>
            Violate the restrictions in any robot exclusion headers on this
            Website or bypass or circumvent other measures employed to prevent
            or limit access to this Website
          </li>
          <li>
            Take any action that imposes, or may impose, in our discretion, an
            unreasonable or disproportionately large load on our infrastructure
          </li>
          <li>
            Deep-link to any portion of this Website (including, without
            limitation, the purchase path for any software & hardware services)
            for any purpose without our express written permission
          </li>
          <li>
            "Frame", "mirror" or otherwise incorporate any part of this Website
            into any other website without our prior written authorization
          </li>
        </ul>
        <p>
          You understand that any violations of this clause may result in the
          cancellation of your reservation(s), in your being denied access to
          the applicable software & hardware product or services, in your
          forfeiting any monies paid for such reservation(s), and/or in our
          debiting your account for any costs we incur as a result of such
          violation.
        </p>
        <h2 className="text-xl font-semibold mt-4 mb-2">
          Suppliers, rules and restrictions
        </h2>
        <p>
          You acknowledge that Sonora Engineering pre-negotiates rates with
          suppliers to facilitate the purchase of products and services on your
          behalf. The rate displayed on the Website is a combination of the
          pre-negotiated rates for the products and services that you purchase,
          and a fee retained by Sonora Engineering to compensate us for our
          services.
        </p>
        <p>
          Separate terms and conditions will apply to your reservation and
          purchase of software & hardware-related goods and services that you
          select. Please read these separate terms and conditions carefully. You
          agree to abide by the terms and conditions of purchase imposed by any
          supplier with whom you elect to deal, including, but not limited to,
          payment of all amounts when due and compliance with the supplier's
          rules and restrictions regarding availability and use of fares,
          products, or services. You acknowledge that some third-party providers
          offering certain services and/or activities may require you to sign
          their liability waiver prior to participating in the service and/or
          activity they offer. You understand that any violation of any such
          supplier's rules and restrictions may result in cancellation of your
          reservation(s), in your being denied access to the applicable software
          & hardware product or services, in your forfeiting any monies paid for
          such reservation(s), and/or in our debiting your account for any costs
          we incur as a result of such violation.
        </p>
        <p>
          You agree to inform Sonora Engineering of any circumstance or
          condition as a result of which you or any person on whose behalf you
          are making a reservation may require special facilities prior to your
          purchase. Any complaints regarding a supplier should be brought to the
          attention of the supplier immediately during the time of the service
          and the same should be communicated to us in writing within thirty
          days thereafter, failing which, Sonora Engineering will not entertain
          any such complaints.
        </p>
        <h2 className="text-xl font-semibold mt-4 mb-2">Payment policy</h2>
        <p>
          We accept online payments in Indian Rupees (Rs) on this Website. You
          warrant that you are the cardholder for all online payments made using
          credit/debit cards, and that your identification (“Cardholder ID”)
          exactly matches that of the credit/debit card used to make your
          purchase. You further agree to promptly provide proof of such matching
          Cardholder ID upon request at any time within six months of your
          purchase. You understand that any violation of this policy may result
          in cancellation of your reservation(s), in your being denied access to
          the applicable software & hardware products or services, in your
          forfeiting any monies paid for such reservation(s), and/or in our
          debiting your account for any costs we incur as a result of such
          violation.
        </p>
        <h2 className="text-xl font-semibold mt-4 mb-2">
          Change and cancellation policy
        </h2>
        <p>
          All requests for change or cancellation of any goods and services
          purchased on this website must be delivered in writing to the service
          help address provided at the end of these terms or by email to
          sonoraengineering@gmail.com and must be received in both cases, on our
          working days at least 24 hours before these goods and services are
          scheduled to be provided. Our offices are open ("working days") from
          9:00am to 7:00pm India local time on each day from Monday to Sunday,
          except on public holidays and holidays declared for private sector
          establishments in India.
        </p>
        <p>
          You agree to abide by the terms and conditions imposed at the time of
          your purchase, which will determine the change and cancellation policy
          applicable to your purchase, including, but not limited to penalties
          which may apply. Additionally, you agree that:
        </p>
        <ul className="list-disc ml-6">
          <li>
            If a change or cancellation policy relevant to your request is not
            provided in the terms and conditions imposed at the time of your
            purchase, your purchase can not be changed and is non-refundable in
            the event of a cancellation.
          </li>
          <li>
            If a cancellation policy relevant to your request in the terms and
            conditions imposed at the time of your purchase does not explicitly
            state a refundable amount or how it may be calculated, your purchase
            is non-refundable in the event of a cancellation.
          </li>
          <li>
            If the change or cancellation policies relevant to your request in
            the terms and conditions imposed at the time of your purchase do not
            specify the price of partly utilized services, they will be
            calculated based on the price of the utilized services alone at the
            time of your request and not pro-rata.
          </li>
        </ul>
        <p>
          Requests for permitted changes and cancellations will be processed
          within 2 working days after all payments required to process the
          request are settled. In the event of a cancellation, any payments to
          be refunded after the deduction of any penalties and other costs
          incurred by us to facilitate your request, will be refunded to you via
          the same method of payment which you used to make the original
          purchase, within 7 days after processing your request. In the event
          that a purchase cannot be changed and/or is non-refundable, as
          determined by the terms and conditions imposed at the time of your
          purchase, any payments made by you in respect of such purchase will be
          forfeited.
        </p>
        <h2 className="text-xl font-semibold mt-4 mb-2">
          Liability disclaimer
        </h2>
        <p>
          The information, software & hardware, products, and services published
          on this website may include inaccuracies or typographical errors. In
          particular, Sonora Engineering and its affiliates do not guarantee the
          accuracy of, and disclaim liability for inaccuracies relating to the
          information and description of the software & hardware, hotel, air,
          cruise, car and other products and services displayed on this website
          (including, without limitation, photographs, list of hotel amenities,
          general product descriptions, etc.), much of which information is
          provided by the respective suppliers. Any ratings displayed on this
          website are intended as only general guidelines, and Sonora
          Engineering and its affiliates do not guarantee the accuracy of the
          ratings. Changes are periodically added to the information herein.
          Sonora Engineering, its affiliates, and/or their respective suppliers
          may make improvements and/or changes on this website at any time.
        </p>
        <p>
          Sonora Engineering, its affiliates, and/or their respective suppliers
          make no representations about the suitability of the information,
          software & hardware, products, and services contained on this website
          for any purpose, and the inclusion or offering for sale of any
          products or services on this website does not constitute any
          endorsement or recommendation of such products or services by Sonora
          Engineering or its affiliates. All such information, software &
          hardware, products, and services are provided "as is" without warranty
          of any kind. Sonora Engineering, its affiliates, and/or their
          respective suppliers hereby disclaim all warranties and conditions
          with regard to this information, software & hardware, products, and
          services, including all implied warranties and conditions of
          merchantability, fitness for a particular purpose, title, and
          non-infringement.
        </p>
        <p>
          In no event shall Sonora Engineering, its affiliates, and/or their
          respective suppliers be liable for any direct, indirect, punitive,
          incidental, special, or consequential damages arising out of, or in
          any way connected with, the use of this website or with the delay or
          inability to use this website, or for any information, software &
          hardware, products, and services obtained through this website, or
          otherwise arising out of the use of this website, whether based on
          contract, tort, strict liability, or otherwise, even if Sonora
          Engineering, its affiliates, and/or their respective suppliers have
          been advised of the possibility of damages.
        </p>
        <h2 className="text-xl font-semibold mt-4 mb-2">Indemnification</h2>
        <p>
          You agree to defend and indemnify Sonora Engineering, its affiliates,
          and/or their respective suppliers and any of their officers,
          directors, employees, and agents from and against any claims, causes
          of action, demands, recoveries, losses, damages, fines, penalties, or
          other costs or expenses of any kind or nature including but not
          limited to reasonable legal and accounting fees, brought by third
          parties as a result of:
        </p>
        <ul className="list-disc ml-6">
          <li>
            Your breach of this Agreement or the documents referenced herein
          </li>
          <li>Your violation of any law or the rights of a third party</li>
          <li>Your use of this Website</li>
        </ul>
        <h2 className="text-xl font-semibold mt-4 mb-2">
          Links to third-party sites
        </h2>
        <p>
          This Website may contain hyperlinks to websites operated by parties
          other than Sonora Engineering. Such hyperlinks are provided for your
          reference only. We do not control such websites and are not
          responsible for their contents or your use of them. Our inclusion of
          hyperlinks to such websites does not imply any endorsement of the
          material on such websites or any association with their operators.
        </p>
        <h2 className="text-xl font-semibold mt-4 mb-2">
          Software available on this website
        </h2>
        <p>
          Any software & hardware that is made available to download from this
          Website ("Software") is the copyrighted work of Sonora Engineering,
          its affiliates, and/or their respective suppliers. Your use of the
          Software is governed by the terms of the end-user license agreement,
          if any, which accompanies or is included with the Software ("License
          Agreement"). You may not install or use any Software that is
          accompanied by or includes a License Agreement unless you first agree
          to the terms of the License Agreement. For any Software not
          accompanied by a License Agreement, we hereby grant to you, the user,
          a personal, nontransferable license to use the Software for viewing
          and otherwise using this Website in accordance with these terms and
          conditions and for no other purpose. You acknowledge that the Software
          and any accompanying documentation and/or technical information are
          subject to applicable export control laws and regulations of India.
          You agree not to export or re-export the Software, directly or
          indirectly, to any countries that are subject to Indian export
          restrictions.
        </p>
        <h2 className="text-xl font-semibold mt-4 mb-2">Miscellaneous</h2>
        <p>
          This Agreement (and any other terms and conditions referenced herein)
          constitutes the entire agreement between you and Sonora Engineering
          with respect to this Website, and it supersedes all prior or
          contemporaneous communications and proposals, whether electronic,
          oral, or written, between the customer and Sonora Engineering with
          respect to this Website. A printed version of this Agreement and of
          any notice given in electronic form shall be admissible in judicial or
          administrative proceedings based upon or relating to this Agreement to
          the same extent and subject to the same conditions as other business
          documents and records originally generated and maintained in printed
          form.
        </p>
        <p>
          Fictitious names of companies, products, people, characters, and/or
          data mentioned on this Website are not intended to represent any real
          individual, company, product, or event.
        </p>
        <p>Any rights not expressly granted herein are reserved.</p>
        <h2 className="text-xl font-semibold mt-4 mb-2">
          Modification of these terms and conditions
        </h2>
        <p>
          We reserve the right to change the terms, conditions, and notices
          under which this Website is offered, including but not limited to the
          charges associated with the use of this Website. You are responsible
          for regularly reviewing these terms and conditions.
        </p>
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default Terms;
