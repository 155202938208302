import React from 'react'
import GPSNavbar from './GPSNavbar'
import Slider from '../../../components/old/Slider'
import RenderData from './RenderData'
import { Link } from 'react-router-dom'
import Footer from "../../../components/common/Footer"
function HomeGPS() {
  return (
    <div>

        <GPSNavbar />
        <Slider name={"gps"} />

        <div className=' bg-achha'>
       
      
<div className='  top-0 text-2xl text-white font-semibold p-4'>
<div className=' flex justify-between items-center w-[90vw] flex-wrap'>
<p>Join The GPS Systems India and Give your Business brand !Features</p>

<Link to={"/login"} className=' bg-[#FED527] p-2 px-4 rounded-sm text-black font-normal '> Login</Link>
</div>
</div>

        </div>
        <RenderData />
        <Footer />


    </div>
  )
}

export default HomeGPS