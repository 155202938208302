import { setToken, setUser } from "../../redux/authSlice";
import { resetCart } from "../../redux/cartSlice";

import { apiConnector } from "../apiConnector";
import { userEndpoints } from "../apis";
import { toast } from "react-toastify";
import Swal from "sweetalert2";


const {
  LOGIN_API,
  SIGNUP_API,
 //Order
 GET_ALL_ORDER,
 UPDATE_ORDER
} = userEndpoints;


export async function signUp(formData, navigate, dispatch) {

    Swal.fire({
      title: "Loading",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await apiConnector("POST", SIGNUP_API, formData);
  
      console.log("SIGNUP API RESPONSE............", response);
  
      if (!response.data.success) {
        // toast.error(response.data.message)
        throw new Error(response.data.message);
      }
  
      console.log(response?.data)
     
      // navigate("/login");
  
    } catch (error) {
      console.log("SIGNUP API ERROR............", error);
      // navigate("/login");
    }
    Swal.close();
  
  }
  
  export async function login(email, password, navigate, dispatch) {
    Swal.fire({
      title: "Loading",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    try {
      const response = await apiConnector("POST", LOGIN_API, {
        email,
        password,
      });
      Swal.close();
      if (!response?.data?.success) {
        await
          Swal.fire({
            title: "Login Failed",
            text: response.data.message,
            icon: "error",
          });
        throw new Error(response.data.message);
      }
  
      Swal.fire({
        title: `Login Successfully!`,
        text: `Have a nice day!`,
        icon: "success",
      });
      dispatch(setToken(response?.data?.token));
      dispatch(setUser(response.data.user));
      // navigate("/admin/dashboard");
    } catch (error) {
      console.log("LOGIN API ERROR............", error);
      Swal.fire({
        title: "Login Failed",
        text:
          error.response?.data?.message ||
          "Something went wrong, please try again later",
        icon: "error",
      });
    }
  }

  // order

  export const getAllOrders  = async (token) => {
    ;
   try {
     const response = await apiConnector("POST", GET_ALL_ORDER,null,{
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });
     if (!response?.data?.success) {
       throw new Error("Could Not Fetch Product");
     }
   
     const result = response?.data?.data;
    
     return result;
   } catch (error) {
     console.log("GET_ALL_PRODUCT_API API ERROR:", error);
     toast.error(error.message);
      ;
     return [];
   }
 };
 

 export const updateOrder = async (data, token) => {

  const toastId = toast.loading("Loading...")
  try {
    const response = await apiConnector("POST", UPDATE_ORDER, data, {
      Authorization: `Bearer ${token}`,
    })
    // console.log("UPDATE  API RESPONSE............", response)
    if (!response?.data?.success) {
      throw new Error("Could Not update ")
    }
    toast.success("Order    update")

  } catch (error) {
    console.log("UPDATE  API ERROR............", error)
    toast.error(error.message)
  }
  toast.dismiss(toastId)

}


export function logout(navigate) {
  return (dispatch) => {
    dispatch(setToken(null))
    dispatch(setUser(null))
    dispatch(resetCart(null))

    localStorage.removeItem("token")
    localStorage.removeItem("cart")
    localStorage.removeItem("user")
    toast.success("Logged Out")
    navigate("/")

  }
}
