import React, { useEffect, useState } from "react";
import { Slide } from "react-awesome-reveal";
import Footer from "../../components/common/Footer";
import SoftwareNavbar from "../../components/common/SoftwareNavbar";
import { services, seo, smo, webdesign, webdev } from "../../data";
import { useParams } from "react-router-dom";
import Client from "../../components/software/Client";
import WhyUs from "../../components/software/WhyUs";
import { Link } from "react-router-dom";
const ServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { name } = useParams();
  const [mainData, setMainData] = useState({});
  const [hero, setHero] = useState([]);
  const [service, setService] = useState([]);
  const [seoPackage, setSeoPackage] = useState([]);
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    if (name === "seo") {
      setMainData(seo);
      setHero(seo?.hero);
      setService(seo?.service);
      setSeoPackage(seo?.seoPackage);
    } else if (name === "services") {
      setMainData(services);
      setHero(services?.hero);
      setService(services?.service);
    } else if (name === "smo") {
      setMainData(smo);
      setHero(smo?.hero);
      setFaq(smo?.faq);
    } else if (name === "webdesign") {
      setMainData(webdesign);
      setHero(webdesign?.hero);
      setSeoPackage(seo?.seoPackage);
    } else if (name === "webdev") {
      setMainData(webdev);
      setHero(webdev?.hero);
      setSeoPackage(seo?.seoPackage);
    }
  }, [name]);

  const [heroData] = hero;

  return (
    <div>
      <SoftwareNavbar />
      {/* Hero Section */}
      <div className="bg-yellow-400 py-10">
        <div className="main max-w-7xl mx-auto justify-center grid lg:grid-cols-2 px-2 text-center lg:text-start">
          <Slide direction="left">
            <div className="first">
              <p className="lg:text-4xl mt-5 text-2xl font-semibold px-1 lg:px-0 text-center lg:text-start">
                {heroData?.title}
              </p>
              <p className="text-sm my-4 leading-loose">
                {heroData?.subtitle1}
              </p>
              <p className="text-sm my-4 leading-loose">
                {heroData?.subtitle2}
              </p>
              <p className="text-sm my-4 leading-loose">
                {heroData?.subtitle3}
              </p>
              <p className="text-sm my-4 leading-loose">
                {heroData?.subtitle4}
              </p>
              <div className="flex gap-3 justify-center lg:justify-start">
                <Link
                  to="/software/quote"
                  className="lg:text-xl text:sm my-8 px-4 py-2 rounded-md bg-black text-white hover:bg-red-600 hover:text-black"
                >
                  {heroData?.buttonText1}
                </Link>
                <Link
                  to={heroData?.path}
                  className="lg:text-xl text-sm my-8 px-4 py-2 rounded-md bg-red-500 text-white hover:bg-red-600 hover:text-black"
                >
                  {heroData?.buttonText2}
                </Link>
              </div>
            </div>
          </Slide>

          <Slide direction="right">
            <div className="second w-[72%] m-auto">
              <img src={heroData?.img} alt="Hero" />
            </div>
          </Slide>
        </div>
      </div>{" "}
      <Client />'{name !== "smo" && <WhyUs />}
      {/* Services Section */}
      {name === "seo" ||
        (name === "services" && (
          <div className="my-16 bg-gray-200">
            <br />
            <br />
            <div>
              <p className="lg:text-3xl text-xl font-bold text-center">
                Our <span className="text-yellow-400"> Digital Marketing</span>{" "}
                Services in India
              </p>

              <div className="main grid lg:grid-cols-4 gap-5 max-w-7xl mx-auto px-5  mt-8">
                {service.map((currElem, index) => (
                  <div className="card bg-white shadow-md py-1" key={index}>
                    <div className="h-[40%] w-full flex justify-center">
                      <img src={currElem?.img} alt="not found" />
                    </div>
                    <p className="text-xl text-center font-bold p-2 mt-16">
                      {currElem?.title}
                    </p>
                    <p className="text-sm text-center h-[20%] p-2">
                      {currElem?.desc}
                    </p>
                    <br />
                    <br />
                    <button className="px-5 py-2 bg-yellow-400 lg:text-xl text-black font-bold rounded-md mb-10 mx-auto flex">
                      {currElem?.btn}
                    </button>
                    <br />
                  </div>
                ))}
              </div>
              {name === "seo" ||
                (name === "services" && (
                  <button className="px-5 py-2 bg-red-600 lg:text-xl text-white rounded-full mt-16 mx-auto flex">
                    Our Service
                  </button>
                ))}

              <br />
            </div>
          </div>
        ))}
      {name === "smo" ? (
        <div className="bg-black p-10 text-white my-10">
          {faq.map((currElem, index) => (
            <div className="max-w-7xl mx-auto">
              <div
                className="text-center lg:text-3xl text-xl font-bold"
                key={index}
              >
                {currElem.title}
              </div>
              <p className="text-center leading-loose text-[16px] mt-5">
                {currElem.desc}
              </p>
            </div>
          ))}
        </div>
      ) : null}
      {name === "seo" ||
      name === "smo" ||
      name === "webdesign" ||
      name === "webdev"
        ? seoPackage.length > 0 && (
            <div className="my-16">
              <div>
                <p className="lg:text-3xl text-xl font-bold text-center">
                  Our <span className="text-yellow-400"> SEO Packages</span>{" "}
                  Pricing Plans
                </p>

                <div className="main grid lg:grid-cols-3 gap-5 max-w-7xl mx-auto px-3 mt-8">
                  {seoPackage.map((pkg, index) => (
                    <div className="card bg-white shadow-md p-5 " key={index}>
                      <div className="w-16 h-16 rounded-full m-auto flex justify-center items-center bg-yellow-400">
                        {pkg.icon}
                      </div>
                      <p className="text-xl text-center font-bold p-2 mt-3">
                        {pkg.title}
                      </p>
                      <p className="text-sm text-center h-[20%] p-2">
                        {pkg.desc}
                      </p>
                      <br />
                      <Link
                        to="/software/packages"
                        className="px-5 py-2 bg-yellow-400 w-fit lg:text-xl text-black font-bold rounded-md mb-10 mx-auto flex"
                      >
                        {pkg.btn}
                      </Link>
                    </div>
                  ))}
                </div>

                <Link
                  to="/software/packages"
                  className="px-5 py-2 w-fit bg-red-600 lg:text-xl text-white rounded-full mt-16 mx-auto flex"
                >
                  View All Packages
                </Link>
                <br />
              </div>
            </div>
          )
        : null}
      <Footer />
    </div>
  );
};

export default ServicePage;
