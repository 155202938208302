import React, { useState } from "react";
import ReactDOM from "react-dom";
import { userEndpoints } from "../../services/apis";
import { apiConnector } from "../../services/apiConnector";
import Swal from "sweetalert2";

const FreeSeoModel = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    keyword: "",
    websiteUrl: ""
  });

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Loading",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const res = await apiConnector(
        "POST",
        userEndpoints.SEO_US_API,
        formData
      )
      // console.log("Email Res - ", res)
      Swal.fire({
        title: `SEO Audit Request Send Successfully!`,
        text: `Have a nice day!`,
        icon: "success",
      });
      onClose()
    } catch (error) {
      console.log("ERROR MESSAGE - ", error)
      Swal.fire({
        title: "Request  Failed",
        text:
          error.response?.data?.message ||
          "Something went wrong, please try again later",
        icon: "error",
      });
    }
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded shadow-lg max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 className="text-2xl font-bold mb-4">Request a FREE SEO Audit</h2>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              className="w-full px-3 py-2 border rounded"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              className="w-full px-3 py-2 border rounded"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              className="w-full px-3 py-2 border rounded"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Keyword</label>
            <input
              type="text"
              name="keyword"
              className="w-full px-3 py-2 border rounded"
              value={formData.keyword}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Website URL</label>
            <input
              type="text"
              name="websiteUrl"
              className="w-full px-3 py-2 border rounded"
              value={formData.websiteUrl}
              onChange={handleChange}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-green-500 text-black py-2 px-4 rounded hover:bg-green-600"
          >
            Submit
          </button>
        </form>
      </div>
    </div>,
    document.body
  );
};

export default FreeSeoModel;
