import React, { useEffect, useState } from 'react'
import { fetchCategory } from '../../services/operation/product';
import Slider from '../../components/core/home/Category';
import HeroSlider from "../../components/old/Slider"
import HardwareNavbar from '../../components/common/HardwareNavbar';
import AllProducts from '../../components/core/home/AllProducts';
import Footer from "../../components/common/Footer"
import Hot from '../../components/core/home/Hot';
import { useSelector } from 'react-redux';
import New from '../../components/core/home/New';
import SingleCategory from './SingleCategory';

function HardwareHome() {
  const { allProducts } = useSelector((state) => state.news);
 
  return (
    <div>
        <HardwareNavbar />




        <HeroSlider name="tv" />
        
        <br />
        <SingleCategory Id={"66aa21ae162fae46903e3d13"} />
        <AllProducts />
        <Footer />
    </div>
  )
}

export default HardwareHome