

export const banking ={
    heroImg : "https://www.sparshsecuritech.com/assets/images/sparshsecuritech/header/banking.webp",
    headingOne :"Surveillance is a critical tool for law enforcement agencies to prevent and investigate crime, ensure public safety, and gather evidence for legal proceedings. Law enforcement agencies have specific surveillance requirements to effectively carry out their duties and responsibilities. Here are some key surveillance requirements in a law enforcement agency",

    headings:[
        {
            image:"https://www.sparshsecuritech.com/assets/images/sparshsecuritech/Bank-1.png",
            heading:"Smart Solution for Branch Security",
            para:"Branch offices face a variety of security challenges as criminals develop clever ways to bypass the traditional security measures. There are many areas to cover in a branch office, from overviews of the waiting areas and ATM vestibule, via images over the tellers, recycling area and vault, to close-up face images at the entrance and exit.",
        },
        {
            image:"https://www.sparshsecuritech.com/assets/images/sparshsecuritech/Bank-2.png",
            heading:"Smart Solution for ATM Security",
            para:"ATM solution from SPARSH enable you the Benefits of: • Prevent hold ups and violent confrontations • Deter ATM skimming • Digital storage – IP-based security cameras enable the user to store and back-up footage digitally on hard drives or servers. • Avoid Criminal investigation",
        },
        {
            image:"https://www.sparshsecuritech.com/assets/images/sparshsecuritech/Bank-3.png",
            heading:"Securing Cash Vans",
            para:"Cash Van Solution from SPARSH enables you: • Live Monitoring of vehicle on remote location • Live tracking the vehicles on Google map • Check the vehicle’s driving route history • Create Geo-fence, alerts when the vehicle enter/leave the given area • Monitor the vehicle’s fuel level & temperature in real time • Integrated with VoIP technology.",
        },
    ]
}


export const education ={
    heroImg : "https://www.sparshsecuritech.com/assets/images/sparshsecuritech/header/education.webp",
    headingOne :"The essential element of video surveillance system implementation is pre-analysis of potential threats to the facility. Solutions adopted from other similar places might not be effective in other facility. Different institutions can be exposed to different dangers with varying intensity and specification, thus the video surveillance system must take into account such diversity.",

    headings:[
        {
            image:"https://www.sparshsecuritech.com/assets/images/sparshsecuritech/Education1.png",
            heading:"Smart Solution",
            para:"Intelligent monitoring & announcement solution from SPARSH enables you to stay focused on your core business – Education. SPARSH has wide range of cameras which are suitable for all educational environments, including: • Hallways and corridors • Class rooms, computer rooms, etc. • Canteens, libraries and sports facilities • Entrances and exits • Playground, Campus Areas • Boundaries",
        },
        {
            image:"https://www.sparshsecuritech.com/assets/images/sparshsecuritech/Education2.png",
            heading:"EDUCATION",
            para:"The essential element of video surveillance system implementation is pre-analysis of potential threats to the facility. Solutions adopted from other similar places might not be effective in other facility. Different institutions can be exposed to different dangers with varying intensity and specification, thus the video surveillance system must take into account such diversity. Intelligent monitoring & announcement solution from SPARSH enables you to stay focused on your core business – Education. SPARSH has wide range of cameras which are suitable for all educational environments, including: • Hallways and corridors • Class rooms, computer rooms, etc. • Canteens, libraries and sports facilities • Entrances and exits • Playground, Campus Areas • Boundaries",
        },
        {
            image:"https://www.sparshsecuritech.com/assets/images/sparshsecuritech/Education%203.png",
            heading:"Campus Safety and Security and Behavior Monitoring :",
            para:"Preventing Incidents: Surveillance cameras act as a deterrent to unwanted behavior, vandalism, and unauthorized access. Rapid Response: Real-time monitoring allows for swift action during security incidents, ensuring the safety of students and staff. Promoting Positive Behavior: Knowing they are being observed encourages students and staff to adhere to behavioral standards. Addressing Issues: Surveillance provides evidence in case of disputes or allegations, helping resolve disciplinary matters objectively. Asset Protection and Emergency Preparedness: Securing Valuables: Surveillance safeguards valuable assets such as computers, AV equipment, and school vehicles from theft or damage. Enhanced Response: Surveillance cameras offer crucial information to first responders during emergencies, allowing for faster and more informed interventions. Visitor Management: Controlled Access: Integrated surveillance systems can support visitor management protocols, ensuring only authorized individuals enter school premises.",
        },
    ]
}



export const smart = {
    heroImg: "https://www.sparshsecuritech.com/assets/images/sparshsecuritech/header/smart_city.webp",
    headingOne: "Surveillance is a crucial component of a smart city's infrastructure, as it plays a central role in ensuring public safety, efficient city management, and enhanced quality of life for residents. Smart city surveillance requirements are diverse and can vary depending on the city's unique needs and priorities.",
    headings: [
        {
            image: "https://www.sparshsecuritech.com/assets/images/sparshsecuritech/Smart%20City/public%20safety.png",
            heading: "Public Safety and Security",
            para: "Surveillance cameras are strategically placed in public spaces, streets, and neighborhoods to monitor and deter criminal activities, ensuring the safety of residents and visitors. Smart city surveillance can be integrated with citizen engagement platforms, allowing residents to report issues and provide feedback on city services."
        },
        {
            image: "https://www.sparshsecuritech.com/assets/images/sparshsecuritech/Smart%20City/infrastructure%20monitoring.png",
            heading: "Infrastructure Monitoring",
            para: "Surveillance systems monitor critical infrastructure, such as bridges, roads, and buildings, for potential maintenance needs and early detection of issues. Smart parking systems use surveillance to detect available parking spaces and streamline parking operations in the city."
        },
        {
            image: "https://www.sparshsecuritech.com/assets/images/sparshsecuritech/Smart%20City/data%20analytics.png",
            heading: "Data Analytics and Insights",
            para: "Surveillance data can be analyzed to gain valuable insights for urban planning, resource allocation, and enhancing city services."
        }
                
        
    ]
};

export const retail = {
    heroImg: "https://www.sparshsecuritech.com/assets/images/sparshsecuritech/header/retail.webp",
    headingOne: "Security surveillance in the retail sector offers a wide range of benefits, contributing to the overall safety, profitability, and efficiency of the business.",
    headings: [
        {
            image: "https://www.sparshsecuritech.com/assets/images/sparshsecuritech/Retail/inventory%20management.png",
            heading: "Inventory Management and Remote Monitoring",
            para: "Surveillance cameras can help with inventory management by tracking stock levels and monitoring any unauthorized access to storage areas, preventing theft or inventory shrinkage. Our surveillance systems allow remote monitoring, enabling retailers to keep an eye on multiple locations from a central control center or even through mobile devices, ensuring immediate response to any incidents."
        },
        {
            image: "https://www.sparshsecuritech.com/assets/images/sparshsecuritech/Retail/customer%20insight.png",
            heading: "Customer Insights & Operational Efficiency",
            para: "Surveillance systems can provide valuable insights into customer behavior, including traffic patterns, dwell times, and purchasing habits. This information can be used for marketing and sales strategy improvements. Retailers can use surveillance to monitor store layouts and customer behavior, enabling them to make data-driven decisions about product placement, store design, and staffing levels to optimize efficiency."
        },
        {
            image: "https://www.sparshsecuritech.com/assets/images/sparshsecuritech/Retail/compliance.png",
            heading: "Compliance and Liability Protection & Cost Savings",
            para: "Surveillance can help retailers comply with safety regulations and protect against false liability claims by providing accurate records of incidents. While there is an initial investment in surveillance equipment, the long-term cost savings from reduced theft, improved efficiency, and better inventory management can be substantial."
        }
                
        
    ]
};
