import s1 from "./assets/software/service/s1.jpg";
import s6 from "./assets/software/service/s6.jpg";
import s8 from "./assets/software/service/s8.png";
import s9 from "./assets/software/service/s9.jpg";
import hero from "./assets/software/service/servicehero.png";
import heroSmo from "./assets/software/service/smo.png";
import seoHero from "./assets/software/hero.png";
import heroWeb from "./assets/software/service/webdesign.png";
import heroWebdev from "./assets/software/service/webdev.png";
import { CiShop } from "react-icons/ci";
import { IoIosBusiness } from "react-icons/io";
import { FaShoppingCart } from "react-icons/fa";
import i1 from "./assets/software/industry/i1.png"
import i2 from "./assets/software/industry/i2.png"
import i3 from "./assets/software/industry/i3.png"
import i4 from "./assets/software/industry/i4.png"


export const services = {
    hero: [
        {
            img: hero,
            title: "Top Rated Digital Marketing Services",
            subtitle1: "Best digital marketing company in Lucknow- Provision Plus offers top-rated digital marketing services for businesses in Lucknow at very low prices.",
            subtitle2: "Want to increase conversions, drive traffic and upsurge sales? Provision Plus believes that Digital Marketing is an essential need of businesses and we strive to offer the most effective and suitable digital marketing services to our potential customers.",
            subtitle3: "Are you searching for the best digital marketing services in India?",
            subtitle4: "Contact us to get a FREE Quote!",
            buttonText1: "Request Proposal",
            buttonText2: "Our SEO Packages",
            path:"/software/seoPackage"
        },
    ],
    service: [
        {
            img: s1,
            title: "SEO Services",
            desc: "By utilizing white hat practices, using the best SEO tools, and performing in-depth keyword research, we can help steal initial positions for your website in SERPs.",
            btn: "SEO Services",
        },
        {
            img: s6,
            title: "Website Development",
            desc: "Operational as a Web Design & Development Company in India, we create websites that are easier to navigate and give better user experience to the viewers.",
            btn: "Website Development",
        },
        {
            img: s8,
            title: "SMO Services",
            desc: "SMO is the process of improving the visibility and reach of a website on social media platforms. We engage people on social media to get new followers and engage with existing ones.",
            btn: "SMO Services",
        },
        {
            img: s9,
            title: "Website Designing",
            desc: "Web design services help you to make a perfectly designed website for your business to increase UI/UX. Hire us for Web Design Services at very affordable prices. Request a FREE Quote now!",
            btn: "Website Designing",
        },
    ],

};


export const seo = {
    hero: [
        {
            img: seoHero,
            title: "Best SEO Company in India With Top Rated SEO Experts",
            subtitle1: "Looking For SEO Services In India? Provision Plus Is the Best SEO Company In India, And We Have Ample Reasons To Prove The Credibility Of Our SEO Services In India. We have Top Rated SEO Experts with more than 10 years of extensive experience working on national and international SEO campaigns. Contact our Professional SEO agency to get your website audited. Call us today to get a FREE SEO Proposal.",
            buttonText1: "Request Proposal",
            buttonText2: " SEO Packages",
            path:"/software/seoPackage"
        },
    ],
    service: [
        {
            img: s1,
            title: "SEO Services",
            desc: "By utilizing white hat practices, using the best SEO tools, and performing in-depth keyword research, we can help steal initial positions for your website in SERPs.",
            btn: "SEO Services",
        },
        {
            img: s6,
            title: "Website Development",
            desc: "Operational as a Web Design & Development Company in India, we create websites that are easier to navigate and give better user experience to the viewers.",
            btn: "Website Development",
        },
        {
            img: s8,
            title: "SMO Services",
            desc: "SMO is the process of improving the visibility and reach of a website on social media platforms. We engage people on social media to get new followers and engage with existing ones.",
            btn: "SMO Services",
        },
        {
            img: s9,
            title: "Website Designing",
            desc: "Web design services help you to make a perfectly designed website for your business to increase UI/UX. Hire us for Web Design Services at very affordable prices. Request a FREE Quote now!",
            btn: "Website Designing",
        },
    ],
    seoPackage: [
        {
            icon: <CiShop size={30} />,
            title: 'Small Business',
            desc: "20,000 INR / 250 USD Monthly Best for Small Business",
            btn: 'Basic Plan'
        },
        {
            icon: <IoIosBusiness size={30} />,
            title: 'Midsize Business',
            desc: "30,000 INR / 400 USD Monthly Best for Mid Size Business",
            btn: 'Advanced Plan'
        },
        {
            icon: <FaShoppingCart size={30} />,
            title: 'eCommerce Business',
            desc: "45,000 INR / 600 USD Monthly Best for eCommerce Business",
            btn: 'Ecommerce Plan'
        },

    ]
};
export const smo = {
    hero: [
        {
            img: heroSmo,
            title: "Social Media Optimization Company in India at your Service",
            subtitle1: "SMO Services in India- Provision Plus is one of the most experienced social media optimization companies in India that works to promote your business and brand on the best social media platforms and direct a majority of web traffic to your website. We have social media optimization experts in our team who have helped more than hundreds of businesses to get brand visibility and leads from social media channels.",
            subtitle2: "We offer affordable social media optimization services in India and offer low cost social media optimization packages so every business can get the fruitful results.",
            buttonText1: "Request Proposal",
            buttonText2: " SMO Packages",
            path:"/software/smoPackage"

        },
    ],
    faq: [
        {
            title: "What is Social Media Optimization?",
            desc: "Social media optimization is the process of improving the visibility and reach of a website or web page on social media platforms. This can be done by optimizing the website or web page content, as well as the social media platform profiles and pages. Additionally, social media optimization includes using social media tools and techniques to improve the visibility and reach of a website or web page.",
        },

    ],
    seoPackage: [
        {
            icon: <CiShop size={30} />,
            title: 'Small Business',
            desc: "20,000 INR / 250 USD Monthly Best for Small Business",
            btn: 'Basic SEO Plan'
        },
        {
            icon: <IoIosBusiness size={30} />,
            title: 'Midsize Business',
            desc: "30,000 INR / 400 USD Monthly Best for Mid Size Business",
            btn: 'Advanced SEO Plan'
        },
        {
            icon: <FaShoppingCart size={30} />,
            title: 'eCommerce Business',
            desc: "45,000 INR / 600 USD Monthly Best for eCommerce Business",
            btn: 'Ecommerce SEO Plan'
        },

    ]
};


export const webdesign = {
    hero: [
        {
            img: heroWeb,
            title: "Web Design Company In India That Creates Modern Websites ONLY!",
            subtitle1: "Is Your Website Design Not Converting Visitors Into Customers? We Have Solution!",
            subtitle2: "Website fails to convert? Looking for best web design company in India? Website is your online identity and it should be very attractive and impressive. Hire Provision Plus- A leading web design company for best quality website designing services in India.",
            buttonText1: "Hire Now",
            buttonText2: " Our Packages",
            path:"/software/webdesign"

        },
    ],

    seoPackage: [
        {
            icon: <CiShop size={30} />,
            title: 'Small Business',
            desc: "20,000 INR / 250 USD Monthly Best for Small Business",
            btn: 'Basic SEO Plan'
        },
        {
            icon: <IoIosBusiness size={30} />,
            title: 'Midsize Business',
            desc: "30,000 INR / 400 USD Monthly Best for Mid Size Business",
            btn: 'Advanced SEO Plan'
        },
        {
            icon: <FaShoppingCart size={30} />,
            title: 'eCommerce Business',
            desc: "45,000 INR / 600 USD Monthly Best for eCommerce Business",
            btn: 'Ecommerce SEO Plan'
        },

    ]
};
export const webdev = {
    hero: [
        {
            img: heroWebdev,
            title: "Modern Website Development Services At Low Prices",
            subtitle1: "Searching For Best Website Development Services In India To Create A Modern Website For Your Business Or Startup? Take Quick Action And Hire Provision Plus- A Leading Website Development Company In India For The Best Website Development Services At Affordable Prices.",
            subtitle2: "Website Development Company In India Trusted by 500+ Clients",
            buttonText1: "FREE Quote!",
            buttonText2: " Our Packages",
            path:"/software/website-development-packages"

        },
    ],

    seoPackage: [
        {
            icon: <CiShop size={30} />,
            title: 'Small Business',
            desc: "20,000 INR / 250 USD Monthly Best for Small Business",
            btn: 'Basic SEO Plan'
        },
        {
            icon: <IoIosBusiness size={30} />,
            title: 'Midsize Business',
            desc: "30,000 INR / 400 USD Monthly Best for Mid Size Business",
            btn: 'Advanced SEO Plan'
        },
        {
            icon: <FaShoppingCart size={30} />,
            title: 'eCommerce Business',
            desc: "45,000 INR / 600 USD Monthly Best for eCommerce Business",
            btn: 'Ecommerce SEO Plan'
        },

    ]
};


export const industrySeo = [
    {
        img: i1,
        btn: "SEO",
        link: "/software/seo"
    },
    {
        img: i2,
        btn: "SMO",
        link: "/software/smo"
    },
    {
        img: i3,
        btn: "Web Design",
        link: "/software/webdesign"
    },
    {
        img: i4,
        btn: "Web Development",
        link: "/software/webdev"
    }
]