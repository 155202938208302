import React, { useEffect, useState } from "react";
import { fetchSingleCategory } from "../../services/operation/product";
import { Link, useParams } from "react-router-dom";
import HardwareNavbar from "../../components/common/HardwareNavbar";
import { calculateDiscount } from "../../helper/utills";
import CCTVNavbar from "./CCTV/CCTVNavbar";

function CCTVProduct() {
  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetchSingleCategory(id);
        console.log(response?.category);
        setCategories(response?.category);
        setSubCategories(response?.category?.subCategories);

       

        // Initialize visible products state
        const initialVisibleProducts = {};
        response?.category?.subCategories.forEach((subCategory) => {
          initialVisibleProducts[subCategory._id] = 3; // Show 3 products initially
        });
        setVisibleProducts(initialVisibleProducts);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [id]);

  const handleShowMore = (subCategoryId) => {
    setVisibleProducts((prevState) => ({
      ...prevState,
      [subCategoryId]: prevState[subCategoryId] + 3, // Show 3 more products
    }));
  };
  return (
    <div>
      <CCTVNavbar />

      <div>
        {categories ? (
          <>
            <div className=" pb-[30px]">
              <img
                src="https://www.cpplusworld.com/assets/img/breadcrumb_bg.jpg"
                alt=""
                className=" absolute"
              />
              <p className=" relative pt-[40px]  text-white font-bold text-4xl text-center ">
                Product
              </p>
            </div>

            <div className=" max-w-7xl mx-auto">
              {subCategories?.map((subCategory) => (
                <div key={subCategory._id}>
                  <div className="flex justify-center font-semibold my-5 text-xl">
                    <h2 className="  ">{subCategory.name}</h2>
                  </div>
                  {/* <img src={subCategory.image} alt={subCategory.name} style={{ width: '200px' }} /> */}
                  <ul className="grid lg:grid-cols-4 gap-4">
                    {subCategory?.Product?.slice(
                      0,
                      visibleProducts[subCategory._id]
                    ).map((product) => (
                      <Link
                        to={`/hardware/product/${product?._id}`}
                        key={product._id}
                        className={`border p-4 rounded-lg ${"flex flex-col"}`}
                      >
                        <div className="min-h-[130px] max-h-[130px] flex items-center justify-center">
                          <img
                            src={product?.images[0]?.url}
                            alt={product.title}
                            className={`mb-4  h-32`}
                          />
                        </div>
                        <div className={`flex flex-col`}>
                          <h3 className="text-lg font-semibold mb-2">
                            {product?.title?.split(" ").slice(0, 8).join(" ")}
                            ...
                          </h3>
                          <div className="text-gray-600 line-through">
                            ₹{product.highPrice}
                          </div>
                          <div className="text-red-500 font-semibold">
                            ₹{product.price}
                          </div>
                          <div className="text-green-500">
                            {calculateDiscount(
                              product?.highPrice - product?.price,
                              product.price
                            )}
                            % Off
                          </div>
                        </div>
                      </Link>
                    ))}
                  </ul>
                  {visibleProducts[subCategory._id] <
                    subCategory?.Product?.length && (
                    <div className="flex justify-center my-4">
                      <button
                        onClick={() => handleShowMore(subCategory._id)}
                        className="px-4 py-2 bg-blue-500 text-white rounded"
                      >
                        Show More
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}

export default CCTVProduct;
