// WhyChoose.js
import React, { useEffect, useState } from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import h1 from "../../../assets/hot/h1.webp";
import h2 from "../../../assets/hot/h2.webp";
import h3 from "../../../assets/hot/h3.webp";
import h4 from "../../../assets/hot/h4.webp";
import h5 from "../../../assets/hot/h5.webp";
import h6 from "../../../assets/hot/h6.webp";
import h7 from "../../../assets/hot/h7.webp";
import h8 from "../../../assets/hot/h8.webp";
import h9 from "../../../assets/hot/h9.webp";
import h10 from "../../../assets/hot/h10.webp";
import h11 from "../../../assets/hot/h11.jpg";
import { Link } from "react-router-dom";

const products = [
  {
    id: 1,
    image: h1,
    title: "SAMSUNG 80 cm (32 inch) HD Ready LED Smart TV (Black) (UA32T4450)",
    originalPrice: "35600",
    topPrice: "45600",
    discount: "20%",
    isSlod: true,
  },
  {
    id: 2,
    image: h2,
    title: "LG 108 cm (43 inch) 4K Ultra HD Smart LED TV (Black) (43UM7290PTF)",
    originalPrice: "47600",
    topPrice: "53600",
    discount: "15%",
    isSlod: false,
  },
  {
    id: 3,
    image: h3,
    title:
      "SONY Bravia 125.7 cm (50 inch) 4K Ultra HD Smart LED TV (Black) (KD-50X75)",
    originalPrice: "85600",
    topPrice: "94600",
    discount: "10%",
    isSlod: true,
  },
  {
    id: 4,
    image: h4,
    title:
      "Mi 138.8 cm (55 inch) 4K Ultra HD Android Smart LED TV (Black) (L55M5-5XIN)",
    originalPrice: "60600",
    topPrice: "68600",
    discount: "12%",
    isSlod: false,
  },
  {
    id: 5,
    image: h5,
    title:
      "OnePlus 138.8 cm (55 inch) Q1 Series 4K Certified Android QLED TV (Black) (55Q1IN-1)",
    originalPrice: "99600",
    topPrice: "109600",
    discount: "8%",
    isSlod: true,
  },
  {
    id: 6,
    image: h6,
    title:
      "Panasonic 80 cm (32 inch) HD Ready LED Smart TV (Black) (TH-32FS600D)",
    originalPrice: "27600",
    topPrice: "31600",
    discount: "13%",
    isSlod: false,
  },
  {
    id: 7,
    image: h7,
    title: "TCL 108 cm (43 inch) 4K Ultra HD Smart LED TV (Black) (43P615)",
    originalPrice: "32600",
    topPrice: "37600",
    discount: "14%",
    isSlod: true,
  },
  {
    id: 8,
    image: h8,
    title: "Hisense 108 cm (43 inch) 4K Ultra HD Smart LED TV (Black) (43A71F)",
    originalPrice: "29600",
    topPrice: "34600",
    discount: "15%",
    isSlod: false,
  },
  {
    id: 9,
    image: h9,
    title: "VU 108 cm (43 inch) Full HD Smart LED TV (Black) (43GA)",
    originalPrice: "19600",
    topPrice: "24600",
    discount: "20%",
    isSlod: true,
  },
  {
    id: 10,
    image: h10,
    title:
      "Realme 80 cm (32 inch) HD Ready LED Smart Android TV (Black) (RMV2003)",
    originalPrice: "18600",
    topPrice: "22600",
    discount: "18%",
    isSlod: false,
  },
  {
    id: 11,
    image: h11,
    title:
      "Motorola 109.2 cm (43 inch) Ultra HD (4K) LED Smart Android TV (Black) (43SAFHDM)",
    originalPrice: "40600",
    topPrice: "47600",
    discount: "15%",
    isSlod: true,
  },
];

const truncateTitle = (title, wordLimit) => {
  const words = title.split(" ");
  return words.length > wordLimit
    ? words.slice(0, wordLimit).join(" ") + "..."
    : title;
};

const New = ({AllProducts}) => {


  
const [top5Products,setTop5Products] = useState([])
useEffect(() => {
  if (AllProducts && AllProducts.length > 0) {
    // Sort the products by createdAt
    const sortedProducts = AllProducts.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    // Get the top 5 products from the sorted list
    const top5Prod = sortedProducts.slice(0, 5);

    // Set the top 5 products
    setTop5Products(top5Prod);
    
    // Optionally log the top 5 products for debugging
    console.log(top5Prod);
  }
}, [AllProducts]);


  return (
    <div className="my-10">
      <p className="px-5 text-3xl font-bold text-red-600">WHAT'S NEW</p>
      <hr />

      <div className="px-5 mt-2 ">
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          autoplay={{ delay: 2000 }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
       {top5Products?.map((item) => (
            <SwiperSlide key={item.id} className="">
              <Link  to={`/hardware/product/${item._id}`}  className="border border-gray-400 hover:border-red-600 rounded-md shadow-lg py-5">
                <img
                  src={item.images[0]?.url}
                  alt={item.title}
                  className="w-full h-60 object-cover rounded-md "
                />
                <div className="mt-8 mb-3">
                  <p className="text-center">{truncateTitle(item.title, 8)}</p>
                  <div className="flex gap-3 justify-center mt-3">
                    <p>₹{item?.price}</p>
                    <p className="line-through text-red-600">
                      ₹{item?.highPrice}
                    </p>
                    <p className="text-green-600">{item.discount}</p>
                  </div>
                
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default New;
