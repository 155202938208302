import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import newsReducer from "./productSlice"
import cartSlice from "./cartSlice"
import paymentReducer from "./paymentSlice"
const store = configureStore({
  reducer: {
    auth: authReducer,
    news: newsReducer,
    cart: cartSlice,
  payment :paymentReducer,

  },
});

export default store;
