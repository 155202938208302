import React, { useEffect, useState } from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import e1 from "../../assets/hardware/hero/camera.png";
import e2 from "../../assets/hardware/hero/tv 2.webp";
import e3 from "../../assets/hardware/hero/tv2.webp";
import e4 from "../../assets/hardware/hero/cam2.webp";
import gps1 from "../../assets/hardware/hero/gps1.png"
import gps2 from "../../assets/hardware/hero/gps2.jpg"
import gps3 from "../../assets/hardware/hero/gps3.png"

import e5 from "../../assets/e6.jpg";
import e6 from "../../assets/e7.jpeg";
import e7 from "../../assets/e8.webp";
// import e5 from "../assets/e5.jpg";
// import e6 from "../assets/e6.jpg";



const Slider = ({name}) => {
  const [work, setWork] = useState([
    { id: 1, image: e1 },
    { id: 2, image: e2 },
    { id: 3, image: e3 },
    { id: 4, image: e4 },
    // { id: 5, image: e5 },
    // { id: 6, image: e6 },
  ]);

  useEffect(() => {
    console.log(`name: ${name}`); // Debugging log

    if (name === "tv") {
      setWork([
        { id: 2, image: e2 },
        { id: 3, image: e3 },
      ]);
    } else if (name === "camera") {
      setWork([
        { id: 1, image: e1 },
        { id: 4, image: e4 },
      ]);
    } else if (name === "gps") {
      setWork([
        { id: 5, image: gps1 },
        { id: 6, image: gps2 },
        { id: 7, image: gps3 },
      ]);
    }
  }, [name]);
  return (
    <div className="relative mt-2">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 2000 }}
        spaceBetween={0}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        {work.map((item) => (
          <SwiperSlide key={item.id}>
            <img
              src={item.image}
              alt="Service"
              className="w-full lg:h-[80vh]"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
