export const gpsData =[
    {
        id:1,
        heading:"How Does GPS Work?",
        p:"GPS is actually a very simple technology. The piece of GPS tracker which is fitted into the car is actually synced with the satellites. 24 US satellites cover the whole world and thus, wherever the car might be, it is never far from the coverage area. Now, these satellites continuously check the speed and the direction of the car, along with the exact location. The exact process of locating the car is called triangulation. In short, once you have installed GPS Vehicle Tracking Systems from GPS Systems Indias India, no matter where you are in the world, you will always be tracked and that is what makes you feel safe."
    },
    {
        id:2,
        heading:"Who Better Than GPS Systems Indias India",
        p:"For over 20 years, we have provided the best GPS equipments to our clients. We are one of the earliest companies to operate in India. Our quality products have catered to the tracking needs of every Indian. All of our products are thoroughly tested and verified for international standards."
    },
    {
        id:3,
        heading:"Choosing Another GPS Route System Can Be an Overwhelming Endeavor.",
        p:"Today simple GPS route and shoddy GPS systems can be discovered on the web or in rebate retail establishments all over. GPS (Global Positioning Systems) beneficiaries are never again a costly device for seekers and game angler; they are a piece of our regular day to day existence and for some as critical and fundamental as a wireless. With a modest GPS a USA delineate in the beneficiary; a driver could without much of a stretch discover their path anyplace in the USA. Worldwide situating system satellites are at last demonstrating their helpfulness to the normal purchaser. In spite of the fact that not every person is outfitted with GPS systems, there are more devices out in the standard market nowadays. You may even be overpowered with the huge determination that you can't decide concerning what GPS system to get. The best approach to do it is to make sense of your needs and investigate the diverse alternatives. There will undoubtedly be a class for you as there are more than five sorts of GPS systems accessible."
    },
    {
        id:4,
        heading:"Vehicle Tracking System",
        p:"Vehicle GPS systems are furnished with fundamental collectors that are quite recently enough to report the present area utilizing scope and longitude units. Their restricted stockpiling limit may enable some base maps to be preloaded in the device. These maps ordinarily contain major expressways, milestones, and a few purposes of intrigue."
    },
    {
        id:5,
        heading:"Bike Tracking System",
        p:"Bike Tracking System Bike GPS systems have comparable highlight as the Vehicle ones, yet they accompany detail tracking instruments that advantage preparing competitors or bike lovers. Some of these instruments incorporate heart rate screens and calorie trackers. For accommodation, these GPS devices are worn instead of held so their practicing schedules aren't interfered. One case of such systems is the GPS watch."
    },
    {
        id:6,
        heading:"Car Tracking System",
        p:"Car GPS systems are plain as day, yet they come in two distinct sorts. The prominent sort is the compact units that are purchased independently. Many known GPS producers offer huge amounts of models at spending plan and premium costs. A portion of the top notch models contain propelled highlights like Bluetooth usefulness and online availability to get continuous movement data. These systems frequently accompany suction mugs so you can mount them to the windshield or dashboard."
    },
    {
        id:7,
        heading:"Personal Tracking System",
        p:"The In-dash units are more costly; however look more pleasant as they are for all time mounted in the car. The main burden is that the GPS system can't be taken to another vehicle if necessary. Be that as it may, they better incorporate with the highlights of your car bringing about better usefulness"
    },
   
]