import React, { useEffect, useState } from "react";
import { FaBars, FaTimes, FaShoppingCart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../../services/operation/user";
import logo from "../../../assets/software/logo.jpg";

const CCTVNavbar = () => {
  const { user } = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const { cart } = useSelector((state) => state.cart);
  const cartQuantity = cart.length;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleMenu = () => setIsOpen(!isOpen);
  const location = useLocation();
  const [navLinks, setNavLinks] = useState([]);
  const [dropdownStates, setDropdownStates] = useState({});

  useEffect(() => {
    const currentPath = location.pathname;

    const links = [
      { name: "Main Home", path: "/" },
      { name: "Home", path: "/hardware/cctv/home" },
      { name: "About Us", path: "/hardware/cctv/about" },
      {
        name: "Product",
        path: "#",
        subLinks: [
          {
            name: "All Product",
            path: "/hardware/category/cctv/66ab2c45746d8bcd80ca8e89",
          },
          {
            name: "Analog HD Camera",
            path: "/hardware/cctv/subcategory/66ae4f1553d56e9ac421bae6",
          },
          {
            name: "Digital Video Recorder (DVR)",
            path: "/hardware/cctv/subcategory/66ae4ecd53d56e9ac421bade",
          },
          {
            name: "Network Camera",
            path: "/hardware/cctv/subcategory/66ab57d43349f329f0662eeb",
          },
          {
            name: "Analog Wired Kit (Combo)",
            path: "/hardware/cctv/subcategory/66ae4f1553d56e9ac421bae6",
          },
          {
            name: "Switches",
            path: "/hardware/cctv/subcategory/66ae4f4253d56e9ac421baeb",
          },
          {
            name: "Accessories",
            path: "/hardware/cctv/subcategory/66ae4f7653d56e9ac421baf0",
          },
        ],
      },
      {
        name: "Solution",
        path: "#",
        subLinks: [
          { name: "Banking", path: "/hardware/solution/banking" },
          { name: "Education", path: "/hardware/solution/education" },
          { name: "Retail Sector", path: "/hardware/solution/retail" },
          { name: "Smart City", path: "/hardware/solution/smart-city" },
        ],
      },
      { name: "Become Partner", path: "/hardware/cctv/become" },
    ];

    setNavLinks(links);
  }, [location]);

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  const handleLogout = async () => {
    dispatch(logout(navigate));
  };

  const toggleDropdown = (index) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div className="bg-black text-white">
      <nav className="max-w-7xl mx-auto p-4">
        <div className="flex justify-between items-center">
          <div>
            <Link to="/" className="flex items-center">
              <img src={logo} alt="Logo" className="h-10" />
            </Link>
          </div>
          <div className="hidden md:flex space-x-6">
            {navLinks.map((link, index) =>
              link.subLinks ? (
                <div key={index} className="relative group">
                  <span className="cursor-pointer hover:text-yellow-400">
                    {link.name}
                  </span>
                  <div className="absolute hidden group-hover:block bg-black text-white mt-2 rounded shadow-lg z-10 min-w-[300px]">
                    {link.subLinks.map((subLink, subIndex) => (
                      <Link
                        key={subIndex}
                        to={subLink.path}
                        className="block px-4 py-2 hover:bg-gray-700 hover:text-white"
                        onClick={handleLinkClick}
                      >
                        {subLink.name}
                      </Link>
                    ))}
                  </div>
                </div>
              ) : (
                <Link
                  key={index}
                  to={link.path}
                  className="hover:text-yellow-400"
                  onClick={handleLinkClick}
                >
                  {link.name}
                </Link>
              )
            )}
            <Link
              to="/hardware/cart"
              className="hover:text-yellow-400 relative"
            >
              <FaShoppingCart size={24} />
              <p className="text-black bg-yellow-400 rounded-full text-center w-[20px] h-[20px] absolute -top-2 -right-3">
                {cartQuantity}
              </p>
            </Link>
            {user ? (
              <button
                onClick={handleLogout}
                className="hover:text-yellow-400 relative bg-blue-500 rounded-xl p-1 px-4"
              >
                Logout
              </button>
            ) : (
              <Link
                to="/login"
                className="hover:text-yellow-400 relative bg-blue-500 rounded-xl p-1 px-4"
              >
                Login
              </Link>
            )}
          </div>
          <div className="md:hidden">
            <button onClick={toggleMenu}>
              {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>
        {isOpen && (
          <div className="md:hidden mt-2 space-y-2">
            <div className="flex flex-col items-start">
              {navLinks.map((link, index) => (
                <div key={index} className="w-full">
                  {link.subLinks ? (
                    <div className="relative w-full">
                      <span
                        className="cursor-pointer hover:text-yellow-400 block py-2 px-4"
                        onClick={() => toggleDropdown(index)}
                      >
                        {link.name}
                      </span>
                      {dropdownStates[index] && (
                        <div className="bg-black text-white rounded shadow-lg w-full">
                          {link.subLinks.map((subLink, subIndex) => (
                            <Link
                              key={subIndex}
                              to={subLink.path}
                              className="block px-4 py-2 hover:bg-gray-700 hover:text-white"
                              onClick={handleLinkClick}
                            >
                              {subLink.name}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <Link
                      to={link.path}
                      className="block py-2 px-4 hover:bg-gray-700 w-full"
                      onClick={handleLinkClick}
                    >
                      {link.name}
                    </Link>
                  )}
                </div>
              ))}
              <Link
                to="/hardware/cart"
                className="block py-2 px-4 hover:bg-gray-700 relative"
                onClick={handleLinkClick}
              >
                <FaShoppingCart size={24} />
                <p className="text-yellow-400 absolute top-0">{cartQuantity}</p>
              </Link>
              {user ? (
                <button
                  onClick={handleLogout}
                  className="block py-2 px-4 hover:bg-gray-700 bg-blue-500 rounded-xl w-full"
                >
                  Logout
                </button>
              ) : (
                <Link
                  to="/login"
                  className="block py-2 px-4 hover:bg-gray-700 bg-blue-500 rounded-xl w-full"
                >
                  Login
                </Link>
              )}
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};

export default CCTVNavbar;
