import React from "react";
import { Slide } from "react-awesome-reveal";
import a1 from "../../assets/software/about/a1.png";
import a2 from "../../assets/software/about/a2.png";
import a3 from "../../assets/software/about/a3.png";
import a4 from "../../assets/software/about/a4.png";
import a5 from "../../assets/software/about/a5.png";
import a6 from "../../assets/software/about/a6.png";
import a7 from "../../assets/software/about/a7.png";
import a8 from "../../assets/software/about/a8.png";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="">
      <div className="bg-black py-5">
        <div className="relative z-10 max-w-7xl mx-auto px-4 lg:gap-32 py-1 bg-opacity-90 bg-transparent text-white">
          <div className="main  items-center grid  gap-5 lg:grid-cols-2">
            <Slide direction="left">
              <div className="second border overflow-hidden grid gap-3 p-5">
                <p className="text-yellow-400 font-semibold text-2xl text-center">
                  As Seen On
                </p>
                <div className="scroll-container">
                  <div className="scroll-left-to-right flex gap-2 lg:gap-1">
                    <img
                      src={a1}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a2}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a3}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a4}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a4}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a5}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a6}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                  </div>
                </div>
                <div className="scroll-container">
                  <div className="scroll-right-to-left flex gap-2 lg:gap-1">
                    <img
                      src={a5}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a6}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a7}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a8}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a1}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a2}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                  </div>
                </div>
              </div>
            </Slide>
            <Slide direction="right">
              <div className="first grid gap-4 p-4 md:gap-6 md:p-8">
                <p className="text-xl md:text-3xl font-semibold  border-l-4 border-yellow-400 pl-4">
                  About Provision Plus Technologies- A Well Known Digital
                  Marketing Agency in India
                </p>
                <p className="text-sm md:text-xl ">
                  Founded in 2017, Provision Plus is a leading international
                  software & Hardware Manufacturing ( Security Surveillance ‘
                  CCTV ; LEDs ; GPS )provider for the eCommerce , Service
                  Industry , Banking and Investment Management industries. The
                  company has a presence in Delhi , Mumbai , Ranchi and Gaya
                  delivering innovative solutions to both start-ups and
                  established institutions, through direct communication or a
                  reliable partners network.
                </p>

                <div>
                  <Link
                    to={"/software/about"}
                    className="bg-red-600 mt-3  rounded-full text-white  w-fit px-5 py-4 text-xl lg:text-xl"
                  >
                    About Provision Plus
                  </Link>
                </div>
                <p className="text-xl mt-1">Check Our Reviews On</p>
                <p className="text-xl mt-1">
                  Upwork, Clutch, & Google My Business Now!
                </p>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
