import React, { useEffect, useState } from "react";
import { fetchSingleSubCategory } from "../../services/operation/product";
import Slider from "../../components/core/home/Category";
import SliderImage from "../../components/core/home/Slider";
import { useParams } from "react-router-dom";
import Hardware from "../../components/common/HardwareNavbar";
import CombinedComponent from "../../components/core/home/CombinedComponent";

function SingleCategory() {
  const [categories, setCategories] = useState([]);
  const [product, setProducts] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetchSingleSubCategory(id);
        setCategories(response?.subCategories)
        setProducts(response?.subCategories?.Product);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);
  return (
    <div>
      <Hardware />
      <div className=" mt-[50px]">
        <div className=" flex flex-col  w-full items-center">
          <h3 className="  text-4xl font-fjalla text-[#33536B]">
           Select Category {categories?.name}
          </h3>
          <div className="flex items-center w-[75px]">
            <div className="h-0.5 bg-[#e2571a]"></div>
            <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
            <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
            <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
            <div
              className="h-[4px] rounded-full w-[10px] flex-grow"
              style={{ backgroundColor: "#e2571a" }}
            ></div>
          </div>
           
        </div>
        {
          <div>
            {/* <SliderImage categories={categories} /> */}
          </div>
        }

        <div>
          <CombinedComponent products={product} />
        </div>
      </div>
    </div>
  );
}

export default SingleCategory;
