import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import CCTVNavbar from "./CCTVNavbar";
import { fetchSingleCategory, fetchSingleSubCategory } from "../../../services/operation/product";
import { calculateDiscount } from "../../../helper/utills";


function SingleSubCategory() {

  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState({});
  const { subcat } = useParams();
    const[subProduct,setSubProduct] = useState([])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetchSingleSubCategory(subcat);
        console.log(response?.subCategories        );
        // setCategories(response?.category);
        // setSubCategories(response?.category?.subCategories);
        console.log(subcat)
        
           setSubProduct(response?.subCategories)
            console.log(subcat)
    
     
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [subcat]);


  return (
    <div>
      <CCTVNavbar />

      <div>
        {subProduct ? (
          <>
            <div className=" lg:pb-[30px] ">
              <img
                src="https://www.cpplusworld.com/assets/img/breadcrumb_bg.jpg"
                alt=""
                className=" absolute"
              />
              <p className=" relative lg:pt-[40px]  text-white font-bold text-4xl text-center ">
                Product
              </p>
            </div>

            <div className=" max-w-7xl mx-auto">
              
                <div key={subProduct._id}>
                  <div className="flex justify-center font-semibold my-5 text-xl">
                    <h2 className="  ">{subProduct.name}</h2>
                  </div>
                  {/* <img src={subCategory.image} alt={subCategory.name} style={{ width: '200px' }} /> */}
                  <ul className="grid lg:grid-cols-4 gap-4">
                    {subProduct?.Product?.map((product) => (
                      <Link
                        to={`/hardware/product/${product?._id}`}
                        key={product._id}
                        className={`border p-4 rounded-lg ${"flex flex-col"}`}
                      >
                        <div className="min-h-[130px] max-h-[130px] flex items-center justify-center">
                          <img
                            src={product?.images[0]?.url}
                            alt={product.title}
                            className={`mb-4  h-32`}
                          />
                        </div>
                        <div className={`flex flex-col`}>
                          <h3 className="text-lg font-semibold mb-2">
                            {product?.title?.split(" ").slice(0, 8).join(" ")}
                            ...
                          </h3>
                          <div className="text-gray-600 line-through">
                            ₹{product.highPrice}
                          </div>
                          <div className="text-red-500 font-semibold">
                            ₹{product.price}
                          </div>
                          <div className="text-green-500">
                            {calculateDiscount(
                              product?.highPrice - product?.price,
                              product.price
                            )}
                            % Off
                          </div>
                        </div>
                      </Link>
                    ))}
                  </ul>
                
                </div>
           
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}

export default SingleSubCategory;
