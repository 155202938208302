import React, { useEffect, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
import Navbar from "../common/SoftwareNavbar";
import Footer from "../common/Footer";
import { userEndpoints } from "../../services/apis";
import { apiConnector } from "../../services/apiConnector";
import Swal from "sweetalert2";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Loading",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const res = await apiConnector(
        "POST",
        userEndpoints.CONTACT_US_API,
        formData
      );

      Swal.fire({
        title: `SEO Audit Request Sent Successfully!`,
        text: `Have a nice day!`,
        icon: "success",
      });

      // Reset form data
      setFormData({
        name: "",
        email: "",
        contact: "",
        message: "",
      });
    } catch (error) {
      console.error("ERROR MESSAGE - ", error);
      Swal.fire({
        title: "Request Failed",
        text:
          error.response?.data?.message ||
          "Something went wrong, please try again later",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="">
        <div className="grid gap-10 max-w-7xl mx-auto px-5 lg:grid lg:grid-cols-2 my-36 items-center">
          <div className="first flex flex-col gap-8">
            <div className="flex items-center gap-4">
              <span className="px-4 py-3 bg-yellow-400 text-white rounded-md text-xl">
                <FaLocationDot />
              </span>
              <ul className="text-sm list-disc ml-3">
                <li>
                  {" "}
                  Rimjhim Kothi Indrapuri Road 1 Ratu road Ranchi 834005
                  Jharkhand
                </li>
                <li>
                  {" "}
                  New Area Gaus Nagar Manitola Doranda 834002 Ranchi Jharkhand
                </li>
              </ul>
            </div>
            <div className="flex items-center gap-4">
              <span className="px-4 py-3 bg-yellow-400 text-white rounded-md text-xl">
                <MdEmail />
              </span>
              <span className="text-sm">provisionplusindia@gmail.com</span>
            </div>
            <div className="flex items-center gap-4">
              <span className="px-4 py-3 bg-yellow-400 text-white rounded-md text-xl">
                <FaPhone />
              </span>
              <span className="text-sm">+919212315612 , +916200819868</span>
            </div>
          </div>
          <form className="second flex flex-col gap-2" onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-2">
              <input
                type="text"
                placeholder="Enter your name"
                className="p-3 outline-none h-14 border border-gray-300 bg-transparent"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <input
                type="email"
                placeholder="Enter your email"
                className="p-3 outline-none h-14 border border-gray-300 bg-transparent"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <input
              type="text"
              placeholder="Enter your phone"
              className="p-3 outline-none h-14 border border-gray-300 bg-transparent"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
            />
            <textarea
              className="p-3 outline-none h-28 border border-gray-300 bg-transparent"
              placeholder="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            <button
              type="submit"
              className="px-6 py-3 rounded-md bg-red-600 text-white text-xl cursor-pointer"
            >
              Contact Us
            </button>
          </form>
        </div>

        <iframe
          title="Provision Plus"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3662.0719225183857!2d85.30274428650819!3d23.3856152425284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f4e123eb58e8af%3A0xac020195aa2dcc08!2sSonora%20Engineering!5e0!3m2!1sen!2sin!4v1723014036811!5m2!1sen!2sin"
          className="w-full h-[70vh] mb-10"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
