import React, { useState } from "react";
import Navbar from "../../components/common/HardwareNavbar";
import Footer from "../../components/common/Footer";

const HardwareGetQuote = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    service: "",
    websiteUrl: "",
    location: "",
    monthlyBudget: "",
    websiteStyle: "",
    domainPurchase: "",
    numberOfPages: "",
    budget: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div>
      <Navbar />
      <div className="bg-yellow-400">
        <div className="grid lg:grid-cols-2 max-w-7xl mx-auto gap-5 p-5 lg:py-16">
          <div className="text-center my-12">
            <h1 className="text-4xl font-bold mb-4">
              Trusted by 500+ Domestic & International Clients
            </h1>
            <h2 className="text-2xl text-gray-700 mb-4">
              A Company That Ranks #1 for more than 1K keywords is here to get
              you on 1st Page.
            </h2>
            <p className="text-gray-600 mb-4">
              If your enquiry seems genuine to us, your entries in the form
              below look real & meaningful, and your request gets shortlisted
              our team will get connected with you as soon as possible.
            </p>
            <p className="text-gray-600">
              You can also request paid consultation and if you decide to work
              with us then that consultation price will be adjusted in the
              billing.
            </p>
          </div>

          <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                placeholder="Enter Your Name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-3 py-3 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter Your Email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-3 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700">Mobile</label>
              <input
                type="text"
                name="mobile"
                placeholder="Enter Your Mobile Number "
                value={formData.mobile}
                onChange={handleChange}
                className="w-full px-3 py-3 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700">Service</label>
              <select
                name="service"
                value={formData.service}
                onChange={handleChange}
                className="w-full px-3 py-3 border rounded"
              >
                <option value="">Select a service</option>
                <option value="digital-marketing">Digital Marketing</option>
                <option value="web-development">Web Development</option>
              </select>
            </div>

            {formData.service === "digital-marketing" && (
              <>
                <div>
                  <label className="block text-gray-700">Website URL</label>
                  <input
                    type="text"
                    name="websiteUrl"
                    placeholder="Website Url"
                    value={formData.websiteUrl}
                    onChange={handleChange}
                    className="w-full px-3 py-3 border rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Location</label>
                  <input
                    type="text"
                    name="location"
                    placeholder="Location"
                    value={formData.location}
                    onChange={handleChange}
                    className="w-full px-3 py-3 border rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Monthly Budget</label>
                  <input
                    type="text"
                    name="monthlyBudget"
                    placeholder="Mothly Budget"
                    value={formData.monthlyBudget}
                    onChange={handleChange}
                    className="w-full px-3 py-3 border rounded"
                  />
                </div>
              </>
            )}

            {formData.service === "web-development" && (
              <>
                <div>
                  <label className="block text-gray-700">Website Style</label>
                  <select
                    name="websiteStyle"
                    value={formData.websiteStyle}
                    onChange={handleChange}
                    className="w-full px-3 py-3 border rounded"
                  >
                    <option value="">Select style</option>
                    <option value="modern">Modern</option>
                    <option value="classic">Classic</option>
                  </select>
                </div>
                <div>
                  <label className="block text-gray-700">Domain Purchase</label>
                  <select
                    name="domainPurchase"
                    value={formData.domainPurchase}
                    onChange={handleChange}
                    className="w-full px-3 py-3 border rounded"
                  >
                    <option value="">Select option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div>
                  <label className="block text-gray-700">Number of Pages</label>
                  <input
                    type="number"
                    name="numberOfPages"
                    value={formData.numberOfPages}
                    onChange={handleChange}
                    placeholder="Number Of Pages"
                    className="w-full px-3 py-3 border rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Budget</label>
                  <select
                    name="budget"
                    value={formData.budget}
                    onChange={handleChange}
                    className="w-full px-3 py-3 border rounded"
                  >
                    <option value="">Select option</option>
                    <option value="5000">5000+</option>
                    <option value="10000">10000+</option>
                  </select>
                </div>
              </>
            )}

            <div className="md:col-span-2">
              <label className="block text-gray-700">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-3 py-3 border rounded lg:h-24 h-20"
                placeholder="Message"
              ></textarea>
            </div>

            <div className="md:col-span-2">
              <button
                type="submit"
                className="w-full bg-black text-white px-4 py-2 rounded"
              >
                Get Quote
              </button>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default HardwareGetQuote;
