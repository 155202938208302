import React from "react";
import SingleCategory from "../SingleCategory";
import TVNavbar from "./TVNavbar";
import Footer from "../../../components/common/Footer";

const LedTv = () => {
  return (
    <div>
      <TVNavbar />
      <SingleCategory Id={"66aa21ae162fae46903e3d13"} />
      <Footer />
    </div>
  );
};

export default LedTv;
