import React from "react";

const Poster = () => {
  return (
    <div className="mt-10">
      <div className="bg-black">
        <br />
        <div className="max-w-7xl mx-auto px-5 py-8">
          <p className="text-center text-xl lg:text-2xl font-bold text-yellow-400">
            Provision Plus
          </p>
          <br />
          <p className="text-center  text-2xl lg:text-4xl font-bold text-white">
            One of The Highest Rated Digital Marketing Companies in India
          </p>
          <br />
          <p className="text-center text-xl lg:text-2xl font-bold text-yellow-400">
            You can read 100s of verified reviews about our digital marketing
            services online on platforms like Upwork, Freelancer, Etc.
          </p>
          <br />
          <p className="text-center text-sm lg:text-xl leading-loose text-white">
            RankON Technologies is a competitive SEO company that works for
            success and puts 100% of its knowledge and skill to provide our
            client’s website with a better position in search engines.
          </p>
          <br />
          <p className="text-center text-sm lg:text-xl leading-loose text-white">
            This is what we love to do and live to do. We read hundreds of SEO
            articles every day to keep our skills updated so you get the best
            from our agency.
          </p>
        </div>
        <br />
      </div>
    </div>
  );
};

export default Poster;
