import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { displayMoney } from "../../../helper/utills";
import { FiShoppingCart } from "react-icons/fi";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import Address from "./Address";
import Payment from "./Payment";
import { Link } from "react-router-dom";

const CheckoutForm = ({ handleClose }) => {
  const { cart, total } = useSelector((state) => state.cart);
  const [isOpen, setIsOpen] = useState(true);
  const [payable, setPayable] = useState(total);
  const dispatch = useDispatch();

  // Coupon state
  const [couponName, setCouponName] = useState("");
  const [coupon, setCoupon] = useState(false);
  const [couponValue, setCouponValue] = useState(0);
  const [couponValid, setCouponValid] = useState(true);

  const displayTotalAmount = displayMoney(total);

  const toggleSummary = () => {
    setIsOpen(!isOpen);
  };

  const { step } = useSelector((state) => state.payment);

  return (
    <div className="flex flex-wrap lg:flex-nowrap lg:min-h-[calc(100vh-150px)] min-h-[calc(100vh-200px)] lg:max-h-[calc(100vh-150px)] max-h-[calc(100vh-130px)] checkout font-montserrat bg-gray-100">
      {/* Left Section */}
      <div className="lg:w-[65%]  w-screen border-r-2 ">
        {step === 1 && <Address />}
        {step === 2 && <Payment payable={payable} />}
      </div>

      {/* Right Section */}
      <div className="lg:w-1/3 w-full bg-white shadow-lg rounded-lg">
        <div className="p-4">
          <button
            type="button"
            onClick={toggleSummary}
            className="w-full p-2 rounded-xl font-bold text-left flex justify-between items-center bg-gray-200 hover:bg-gray-300 transition"
          >
            <div className="flex items-center gap-3">
              <FiShoppingCart className="text-xl text-gray-600" />
              <span className="text-gray-700">Order Summary Details</span>
            </div>
            <span className="text-gray-600">{isOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
          </button>
          {isOpen && (
            <div className="p-3 max-h-[250px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300">
              <ul className="flex flex-col gap-4">
                {cart.map((item, ind) => (
                  <li key={ind} className="flex gap-4 border-b border-gray-300 py-2">
                    <div className="w-1/4 border-r border-gray-300 pr-3">
                      <Link to={`/product/${item.product._id}`} onClick={handleClose}>
                        <img
                          src={item.product.images[0].url}
                          alt="Product"
                          className="w-full h-auto object-cover rounded-lg"
                        />
                      </Link>
                    </div>
                    <div className="flex flex-col w-3/4">
                      <p className="text-lg font-semibold text-gray-800">{item.product.title}</p>
                      <p className="text-sm text-gray-600">Price: {displayMoney(item.product.price)}</p>
                      <p className="text-sm text-gray-600">Quantity: {item.quantity}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="mt-4">
            <div className="flex justify-between text-sm text-gray-700 px-2">
              <span>Subtotal</span>
              <span>{displayTotalAmount}</span>
            </div>
            {coupon && (
              <div className="flex justify-between text-sm text-green-600 px-2 mt-2">
                <span>Discount</span>
                <span>- {displayMoney(couponValue)}</span>
              </div>
            )}
            <div className="flex justify-between text-sm text-gray-700 px-2 mt-2">
              <span>Shipping</span>
              <span>To be calculated</span>
            </div>
            <hr className="my-4 border-gray-300" />
            <div className="flex justify-between text-base font-bold text-gray-800 px-2">
              <span>Payable</span>
              <span>{displayMoney(payable)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
