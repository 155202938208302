import React, { useEffect, useState } from "react";
import { fetchSingleCategory } from "../../services/operation/product";
import { Link, useParams } from "react-router-dom";
import HardwareNavbar from "../../components/common/HardwareNavbar";
import { calculateDiscount } from "../../helper/utills";
import GPSNavbar from "./GPS/GPSNavbar";

function GPSProduct() {
  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetchSingleCategory(id);
        console.log(response?.category);
        setCategories(response?.category);
        setSubCategories(response?.category?.subCategories);

        // Initialize visible products state
        const initialVisibleProducts = {};
        response?.category?.subCategories.forEach((subCategory) => {
          initialVisibleProducts[subCategory._id] = 3; // Show 3 products initially
        });
        setVisibleProducts(initialVisibleProducts);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [id]);

  const handleShowMore = (subCategoryId) => {
    setVisibleProducts((prevState) => ({
      ...prevState,
      [subCategoryId]: prevState[subCategoryId] + 3, // Show 3 more products
    }));
  };
  return (
    <div>
      <GPSNavbar />

      <div>
        {categories ? (
          <>
            <div className=" pb-[30px]">
              <img
                src="http://gpssystemindia.in/images/img2.png"
                alt=""
                className=" absolute"
              />
              <p className=" relative pt-[80px]  text-black font-bold text-4xl text-center ">
                About GPS Systems India Products
              </p>
            </div>

            <div className=" flex flex-col gap-3 max-w-7xl mx-auto pt-32 p-4">
              <div className=" flex flex-col gap-4">
                <h2 className=" text-4xl font-bold">
                  GPS Systems India Products
                </h2>
                <h3 className=" text-gray-500 text-2xl">
                  Create beautiful Landmarks, Setting Benchmarks.
                </h3>
              </div>

              <div>
                A lot of different GPS products are up for grab from mark GPS
                System India. These products will cover almost every tracking
                purpose a simple man might have. From personal vehicle tracking,
                managing the fleet professionally or keeping an eye on kids,
                elders and pets; every purpose is served with our products.{" "}
                <br /> <br /> <br />
                We always bring products which are of international quality and
                thus they adhere to the international regulations. To accompany
                those hardware, we have the latest GPS tracking software as well
                so they can come together to give you the most accurate location
                data. The GOS vehicle tracking equipments can be used instantly.
                In fact, there are very few companies in India who can actually
                match our diverse range of GPS products. <br /> <br /> <br />
                We also make sure that all of our GPS systems abide by Indian
                laws and regulations so there is no law broken by using our
                tracking devices. We run each product for Indian quality and
                adherence test. Two different tests make sure that they come up
                with impeccable quality and the products are just unbeatable. We
                also run real life testing of our products so we don’t end up
                relying too much on laboratory data. <br />
              </div>
              <div className=" flex flex-col gap-4">
                <h2 className=" text-4xl font-bold">
                  What Our Products Offer you?
                </h2>
              </div>

              <div>
                Our GPS tracking system equipments come from the best
                manufacturers in the world and thus they satisfy every need of
                our clients. We are a leader in the GPS industry for years now
                and that can tell you our customers have been happy beyond
                expectation. We are so confident of our products that we offer
                long warranty on each product we sell. <br /> <br /> <br />
                Our products don’t have a history of going much wrong but if,
                unfortunately, it does, we have the biggest dealer network
                across India who can immediately reach you and help you out with
                whatever you might need. <br /> <br /> <br />
                You gain everything when you use our products. You can be
                managing your car or fleet beautifully. You can keep a tab on
                your drivers. You will immediately get an alert if someone is
                trying to steal your car. You can know where the kids are, the
                pets are or the elderly members of the family are. You get a
                peaceful personal life and a very rewarding professional life.
                Money can not buy everything but money can surely buy you all
                these things from GPS Systems India.
              </div>
            </div>
            <div className=" max-w-7xl mx-auto">
              {subCategories?.map((subCategory) => (
                <div key={subCategory._id}>
                  <div className="flex justify-center font-semibold my-5 text-xl">
                    <h2 className="  ">{subCategory.name}</h2>
                  </div>
                  {/* <img src={subCategory.image} alt={subCategory.name} style={{ width: '200px' }} /> */}
                  <ul className="grid lg:grid-cols-4 gap-4">
                    {subCategory?.Product?.slice(
                      0,
                      visibleProducts[subCategory._id]
                    ).map((product) => (
                      <Link
                        to={`/hardware/product/${product?._id}`}
                        key={product._id}
                        className={`border p-4 rounded-lg ${"flex flex-col"}`}
                      >
                        <div className="min-h-[130px] max-h-[130px] flex items-center justify-center">
                          <img
                            src={product?.images[0]?.url}
                            alt={product.title}
                            className={`mb-4  h-32`}
                          />
                        </div>
                        <div className={`flex flex-col`}>
                          <h3 className="text-lg font-semibold mb-2">
                            {product?.title?.split(" ").slice(0, 8).join(" ")}
                            ...
                          </h3>
                          <div className="text-gray-600 line-through">
                            ₹{product.highPrice}
                          </div>
                          <div className="text-blac font-semibold flex">
                          <p className=" bg-yellow-500 px-2 rounded-lg ">  ₹{product.price}</p>
                          </div>
                          <div className="text-green-500">
                            {calculateDiscount(
                              product?.highPrice - product?.price,
                              product.price
                            )}
                            % Off
                          </div>
                        </div>
                      </Link>
                    ))}
                  </ul>
                  {visibleProducts[subCategory._id] <
                    subCategory?.Product?.length && (
                    <div className="flex justify-center my-4">
                      <button
                        onClick={() => handleShowMore(subCategory._id)}
                        className="px-4 py-2 bg-blue-500 text-white rounded"
                      >
                        Show More
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}

export default GPSProduct;
