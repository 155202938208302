import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  fetchCategory,
  imageUpload,
  fetchSubCategory,
  createProduct,
  getSingleProduct,
  editProduct,
} from "../../../services/operation/product";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

function AddProduct() {

  const location = useLocation()



  // States
  const [product, setProduct] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [editorHtml, setEditorHtml] = useState("");

  const [images, setImages] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();
  const maxWords = 3000; // Maximum allowed words


  const handleChange = (html) => {
    // Count words
    const text = html.replace(/<[^>]*>?/gm, ""); // Strip HTML tags
    const wordCount = text.split(/\s+/).length;

    // Check if word count exceeds limit
    if (wordCount <= maxWords) {
      setEditorHtml(html);
    } else {
      // Display message or handle exceeding word limit
      alert(`You cannot exceed ${maxWords} words.`);
    }
  };













  // Network Call
  useEffect(() => {
    const fetchCategoryMain = async () => {
      try {
        const response = await fetchCategory();
        setCategories(response?.categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchSubCategoryMain = async () => {
      try {
        const response = await fetchSubCategory();
        setSubCategories(response);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    const fetchProduct = async (id) => {
      try {
        const response = await getSingleProduct(id);
        setProduct(response);
        setImages(response?.images);
        console.log(response)
        formik.setFieldValue("title",response?.title)
        setEditorHtml(response?.description);

        formik.setFieldValue("price",response?.price)
        formik.setFieldValue("highPrice",response?.highPrice)
        formik.setFieldValue("quantity",response?.quantity)
        formik.setFieldValue("category",response?.category?._id)
        formik.setFieldValue("subcategory",response?.subcategory?._id)
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    if (id) {
      fetchProduct(id);
    }
    fetchSubCategoryMain();
    fetchCategoryMain();
  }, [id,location]);

  // Functions
  const uploadImage = async (acceptedFiles) => {
    const response = await imageUpload(acceptedFiles);
    const uploadedImages = response?.map((image) => ({
      public_id: image.asset_id,
      url: image.url,
    }));
    setImages((prevImages) => [...prevImages, ...uploadedImages]);
  };

  const removeImage = (publicId) => {
    const updatedImages = images.filter(
      (image) => image.public_id !== publicId
    );
    setImages(updatedImages);
  };

  // Formik Form Validation Schema
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    // description: Yup.string().required("Description is required"),
    quantity: Yup.string().required("Quntity is required"),
    price: Yup.string().required("Price is required"),
    category: Yup.string().required("Category is required"),
    subcategory: Yup.string().required("Subcategory is required"),
     });

  // Formik Form Initial Values
  const initialValues = {
    title: product?.title || "",
    description: product?.description || "",
    quantity: product?.quantity || "",
    highPrice: product?.highPrice || "",
    price: product?.price || "",
    category: product?.category || "",
    subcategory: product?.subcategory || "",
    images: product?.images || [],
  };

  // Formik Form Submission
  const onSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", editorHtml);

    formData.append("price", values.price);
    formData.append("highPrice", values.highPrice);
    formData.append("quantity", values.quantity);
    formData.append("category", values.category);
    formData.append("subcategory", values.subcategory);
    formData.append("images", JSON.stringify(images));

if (id) {
  // If id exists, append it to formData
  formData.append("id", id);

  // Perform edit operation
  await editProduct(formData, token);
} else {
  // Perform create operation
  await createProduct(formData, token);
}
 resetForm();
    setEditorHtml(""); // Clear the ReactQuill editor
    setImages([]); // Clear uploaded images


  };

  // Formik Hook
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  // Update subcategories based on selected category
  useEffect(() => {
    const filteredCategory = categories.find(
      (cat) => cat._id === formik.values.category
    );
    setSubCategories(filteredCategory?.subCategories || []);
  }, [formik.values.category, categories]);


  return (
    <div className="max-w-3xl mx-auto p-4 mb-[100px]">
      <h3 className="text-xl font-bold mb-4">
        {id ? "Edit News" : "Add News"}
      </h3>

      <form onSubmit={formik.handleSubmit} className="space-y-4">
        {/* Form fields */}
        {/* Title and Subtitle */}
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-4">
          <div className="space-y-2">
            <label htmlFor="title" className="block font-medium text-gray-700">
              Title *
            </label>
            <input
              id="title"
              name="title"
              type="text"
              placeholder="Enter Title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              className="form-input"
            />
            {formik.touched.title && formik.errors.title && (
              <div className="text-red-500">{formik.errors.title}</div>
            )}
          </div>

        </div>



        <div className="space-y-2">
          <label
            htmlFor="description"
            className="block font-medium text-gray-700"
          >
            Description *
          </label>
          <ReactQuill
            theme="snow"
            value={editorHtml}
            onChange={handleChange}
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                ["clean"],
              ],
            }}
            className="quill-editor"
          />
        </div>

        {/* Description */}
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-4">
        {/* <div className="space-y-2">
          <label
            htmlFor="description"
            className="block font-medium text-gray-700"
          >
            Description *
          </label>
          <input
              id="description"
              name="description"
              type="text"
              placeholder="Enter Description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              className="form-input"
            />
        </div> */}


        <div className="space-y-2">
          <label
            htmlFor="quantity"
            className="block font-medium text-gray-700"
          >
            Product Quantity *
          </label>
          <input
              id="quantity"
              name="quantity"
              type="number"
              placeholder="Enter quantity"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.quantity}
              className="form-input"
            />
        </div>
        </div>
      


        <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-4">
        <div className="space-y-2">
          <label
            htmlFor="price"
            className="block font-medium text-gray-700"
          >
            price *
          </label>
          <input
              id="price"
              name="price"
              type="number"
              placeholder="Enter price"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.price}
              className="form-input"
            />
        </div>


        <div className="space-y-2">
          <label
            htmlFor="highPrice"
            className="block font-medium text-gray-700"
          >
            highPrice (Optional)
          </label>
          <input
              id="highPrice"
              name="highPrice"
              type="number"
              placeholder="Enter highPrice"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.highPrice}
              className="form-input"
            />
        </div>
        </div>





        {/* Language, Type, Category, Subcategory */}
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-4">
         
     

          <div className="space-y-2">
            <label
              htmlFor="category"
              className="block font-medium text-gray-700"
            >
              Category *
            </label>
            <select
              id="category"
              name="category"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.category}
              className="form-input"
            >
              <option value="">Select Category</option>
              {categories?.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
            {formik.touched.category && formik.errors.category && (
              <div className="text-red-500">{formik.errors.category}</div>
            )}
          </div>

          <div className="space-y-2">
            <label
              htmlFor="subcategory"
              className="block font-medium text-gray-700"
            >
              Sub Category *
            </label>
            <select
              id="subcategory"
              name="subcategory"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.subcategory}
              className="form-input"
            >
              <option value="">Select Sub Category</option>
              {subCategories?.map((subcategory) => (
                <option key={subcategory._id} value={subcategory._id}>
                  {subcategory.name}
                </option>
              ))}
            </select>
            {formik.touched.subcategory && formik.errors.subcategory && (
              <div className="text-red-500">{formik.errors.subcategory}</div>
            )}
          </div>
        </div>

       







        {/* Upload Image */}
        <div className="space-y-2">
          <label htmlFor="images" className="block font-medium text-gray-700">
            Upload Image *
          </label>
          <Dropzone onDrop={uploadImage}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps()}
                  className="border-2 border-dashed p-4 text-center cursor-pointer"
                >
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                <aside className="mt-4">
                  <h4>Uploaded Images</h4>
                  <div className="grid grid-cols-4 gap-2">
                    {images?.map((image) => (
                      <div key={image.public_id} className="relative">
                        <img
                          src={image.url}
                          alt="Uploaded"
                          className="h-24 w-full object-cover"
                        />
                        <button
                          type="button"
                          onClick={() => removeImage(image.public_id)}
                          className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                </aside>
              </section>
            )}
          </Dropzone>
        </div>

        {/* Submit Button */}
        <div className="pt-4">
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded"
          >
            {id ? "Update News" : "Add News"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddProduct;
