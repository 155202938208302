import React, { useState } from "react";
import t1 from "../../../assets/hardware/tv/t1.webp";
import t2 from "../../../assets/hardware/tv/t2.jpg";
import t3 from "../../../assets/hardware/tv/t3.webp";

const Choose = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(true);
  };
  return (
    <div>
      <div>
        <div className="bg-[#282828] p-8">
          <div className=" max-w-7xl mx-auto">
            <p className="text-[#c6a85b] text-center lg:text-6xl text-2xl">
              Choose from a range of TVs & Soundbars
            </p>
            <p className="text-[#c6a85b] text-center  my-8 text-xl">
              An enormous variety of Televisions & Soundbars to cater to each
              customer's needs.
            </p>
            <div className="grid lg:grid-cols-3 gap-5">
              <img src={t1} alt="not found" className="hover:scale-95" />
              <img src={t2} alt="not found" className="hover:scale-95" />
              <img src={t3} alt="not found" className="hover:scale-95" />
            </div>
          </div>
        </div>
        <div className="relative w-screen h-screen my-20 overflow-y-hidden">
          {!isPlaying && (
            <div
              className="absolute inset-0 flex items-center justify-center cursor-pointer"
              onClick={handlePlayClick}
            >
              <img
                src="https://visioworld.in/cdn/shop/files/Playwall-_970-x-600-px_2_1512x.png?v=1676622761"
                alt="Play"
                className="min-w-full min-h-full object-cover"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                fill="white"
                className="bi bi-play-circle-fill absolute"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.271 5.055a.5.5 0 0 0-.759.432v5.026a.5.5 0 0 0 .759.432L10.5 8.615a.5.5 0 0 0 0-.83L6.271 5.055z" />
              </svg>
            </div>
          )}
          {isPlaying && (
            <iframe
              id="youtube-bwuvpzJ2bbs-0"
              frameBorder="0"
              allowFullScreen=""
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              title="Introducing Visio World."
              className="min-w-[80%] min-h-screen m-auto"
              src="https://www.youtube.com/embed/bwuvpzJ2bbs?autoplay=1&controls=1&disablekb=1&playsinline=1&cc_load_policy=0&cc_lang_pref=auto&widget_referrer=https%3A%2F%2Fvisioworld.in%2F&noCookie=false&rel=0&showinfo=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&origin=https%3A%2F%2Fvisioworld.in&widgetid=1"
            ></iframe>
          )}
        </div>
      </div>

      <div className="grid lg:grid-cols-2 bg-[#0A1828] text-[#bfa181]">
        <div className="flex-1 flex justify-center md:justify-start">
          <img
            src="https://visioworld.in/cdn/shop/files/Make-in-India-_800-x-600-px.png?v=1676622761"
            alt="Made in India"
            className="max-w-full h-auto"
          />
        </div>
        <div className="flex-1 text-center md:text-left p-5  flex flex-col items-center justify-center  mt-8 md:mt-0">
          <h1 className="text-4xl md:text-5xl font-bold text-center">
            Pioneers In Quality
          </h1>
          <p className="text-lg md:text-xl mt-4 text-center">
            Experience Vision & Sound like never before.
          </p>
          <p className="text-sm md:text-base mt-4 text-center">
            We give a lot of importance to customer feedback and focus on
            constantly improving our product through a rigorous R & D facility.
          </p>
          <button className="mt-8  px-6 py-3 border border-[#bfa181] text-[#bfa181] rounded-full hover:bg-[#bfa181] hover:text-gray-800 transition">
            OUR QUALITY
          </button>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 bg-[#0A1828] text-[#bfa181]">
        <div className="flex-1 text-center p-5 md:text-left  flex flex-col items-center justify-center  mt-8 md:mt-0">
          <h1 className="text-4xl md:text-5xl font-bold text-center">
            550+ Service Centres Across India
          </h1>
          <p className="text-lg md:text-xl mt-4 text-center">
            A Comfortable After-Sales Experience
          </p>
          <p className="text-sm md:text-base mt-4 text-center">
            Get end to end support from purchase to installation. We will take
            care of everything.
          </p>
          <button className="mt-8  px-6 py-3 border border-[#bfa181] text-[#bfa181] rounded-full hover:bg-[#bfa181] hover:text-gray-800 transition">
            VIEW ALL
          </button>
        </div>

        <div className="flex-1 flex justify-center md:justify-start">
          <img
            src="https://visioworld.in/cdn/shop/files/Pan-India-Presence-_970-x-600-px.png?v=1676622761"
            alt="Made in India"
            className="max-w-full h-auto"
          />
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default Choose;
